import { SortCriteria } from 'utils/constants';
import { PartlyDefinedPeriodModel } from 'hooks/api/global/models/dateTimePeriod';
import { ReservationInputModel } from 'hooks/api/reservation/models/reservation';
import { RepeatIntervalOption } from 'utils/repeatIntervalHelper';
import { RoomTypeModel } from 'hooks/api/rentableTypes/roomType/models/roomType';
import { LocationModel } from 'hooks/api/location/models/location';

export enum SearchActionType {
    SET_FILTERS = 'SET_FILTERS',
    SET_IS_ONE_TIME_RENTAL = 'SET_IS_ONE_TIME_RENTAL',
    SET_SELECTED_REPEAT_INTERVAL = 'SET_SELECTED_REPEAT_INTERVAL',
    SET_SELECTED_ROOM_TYPE = 'SET_SELECTED_ROOM_TYPE',
    SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION',
    SET_SELECTED_DATE = 'SET_SELECTED_DATE',
    SET_SELECTED_TIME = 'SET_SELECTED_TIME',
    SET_SORT_BY = 'SET_SORT_BY',
    SET_PRICE_RANGE = 'SET_PRICE_RANGE',
    CLEAR_FILTERS = 'CLEAR_FILTERS',
}

export type SearchAction =
    | { type: SearchActionType.SET_FILTERS }
    | { type: SearchActionType.SET_IS_ONE_TIME_RENTAL, payload: { isOneTimeRental: boolean } }
    | { type: SearchActionType.SET_SELECTED_REPEAT_INTERVAL, payload: { selectedRepeatInterval?: RepeatIntervalOption } }
    | { type: SearchActionType.SET_SELECTED_ROOM_TYPE, payload: { selectedRoomType?: RoomTypeModel } }
    | { type: SearchActionType.SET_SELECTED_LOCATION, payload: { selectedLocation?: LocationModel } }
    | { type: SearchActionType.SET_SELECTED_DATE, payload: { selectedDate: PartlyDefinedPeriodModel } }
    | { type: SearchActionType.SET_SELECTED_TIME, payload: { startTime: Date | null, endTime: Date | null } }
    | { type: SearchActionType.SET_SORT_BY, payload: { sortBy: SortCriteria } }
    | { type: SearchActionType.SET_PRICE_RANGE, payload: { priceRange: number[] } }
    | { type: SearchActionType.CLEAR_FILTERS };

export interface SearchActions {
    setIsOneTimeRental: (isOneTimeRental: boolean) => void;
    setSelectedRepeatInterval: (selectedRepeatInterval?: RepeatIntervalOption) => void;
    setSelectedRoomType: (selectedRoomType?: RoomTypeModel) => void;
    setSelectedLocation: (selectedLocation?: LocationModel) => void;
    setSelectedDate: (selectedDate: PartlyDefinedPeriodModel) => void;
    setSelectedTime: (startTime: Date | null, endTime: Date | null) => void;
    setSortBy: (sortBy: SortCriteria) => void;
    setPriceRange: (priceRange: number[]) => void;
    getReservationInputModel: () => ReservationInputModel;
    clearFilters: () => void;
}
