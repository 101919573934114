import React, { FC } from 'react';
import Modal, { ModalProps } from 'components/organisms/Modal';
import { EditUserForm } from './components';
import useUser from 'hooks/api/user';
import Loading from 'components/atoms/Loading';

interface EditUserProfileModelProps {
    userId?: string;
    modalProps: ModalProps;
}

const EditUserProfileModel: FC<EditUserProfileModelProps> = (props) => {
    const { userId, modalProps: { inProp, onExited, onHide } } = props;

    const { useAllUsers } = useUser();
    const { data: users = [], refetch } = useAllUsers({ id: userId });
    const user = users[0];

    if (!user) {
        return <Loading />;
    }

    return (
        <Modal isSideModal size="large" inProp={inProp} onExited={onExited} onHide={onHide}>
            <EditUserForm
                user={user}
                refetch={refetch}
                onHide={onHide}
            />
        </Modal>
    );
};

export default EditUserProfileModel;
