import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 15rem;
    h4 {
        margin-bottom: 2rem;
        color: ${({ theme }) => theme.colors.medium};
    }
`;