import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-grid-system';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormControl } from 'components/atoms/form';
import { Button } from 'components/atoms/button';
import { Account as AccountFooter } from 'components/organisms/footer';
import { toFormikErrors } from 'utils/errorhelper';
import useAccount from 'hooks/api/account';
import { AbstractRegisterUserInputModel } from 'hooks/api/user/models/user';

export interface RegisterUserFormProps<TRegisterModel> {
    initialValues: TRegisterModel;
    onRegister: (values: TRegisterModel) => Promise<any>;
    redirectUrl?: string;
    onHide?: () => void;
}

const RegisterUserForm = <TRegisterModel extends AbstractRegisterUserInputModel>(props: RegisterUserFormProps<TRegisterModel>) => {
    const { initialValues, onRegister, redirectUrl, onHide } = props;
    const { login } = useAccount();

    const onSubmit = async (values: TRegisterModel, actions: FormikHelpers<TRegisterModel>) => {
        actions.setSubmitting(true);
        const response = await onRegister(values);

        if (response.ok) {
            const loginValues = { userName: values.email, password: values.password, rememberMe: true };
            await login(loginValues, redirectUrl);
            if (onHide) {
                onHide();
            }
        } else if (response.errors) {
            actions.setErrors(await toFormikErrors(response.errors));
        }
        actions.setSubmitting(false);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, isSubmitting, dirty }) => (
                <Form>
                    <Row gutterWidth={16}>
                        <Col md={12} xxl={4}>
                            <FormGroup label="Voornaam" required>
                                <FormikFormControl name="firstName" placeholder="Voornaam" />
                            </FormGroup>
                        </Col>
                        <Col md={4} xxl={4}>
                            <FormGroup label="Tussenvoegsel">
                                <FormikFormControl name="insertion" placeholder="Tussenvoegsel" />
                            </FormGroup>
                        </Col>
                        <Col md={8} xxl={4}>
                            <FormGroup label="Achternaam" required>
                                <FormikFormControl name="lastName" placeholder="Achternaam" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <FormGroup label="E-mailadres" required>
                                <FormikFormControl name="email" placeholder="E-mailadres" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6}>
                            <FormGroup label="Wachtwoord" required>
                                <FormikFormControl name="password" placeholder="Wachtwoord" type="password" />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup label="Herhaal wachtwoord" required>
                                <FormikFormControl name="passwordConfirm" placeholder="Herhaal wachtwoord" type="password" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Button size="lg" full center disabled={isSubmitting || !dirty} loading={isSubmitting}>
                        Registreren
                    </Button>
                    <AccountFooter>
                        <Link to="/account" onClick={onHide} state={{ email: values.email }}>
                            Al een account? <span>Inloggen</span>
                        </Link>
                    </AccountFooter>
                </Form>
            )}
        </Formik>
    );
};

export default RegisterUserForm;
