import styled from '@emotion/styled';

export const RadioItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.2;
    &:not(:last-of-type) {
      margin-bottom: .5rem;
    }
`;

export const Label = styled.label`
    position: relative;
    display: block;
    padding-left: 1.5rem;
    font-weight: 400;
    font-size: .9rem;
    cursor: pointer;

    p {
      margin-bottom: 0;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
        
        &:checked + div {
          border-color: ${({ theme }) => theme.colors.primary};

        &:before {
            transform: translate(-50%, -50%) scale(1.2);
          }
        }
    }
`;

export const Switch = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.border};

    &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        transform-origin: center center;
        content: '';
        width: 100%;
        height: 100%;
        background-color: ${({ theme }) => theme.colors.white};
        border: 4px solid ${({ theme }) => theme.colors.primary};
        border-radius: 50%;
        display: block;
        transition: transform .2s ease 0s;
    }
`;
