import { useQuery } from 'react-query';
import { CustomError, useApi } from 'hooks/api';
import { appendToForm } from 'utils/formDataHelper';
import {
    SettingModel,
    SiteSettingModel, UpdateBookingSettingInputModel, UpdateWebsiteBannerSettingInputModel,
    UpdateWebsiteLinkSettingInputModel, UpdateWebsiteGeneralSettingInputModel, UpdateFinanceSettingInputModel,
} from './models/setting';

export const searchSettingsQueryKey = 'setting.search';
export const siteSettingsQueryKey = 'setting.search';

const useSettings = () => {
    const baseUrl = '/setting';
    const { apiGet, apiPut } = useApi();

    const useSiteSettings = () => {
        const queryKey = [siteSettingsQueryKey];

        return useQuery<SiteSettingModel | null, CustomError[]>(queryKey, async() => {
            const response = await apiGet<SiteSettingModel>(`${baseUrl}/site`);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? null;
        });
    };

    const useAllSettings = () => {
        const queryKey = [searchSettingsQueryKey];

        return useQuery<SettingModel | null, CustomError[]>(queryKey, async() => {
            const response = await apiGet<SettingModel>(`${baseUrl}`);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? null;
        });
    };

    const updateWebsiteGeneralSettings = async (data: UpdateWebsiteGeneralSettingInputModel) => {
        const formData = new FormData();
        appendToForm(formData, data);
        return apiPut<UpdateWebsiteGeneralSettingInputModel>(`${baseUrl}/website/general`, formData);
    };

    const updateWebsiteBannerSettings = async (data: UpdateWebsiteBannerSettingInputModel) => {
        const formData = new FormData();
        appendToForm(formData, data);
        return apiPut<UpdateWebsiteBannerSettingInputModel>(`${baseUrl}/website/banner`, formData);
    };

    const updateWebsiteLinkSettings = async (data: UpdateWebsiteLinkSettingInputModel) => {
        const formData = new FormData();
        appendToForm(formData, data);
        return apiPut<UpdateWebsiteLinkSettingInputModel>(`${baseUrl}/website/link`, formData);
    };

    const updateBookingSettings = async (data: UpdateBookingSettingInputModel) => {
        const formData = new FormData();
        appendToForm(formData, data);
        return apiPut<UpdateBookingSettingInputModel>(`${baseUrl}/booking`, formData);
    };

    const updateFinanceSettings = async (data: UpdateFinanceSettingInputModel) => {
        const formData = new FormData();
        appendToForm(formData, data);
        return apiPut<UpdateFinanceSettingInputModel>(`${baseUrl}/finance`, formData);
    };

    return {
        useAllSettings,
        useSiteSettings,
        updateWebsiteGeneralSettings,
        updateWebsiteBannerSettings,
        updateWebsiteLinkSettings,
        updateBookingSettings,
        updateFinanceSettings
    };
};

export default useSettings;
