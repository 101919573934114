import styled from '@emotion/styled';

export const Content = styled.span<{ color: string; }>`
    display: inline-flex;
    align-items: center;
    font-size: .85rem;
    font-weight: 500;
    color: ${({ color }) => color};

    .icon-wrapper {
        margin-right: .5rem;
    }
`;
