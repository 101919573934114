import React, { FC } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormGroup, FormikFormControl } from 'components/atoms/form';

const AccountSection: FC = () => {
    return (
        <>
            <h4>Accountgegevens</h4>
            <Row>
                <Col sm={4}>
                    <FormGroup label="Huidig wachtwoord">
                        <FormikFormControl
                            placeholder="Huidig wachtwoord"
                            name="currentPassword"
                            errorFields={['currentPassword']}
                            type="password"
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Nieuw wachtwoord">
                        <FormikFormControl
                            placeholder="Nieuw wachtwoord"
                            name="newPassword"
                            errorFields={['confirmNewPassword']}
                            type="password"
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Bevestig nieuw wachtwoord">
                        <FormikFormControl
                            placeholder="Bevestig nieuw wachtwoord"
                            name="confirmNewPassword"
                            errorFields={['confirmNewPassword']}
                            type="password"
                        />
                    </FormGroup>
                </Col>
            </Row>

            <hr />
        </>
    );
};

export default AccountSection;
