import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Wrapper = styled.div<{ large?: boolean; image?: string; }>`
  	width: 2.25rem;
	height: 2.25rem;
	flex: 0 0 2.25rem;
	border-radius: 50%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
  	background-color: ${({ theme }) => theme.colors.primary};
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.75rem;
	text-transform: uppercase;
  	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	background-image: url(${({ image }) => image});

	${({ large }) =>
	large &&
	css`
		width: 3.5rem;
	   	height: 3.5rem;
	   	flex: 0 0 3.5rem;
	`}
`;