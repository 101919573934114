import React, { FC } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormGroup, FormikFormControl } from 'components/atoms/form';
import { useFormikContext } from 'formik';
import { EditUserInputModel } from 'hooks/api/user/models/user';
import usePostalCode from 'hooks/api/postalCode';
import { toFormikErrors } from 'utils/errorhelper';

const InvoiceAddressSection: FC = () => {
    const { values: { invoiceAddres }, setFieldValue, setErrors, handleBlur } = useFormikContext<EditUserInputModel>();
    const { getAddress } = usePostalCode();
    const invoiceAddress = 'invoiceAddres';

    const addressFieldsOnBlur = async (event: React.FocusEvent<any>) => {
        handleBlur(event);
        const { postalCode, houseNumber } = invoiceAddres || { postalCode: '', houseNumber: '' };
        const response = await getAddress(postalCode, houseNumber);

        if (response.data) {
            const { street, city, coordinates: { longitude, latitude } } = response.data;
            const fields = [
                { name: `${invoiceAddress}.street`, value: street },
                { name: `${invoiceAddress}.city`, value: city },
                { name: `${invoiceAddress}.country`, value: 'Nederland' },
                { name: `${invoiceAddress}.coordinates.longitude`, value: longitude },
                { name: `${invoiceAddress}.coordinates.latitude`, value: latitude },
            ];

            fields.forEach(field => setFieldValue(field.name, field.value));
        } else if (response.status === 404) {
            const emptyValue = '';

            setFieldValue(`${invoiceAddress}.street`, emptyValue);
            setFieldValue(`${invoiceAddress}.city`, emptyValue);
            setFieldValue(`${invoiceAddress}.country`, emptyValue);
        } else if (response.errors) {
            const invoiceAddressErrors = response.errors.map(error => {
                return {
                    ...error,
                    key: `${invoiceAddress}.${error.key}`
                };
            });
            setErrors(toFormikErrors(invoiceAddressErrors));
        }
    };

    return (
        <>
            <h4>Factuurgegevens</h4>
            <Row>
                <Col sm={4}>
                    <FormGroup label="Postcode" required>
                        <FormikFormControl
                            placeholder="Postcode"
                            name={`${invoiceAddress}.postalCode`}
                            errorFields={[`${invoiceAddress}.postalCode`]}
                            onChange={event => {
                                const { name, value: postalCode } = event.target;
                                setFieldValue(name, postalCode.toUpperCase());
                            }}
                            onBlur={addressFieldsOnBlur}
                        />
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup label="Huisnummer" required>
                        <FormikFormControl
                            placeholder="Huisnummer"
                            name={`${invoiceAddress}.houseNumber`}
                            errorFields={[`${invoiceAddress}.houseNumber`]}
                            onBlur={addressFieldsOnBlur}
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Toevoeging">
                        <FormikFormControl
                            placeholder="Toevoeging"
                            name={`${invoiceAddress}.houseNumberAddition`}
                            errorFields={[`${invoiceAddress}.houseNumberAddition`]}
                            onChange={event => {
                                const { name, value: houseNumberAddition } = event.target;
                                setFieldValue(name, houseNumberAddition.toUpperCase());
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Straat" required>
                        <FormikFormControl
                            placeholder="Straat"
                            name={`${invoiceAddress}.street`}
                            errorFields={[`${invoiceAddress}.street`]}
                            disabled
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Woonplaats" required>
                        <FormikFormControl
                            placeholder="Woonplaats"
                            name={`${invoiceAddress}.city`}
                            errorFields={[`${invoiceAddress}.city`]}
                            disabled
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Land" required>
                        <FormikFormControl
                            placeholder="Land"
                            name={`${invoiceAddress}.country`}
                            errorFields={[`${invoiceAddress}.country`]}
                            disabled
                        />
                    </FormGroup>
                </Col>
            </Row>

            <hr />
        </>
    );
};

export default InvoiceAddressSection;
