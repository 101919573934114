import React, { FC, Suspense, useEffect, useState } from 'react';
import AdminNavigation from 'components/organisms/AdminNavigation';
import { Outlet } from 'react-router';
import useAccount from 'hooks/api/account';
import { useSession } from 'contexts/sessionContext';
import { useAreaAuthorization } from 'hooks/area';
import { Role } from 'utils/constants';
import { Container } from 'react-grid-system';
import Content from 'components/atoms/Content';
import { Global, css } from '@emotion/react';
import { useTheme } from '@emotion/react';
import Loading from 'components/atoms/Loading';
import { useAppSettings } from 'contexts/settingsContext';

interface AdminLayoutProps { }

const AdminLayout: FC<AdminLayoutProps> = () => {
    const [initialized, setInitialized] = useState(false);
    const { refreshSession } = useAccount();
    const { session } = useSession();
    const { initializedAuth } = useAreaAuthorization(initialized, Role.Admin);
    const { organisationName } = useAppSettings();

    document.title = `QBIO beheeromgeving - ${organisationName}`;

    const theme = useTheme();

    const styles = css`
        body {
            background-color: ${theme.colors.light};
        }
    `;

    useEffect(() => {
        (async () => {
            if (session == null) {
                await refreshSession();
            }
            setInitialized(true);
        })();
    }, []);

    return session && initialized && initializedAuth ? (
        <>
            <AdminNavigation />
            <Global styles={styles} />
            <Suspense fallback={<Loading />}>
                <Container fluid>
                    <Content>
                        <Outlet />
                    </Content>
                </Container>
            </Suspense>
        </>
    ) : (<Suspense fallback="Loading..." />);
};

export default AdminLayout;
