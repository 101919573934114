import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import Icon from 'components/atoms/Icon';
import { Checkbox, HiddenCheckbox, Label, SubLabel, Wrapper } from './styles';
import colors from 'theme/colors';
import InvalidFeedback from '../InvalidFeedback';

export interface FormCheckProps extends InputHTMLAttributes<HTMLInputElement>{
    label?: ReactNode;
    subLabel?: string;
    className?: string;
    boxed?: boolean;
    error?: string;
}

const FormCheck: FC<FormCheckProps> = (props) => {
    const { className, label, subLabel, checked, boxed, error } = props;

    return (
        <>
            <Wrapper hasLabel={label != null} className={className} checked={checked} boxed={boxed}>
                <HiddenCheckbox type="checkbox" checked={checked} {...props} />
                <Checkbox checked={checked}>
                    {checked && <Icon name="check" size={0.75} color={colors.white} />}
                </Checkbox>
                {label && <Label>{label}</Label>}
                {subLabel && <SubLabel>{subLabel}</SubLabel>}
            </Wrapper>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormCheck;
