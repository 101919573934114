import Icon from 'components/atoms/Icon';
import React, { FC } from 'react';
import { Menu as Wrapper, MenuItem } from './styles';
import { useTheme } from '@emotion/react';

export interface ActionMenuItem {
    title: string;
    icon: string;
    to: string;
    onClick?: () => void;
}

interface AdminActionMenuProps {
    items: ActionMenuItem[];
}

const AdminActionMenu: FC<AdminActionMenuProps> = ({
    items
}) => {
    const theme = useTheme();

    return (
        <Wrapper>
            {items.map(item => (
                <MenuItem to={item.to} onClick={item.onClick} key={item.title} title={item.title}>
                    <Icon name={item.icon} color={theme.colors.white} />
                </MenuItem>
            ))}
        </Wrapper>
    );
};

export default AdminActionMenu;