import React, { FC } from 'react';
import { useField } from 'formik';
import WysiwygEditor, { WysiwygEditorProps } from '..';

interface WysiwygEditorFormikProps extends WysiwygEditorProps {
    name: string;
}

const WysiwygEditorFormik: FC<WysiwygEditorFormikProps> = ({ name, ...props }) => {
    const [field, meta, helpers] = useField<string>(name);
    const handleEditorChange = (value: string) => {

    helpers.setValue(value);
    };

    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return (
        <>
            <WysiwygEditor value={field.value} {...props} onEditorChange={handleEditorChange} error={error} />
        </>
    );
 
};

export default WysiwygEditorFormik;
