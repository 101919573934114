import React, { FC, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormImage } from 'components/atoms/form';
import { Button } from 'components/atoms/button';
import useUser from 'hooks/api/user';
import { EditUserInputModel, UserModel } from 'hooks/api/user/models/user';
import { AccountSection, AddressSection, BankSection, InvoiceAddressSection, UserSection } from './index';
import { cdnFileUrl } from 'utils/cdnHelper';
import { toFormikErrors } from 'utils/errorhelper';
import { ThumbnailWrapper, ButtonWrapper } from './styles';
import { useApi } from 'hooks/api';

interface EditUserFormProps {
    user: UserModel;
    refetch: () => Promise<unknown>;
    onHide: () => void;
}

const EditUserForm: FC<EditUserFormProps> = (props) => {
    const { user, refetch, onHide } = props;
    const { editUser } = useUser();
    const { refreshSession } = useApi();

    const initialValues: EditUserInputModel = {
        id: user?.id ?? '',
        firstName: user?.firstName ?? '',
        insertion: user?.insertion ?? undefined,
        lastName: user?.lastName ?? '',
        email: user?.email ?? '',
        phoneNumber: user?.phoneNumber ?? '',
        avatar: user?.avatar != null ? cdnFileUrl(user.avatar) : undefined,
        iban: user?.iban ?? '',
        currentPassword: undefined,
        newPassword: undefined,
        confirmNewPassword: undefined,
        addres: {
            id: user?.address?.id ?? undefined,
            street: user?.address?.street ?? '',
            houseNumber: user?.address?.houseNumber ?? '',
            houseNumberAddition: user?.address?.houseNumberAddition ?? '',
            postalCode: user?.address?.postalCode ?? '',
            city: user?.address?.city ?? '',
            country: user?.address?.country ?? '',
            coordinates: {
                longitude: user?.address?.coordinates?.longitude ?? 0,
                latitude: user?.address?.coordinates?.latitude ?? 0
            }
        },
        invoiceAddres: {
            id: user?.invoiceAddress?.id ?? undefined,
            street: user?.invoiceAddress?.street ?? '',
            houseNumber: user?.invoiceAddress?.houseNumber ?? '',
            houseNumberAddition: user?.invoiceAddress?.houseNumberAddition ?? '',
            postalCode: user?.invoiceAddress?.postalCode ?? '',
            city: user?.invoiceAddress?.city ?? '',
            country: user?.invoiceAddress?.country ?? '',
            coordinates: {
                longitude: user?.invoiceAddress?.coordinates?.longitude ?? 0,
                latitude: user?.invoiceAddress?.coordinates?.latitude ?? 0
            }
        }
    };

    const { addres, invoiceAddres } = initialValues;
    const isInvoiceAddressEqualToAddress = addres?.postalCode === invoiceAddres?.postalCode && addres?.houseNumber === invoiceAddres?.houseNumber;
    const [invoiceAddressIsEqualToAddress, setInvoiceAddressIsEqualToAddress] = useState(isInvoiceAddressEqualToAddress);

    const handleSubmit = async (values: EditUserInputModel, { setSubmitting, resetForm, setErrors }: FormikHelpers<EditUserInputModel>) => {
        const response = await editUser(values);
        if (response.ok) {
            await refetch();
            await refreshSession();
            onHide();
            resetForm();
        } else if(response.errors) {
            setErrors(toFormikErrors(response.errors));
        }
        setSubmitting(false);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {
                (formikProps) => {
                    const { isSubmitting, dirty } = formikProps;

                    return (
                        <Form>
                            <h4 style={{ fontSize: '1.25rem' }}>Profiel aanpassen</h4>

                            <FormGroup>
                                <ThumbnailWrapper>
                                    <FormikFormImage label="Profielfoto wijzigen" name="avatar" rounded />
                                </ThumbnailWrapper>
                            </FormGroup>

                            <UserSection />

                            <AddressSection
                                invoiceAddressIsEqualToAddress={invoiceAddressIsEqualToAddress}
                                setInvoiceAddressIsEqualToAddress={setInvoiceAddressIsEqualToAddress}
                            />

                            {
                                !invoiceAddressIsEqualToAddress && <InvoiceAddressSection />
                            }

                            <BankSection />

                            <AccountSection />

                            <ButtonWrapper>
                                <Button size="lg" type="button" variant="light" onClick={onHide}>
                                    Annuleren
                                </Button>
                                <Button size="lg" type="submit" disabled={isSubmitting || !dirty} loading={isSubmitting}>
                                    Toepassen
                                </Button>
                            </ButtonWrapper>

                        </Form>
                    );
                }
            }
        </Formik>
    );
};

export default EditUserForm;
