import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Wrapper, Inner, NoImage, UploadIcon, Image, Input, Label, Remove, TextLabel } from './styles';
import Icon from 'components/atoms/Icon';
import { useTheme } from '@emotion/react';
import noImage from 'static/no-photo.svg';
import InvalidFeedback from '../InvalidFeedback';

export interface FormImageProps {
    name?: string;
    heightPercentage?: number;
    onChange?: (value: File) => void;
    onRemove?: () => void;
    value?: File | string;
    error?: string;
    rounded?: boolean;
    label?: string;
}

const FormImage: FC<FormImageProps> = ({ name, heightPercentage = 100, value, onChange, onRemove, error, rounded, label }) => {
    const theme = useTheme();
    const hasImage = value != null;

    const [url, setUrl] = useState('');

    useEffect(() => {
        if (value == null) {
            return;
        }

        if (value instanceof File) {
            setUrl(URL.createObjectURL(value));
        } else {
            setUrl(value as string);
        }
    }, [value]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];

        if (file && onChange) {
            onChange(file);
        }
    };

    return (
        <Wrapper>
            <Label>
                <Inner heightPercentage={heightPercentage} hasError={!!error} rounded={rounded}>
                    {
                        onRemove &&
                        <Remove onClick={onRemove}>
                            <Icon name="cross" size={1} color={theme.colors.white} />
                        </Remove>
                    }
                    <Input
                        type="file"
                        name={name}
                        multiple={false}
                        onChange={handleOnChange}
                        accept="image/*"
                    />

                    {
                        !label &&
                        <UploadIcon>
                            <Icon name="edit" size={1.35} color={theme.colors.white} />
                        </UploadIcon>
                    }

                    {
                        hasImage ? (
                            <Image
                                image={url}
                            />
                        ) : (
                            <NoImage>
                                <img src={noImage} alt="Placeholder" />
                            </NoImage>
                        )
                    }
                </Inner>
                {label && <TextLabel>{label}</TextLabel>}
            </Label>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </Wrapper>
    );
};

export default FormImage;
