import React, { FC } from 'react';
import { Modal as Wrapper, Overlay, Content, Header, Title, Subtitle, Close, Footer } from './styles';
import { Transition } from 'react-transition-group';
import Icon from 'components/atoms/Icon';
import Button, { ButtonProps } from 'components/atoms/button/Button';
import Group from 'components/molecules/Group';

const DURATION = 300;

export interface ModalProps {
    inProp: boolean;
    onExited: () => void;
    onHide: () => void;
    size?: 'small' | 'medium' | 'large' | 'full';
    title?: string;
    subtitle?: string;
    tabbedContent?: boolean;
    isSideModal?: boolean;
    buttons?: ButtonProps[];
}

const Modal: FC<ModalProps> = (props) => {
    const { children, inProp, onExited, onHide, size = 'medium',
        title, subtitle, tabbedContent = false, isSideModal = false, buttons
    } = props;

    return (
        <Transition in={inProp} onExited={onExited} timeout={DURATION}>
            {(state) => (
                <Overlay isSideModal={isSideModal} state={state} duration={DURATION} onClick={onHide}>
                    <Wrapper isSideModal={isSideModal} state={state} duration={DURATION} onClick={(e) => e.stopPropagation()} size={size}>
                        <Close onClick={onHide}>
                            <Icon name="cross" size={1} />
                        </Close>
                        {title && (
                            <Header isSideModal={isSideModal}>
                                <Title>{title}</Title>
                                {subtitle && <Subtitle center>{subtitle}</Subtitle>}
                            </Header>
                        )}
                        {children && <Content tabbedContent={tabbedContent} size={size}>{children}</Content>}

                        {
                            buttons && (
                                <Footer>
                                    <Group spaceBetween>
                                        {buttons?.map((button, index) => (
                                            <Button key={index} {...button} full center>{button.label}</Button>
                                        ))}
                                    </Group>
                                </Footer>
                        )}
                    </Wrapper>
                </Overlay>
            )}
        </Transition>
    );
};

export default Modal;
