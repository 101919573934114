import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { LocalStorageKeys } from 'localStorage';
import { SearchStateModel } from './state';
import { SortCriteria } from 'utils/constants';
import { SearchActions, SearchActionType } from './action';
import { searchReducer } from './reducer';
import { PartlyDefinedPeriodModel } from 'hooks/api/global/models/dateTimePeriod';
import { RepeatIntervalOption } from 'utils/repeatIntervalHelper';
import { RoomTypeModel } from 'hooks/api/rentableTypes/roomType/models/roomType';
import { LocationModel } from 'hooks/api/location/models/location';

export type SearchContextType = SearchActions & Partial<SearchStateModel>;
const context = createContext<SearchContextType>({} as SearchContextType);
export const SearchContext = context.Provider;

export const useSearchContext = () => {
    return useContext(context);
};

export const useSearch = (): SearchContextType => {
    const [searchState, dispatch] = useReducer(searchReducer, undefined);

    useEffect(() => {
        if (searchState == null) {
            dispatch({ type: SearchActionType.SET_FILTERS });
            return;
        }
        localStorage.setItem(LocalStorageKeys.SearchFilters, JSON.stringify(searchState));
    }, [searchState]);

    const setIsOneTimeRental = useCallback((isOneTimeRental: boolean) => {
        dispatch({
            type: SearchActionType.SET_IS_ONE_TIME_RENTAL,
            payload: { isOneTimeRental }
        });
    }, [dispatch]);

    const setSelectedRepeatInterval = useCallback((selectedRepeatInterval?: RepeatIntervalOption) => {
        dispatch({
            type: SearchActionType.SET_SELECTED_REPEAT_INTERVAL,
            payload: { selectedRepeatInterval }
        });
    }, [dispatch]);

    const setSelectedRoomType = useCallback((selectedRoomType?: RoomTypeModel) => {
        dispatch({
            type: SearchActionType.SET_SELECTED_ROOM_TYPE,
            payload: { selectedRoomType }
        });
    }, [dispatch]);

    const setSelectedLocation = useCallback((selectedLocation?: LocationModel) => {
        dispatch({
            type: SearchActionType.SET_SELECTED_LOCATION,
            payload: { selectedLocation }
        });
    }, [dispatch]);

    const setSelectedDate = useCallback((selectedDate: PartlyDefinedPeriodModel) => {
        dispatch({
            type: SearchActionType.SET_SELECTED_DATE,
            payload: { selectedDate }
        });
    }, [dispatch]);

    const setSelectedTime = useCallback((startTime: Date | null, endTime: Date | null) => {
        dispatch({
            type: SearchActionType.SET_SELECTED_TIME,
            payload: { startTime, endTime }
        });
    }, [dispatch]);

    const setSortBy = useCallback((sortBy: SortCriteria) => {
        dispatch({
            type: SearchActionType.SET_SORT_BY,
            payload: { sortBy }
        });
    }, [dispatch]);

    const setPriceRange = useCallback((priceRange: number[]) => {
        dispatch({
            type: SearchActionType.SET_PRICE_RANGE,
            payload: { priceRange }
        });
    }, [dispatch]);

    const getReservationInputModel = () => {

        let reservationTime = null;

        // Check if a reservation time is selected
        if (searchState?.isStartTimeSelected
            && searchState.isEndTimeSelected
            && searchState.selectedDates.length > 0
            && searchState.selectedDates[0].to != null) {
            reservationTime = {
                from: searchState.selectedDates[0].from,
                to: searchState.selectedDates[0].to
            };
        }

        return {
            reservationTime,
            isOneTimeRental: searchState?.isOneTimeRental ?? false,
            repeatInterval: searchState?.selectedRepeatInterval?.value,
            options: []
        };
    };

    const clearFilters = useCallback(() => {
        dispatch({ type: SearchActionType.CLEAR_FILTERS });
    }, [dispatch]);

    const actions: SearchActions = useMemo(() => ({
        setIsOneTimeRental, setSelectedRepeatInterval, setSelectedRoomType,
        setSelectedLocation, setSelectedDate, setSelectedTime, setSortBy, setPriceRange,
        getReservationInputModel, clearFilters
    }), [
        setIsOneTimeRental, setSelectedRepeatInterval, setSelectedRoomType,
        setSelectedLocation, setSelectedDate, setSelectedTime, setSortBy, setPriceRange,
        getReservationInputModel, clearFilters
    ]);

    return useMemo(() => ({
        ...actions, ...searchState
    }), [actions, searchState]);
};
