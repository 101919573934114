import React, { FC, useState } from 'react';
import TransactionStatus, { TransactionStatusProps } from 'components/atoms/TransactionStatus';
import { Wrapper, Left, Right, SpecsWrapper } from './styles';
import { Card, CardBody } from 'components/atoms/card';
import { Col, Row } from 'react-grid-system';
import Icon from 'components/atoms/Icon';
import { useTheme } from '@emotion/react';
import { formatToCurrency } from 'utils/currencyHelper';
import Margin from 'components/atoms/Margin';
import useInvoices from 'hooks/api/invoice';
import { saveAs } from 'file-saver';
import { Button } from 'components/atoms/button';
import { useNavigate } from 'react-router';
import { InvoiceStatus, InvoiceType } from 'hooks/api/invoice/models/invoice';

interface Spec {
    label: string;
    value: string;
}

export interface Specs {
    items: Spec[];
}

export interface TransactionProps {
    invoiceId?: string;
    pdfDocumentName?: string;
    amount: number;
    paymentDateOrPeriod: string;
    status?: TransactionStatusProps;
    specs?: Specs[];
    invoiceStatus?: InvoiceStatus;
    invoiceType?: InvoiceType;
}

const Transaction: FC<TransactionProps> = (props) => {
    const { invoiceId, pdfDocumentName, amount, paymentDateOrPeriod, status, specs, invoiceStatus, invoiceType } = props;
    const [specsVisible, setSpecsVisible] = useState<boolean>(false);
    const theme = useTheme();
    const navigate = useNavigate();

    const { downloadInvoicePdfDocument } = useInvoices();
    const onDownloadTransaction = async () => {
        if (invoiceId == null) {
            return;
        }

        const response = await downloadInvoicePdfDocument(invoiceId);

        if (response.ok) {
            const blob = await response.blob();
            saveAs(blob, pdfDocumentName);
        } else if (response.errors) {
            alert(response.errors[0].message);
        }
    };

    return (
        <>
            <Wrapper onClick={() => setSpecsVisible(!specsVisible)} specsVisible={specsVisible}>
                <Left>
                    <h4>{formatToCurrency(amount)}</h4>
                    <p>
                        {paymentDateOrPeriod}
                        {
                            specs &&
                            <Icon name="chevron-down" className="is-open-icon" size={1} color={theme.colors.medium} />
                        }
                    </p>
                </Left>
                {
                    invoiceId != null && status != null &&
                    <Right>
                        <TransactionStatus title={status.title} type={status.type} />
                        <Button endIcon="download-cloud" variant="white" onClick={onDownloadTransaction}>Download</Button>
                        {
                            (invoiceStatus === InvoiceStatus.Open || invoiceStatus === InvoiceStatus.Expired) && invoiceType === InvoiceType.Debit &&
                            <Button endIcon="payment" variant="white" onClick={() => navigate(`/payInvoice/${invoiceId}`)}>Betaal</Button>
                        }
                    </Right>
                }
            </Wrapper>
            {
                (specs && specsVisible) &&
                <Margin bottom={0.5}>
                    <Card light>
                        <Row>
                            {
                                specs.map((s, i) => (
                                    <Col xs={6} key={i}>
                                        <CardBody>
                                            <SpecsWrapper>
                                                <table>
                                                    {
                                                        s.items.map((spec, index) => (
                                                            <tr key={index}>
                                                                <td>{spec.label}</td>
                                                                <td>{spec.value}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </SpecsWrapper>
                                        </CardBody>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Card>
                </Margin>
            }
        </>
    );
};

export default Transaction;
