import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Group = styled.div<{ spaceBetween?: boolean; right?: boolean; noMargin?: boolean; }>`
    display: flex;
    justify-content: ${({ spaceBetween, right }) => (spaceBetween ? 'space-between' : right ? 'flex-end' : 'flex-start')};

    > * {
        margin-right: 8px;

        ${({ spaceBetween, right }) =>
            spaceBetween &&
            right &&
            css`
                :only-child {
                    margin-left: auto;
                }
            `};

        &:last-child {
            margin-right: 0;
        }
    }
    ${({ noMargin }) =>
    noMargin &&
    css`
        margin-bottom: 0;
    `};
`;
