import React, { FC } from 'react';
import ProfileCard from 'components/molecules/ProfileCard';
import { Sticky } from './styles';
import { UserModel } from 'hooks/api/user/models/user';
import { formatToCurrency } from 'utils/currencyHelper';

interface UserDetailsProps {
    user: UserModel;
    totalDeposit: number;
}

const UserDetails: FC<UserDetailsProps> = (props) => {
    const { user, totalDeposit } = props;
    const { firstName, insertion, lastName, email, phoneNumber, address } = user || {};
    const { street, houseNumber, houseNumberAddition, postalCode, city, country } = address || {};
    const emptySpec = { label: '', value: '' };

    return (
        <Sticky>
            <ProfileCard
                user={user}
                specs={[
                    {
                        items: [
                            { label: 'Voornaam', value: firstName },
                            insertion ? { label: 'Tussenvoegsel', value: insertion } : emptySpec,
                            { label: 'Achternaam', value: lastName },
                            { label: 'E-mailadres', value: email },
                            phoneNumber ? { label: 'Telefoonnummer', value: `${phoneNumber.slice(0, 2)} - ${phoneNumber.slice(2)}` } : emptySpec,
                            address ? { label: 'Adres', value: `${street} ${houseNumber}${houseNumberAddition ?? ''}` } : emptySpec,
                            address ? { label: '', value: `${postalCode} ${city}, ${country}` } : emptySpec,
                        ]
                    },
                    {
                        items: [
                            { label: 'Totaal borg', value: formatToCurrency(totalDeposit) }
                        ]
                    }
                ]}
            />
        </Sticky>
    );
};

export default UserDetails;
