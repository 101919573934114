import React, { FC, MouseEvent } from 'react';
import { Button as Wrapper, Loading, StartIcon, EndIcon } from './styles';
import { useTheme } from '@emotion/react';

export interface ButtonProps {
    label?: string;
    onClick?: (event?: MouseEvent) => void;
    variant?: 'primary' | 'primary-bordered' | 'secondary' | 'white' | 'border-white' | 'border' | 'light' | 'success';
    disabled?: boolean;
    className?: string;
    loading?: boolean;
    rounded?: boolean;
    full?: boolean;
    center?: boolean;
    size?: 'sm' | 'md' | 'lg';
    startIcon?: string;
    endIcon?: string;
    iconSize?: number;
    type?: 'button' | 'submit';
    breakText?: boolean;
}

const Button: FC<ButtonProps> = ({ loading, disabled, onClick, children, variant = 'primary', rounded, full, center, startIcon, endIcon, iconSize = 1, breakText, ...props }) => {
    const theme = useTheme();
    // Button's methods.
    const handleOnClick = (event?: MouseEvent) => {
        if (onClick && (!loading || disabled)) {
            event?.stopPropagation();
            onClick(event);
        }
    };

    // Render.
    const icon = !!(startIcon || endIcon);

    let textColor = theme.colors.white;
    switch (variant) {
        case 'border':
            textColor = theme.colors.dark;
            break;
        case 'primary-bordered':
            textColor = theme.colors.primary;
            break;
        case 'white':
            textColor = theme.colors.medium;
            break;
        case 'light':
            textColor = theme.colors.medium;
            break;
        case 'secondary':
            textColor = theme.colors.dark;
            break;
        case 'success':
            textColor = theme.colors.success;
            break;
    }

    return (
        <Wrapper breakText={breakText} full={full} startIcon={startIcon} endIcon={endIcon} loading={loading} disabled={disabled || loading} variant={variant} rounded={rounded} center={center} onClick={handleOnClick} {...props}>
            {!endIcon && loading ? (
                <Loading icon={icon} color={theme.colors.white} />
            ) : (
                startIcon && <StartIcon name={startIcon} size={iconSize} color={textColor} />
            )}
            {children}
            {endIcon && (loading ? <Loading icon={icon} color={textColor} /> : <EndIcon name={endIcon} size={iconSize} color={textColor} />)}
        </Wrapper>
    );
};

export default Button;
