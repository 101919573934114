import React, { FC } from 'react';
import { Wrapper } from './styles';
import Dropdown from 'components/molecules/Dropdown';
import { useModal } from 'hooks/modal';
import EditUserProfileModel from 'components/organisms/EditUserProfileModel';
import { PlaceholderImage } from 'utils/constants';
import { cdnFileUrl } from 'utils/cdnHelper';
import { useSession } from 'contexts/sessionContext';
import useAccount from 'hooks/api/account';

export interface UserProps {
    large?: boolean;
}

const User: FC<UserProps> = (props) => {
    const { large } = props;
    const session = useSession();
    const { logOut } = useAccount();
    const avatar = session.session?.avatar ? cdnFileUrl(session.session.avatar) : PlaceholderImage;

    const [showEditUserProfileModel, hideEditUserProfileModel] = useModal(({ in: inProp, onExited }) => {
        const modalProp = { inProp, onExited, onHide: hideEditUserProfileModel };
        return (
            <EditUserProfileModel
                userId={session.session?.userId}
                modalProps={modalProp}
            />
        );
    }, []);

    return (
        <Dropdown
            hideIcon
            items={[
                {
                    title: 'Profiel bewerken',
                    onClick: showEditUserProfileModel,
                    closeOnClick: true,
                    icon: 'profile'
                },
                {
                    title: 'Uitloggen',
                    onClick: logOut,
                    closeOnClick: true,
                    icon: 'sign-out',
                    topBorder: true,
                },
            ]}
        >
            <Wrapper large={large} image={avatar} />
        </Dropdown>
    );
};

export default User;
