import React, { FC } from 'react';
import { Wrapper, Title } from './styles';
import { Button } from 'components/atoms/button';
export interface MenuItemProps {
    to: string;
    onClick?: () => void;
    title?: string;
    isButton?: boolean;
    isVisible?: boolean;
}

const MenuItem: FC<MenuItemProps> = ({ to, onClick, title, isButton }) => {
    // MenuItem's methods.
    // Render.
    return (
        <Wrapper onClick={onClick} to={to} hideActiveLine={isButton}>
            {
                isButton ? (
                    <Button onClick={onClick} variant="light">{title}</Button>
                ) : (
                    <Title>{title}</Title>
                )
            }
        </Wrapper>
    );
};

export default MenuItem;
