import { PriceItem } from 'components/molecules/PricingTable';
import { InvoiceSpecificationViewModel } from 'hooks/api/invoice/models/invoice';
import { PaymentMethod, PaymentStatus } from 'hooks/api/payment/models/payment';
import { PriceSpecificationType, PriceSpecificationViewModel } from 'hooks/api/price/models/price';
import { groupBy } from 'lodash';
import { DateTimeFormat } from 'utils/constants';
import { formatToCurrency } from 'utils/currencyHelper';
import { TransactionStatusType } from 'components/atoms/TransactionStatus';
import { formatInUtc, parseTimeOnly } from 'utils/dateHelper';
import { addMinutes } from 'date-fns';
import { ReactNode } from 'react';
import React from 'react';
import Tooltip from 'components/molecules/Tooltip';
import Icon from 'components/atoms/Icon';

export const getPaymentMethodLabel = (method: PaymentMethod): string => {
    let label = '';

    switch (method) {
        case PaymentMethod.Ideal:
            label = 'Ideal';
            break;
        case PaymentMethod.MasterCard:
            label = 'Mastercard';
            break;
        case PaymentMethod.Visa:
            label = 'Visa';
            break;
        case PaymentMethod.DirectDebit:
            label = 'Incasso';
            break;
    }

    return label;
};

export const getPaymentStatusLabel = (status: PaymentStatus): string => {
    let label = 'Status onbekend';

    switch (status) {
        case PaymentStatus.Success:
            label = 'Betaald';
            break;
        case PaymentStatus.Failed:
            label = 'Mislukt';
            break;
        case PaymentStatus.Canceled:
            label = 'Geannuleerd';
            break;
        case PaymentStatus.Pending:
            label = 'In afwachting';
            break;
        case PaymentStatus.NotCreated:
            label = 'Betaling niet aangemaakt';
            break;
        case PaymentStatus.Refunded:
            label = 'Teruggeboekt';
            break;
    }

    return label;
};

export const getPaymentStatusType = (status: PaymentStatus) : TransactionStatusType => {
    switch (status) {
        case PaymentStatus.Success:return TransactionStatusType.Completed;
        case PaymentStatus.Failed: return TransactionStatusType.Warning;
        case PaymentStatus.Canceled: return TransactionStatusType.Warning;
        case PaymentStatus.Pending: return TransactionStatusType.Pending;
        case PaymentStatus.NotCreated: return TransactionStatusType.Warning;
        case PaymentStatus.Refunded: return TransactionStatusType.Warning;
    }
};

export const invoiceSpecificationViewModelToPricingTableItems = (specifications: InvoiceSpecificationViewModel[]) => {
    const specs = specifications.map((s) => ({
        ...s,
        rentalBlock: null,
        rentable: null
    }));

    return priceSpecificationViewModelToPricingTableItems(specs);
};

export const priceSpecificationViewModelToPricingTableItems = (specifications?: PriceSpecificationViewModel[], monthlyPayment = false) => {

    if (specifications == null) {
        return [{
            label: 'Totaal',
            value: 'Prijs niet bekend'
        }];
    }

    specifications = specifications.filter(s => s.totalAmount !== 0);
    const rent = specifications.filter((s) => s.type === PriceSpecificationType.Rent);
    const voucher = specifications.filter((s) => s.type === PriceSpecificationType.Voucher);
    const deposit = specifications.filter((s) => s.type === PriceSpecificationType.Deposit);
    const restitution = specifications.filter((s) => s.type === PriceSpecificationType.Restitution || s.type === PriceSpecificationType.DepositRestitution);
    const verification = specifications.filter((s) => s.type === PriceSpecificationType.Verification);

    const priceItems: PriceItem[] = [];

    const createSpecs = (items: PriceSpecificationViewModel[]) => {
        // Check if there are divergent prices
        if (items.some((i) => i.rentalBlock != null && i.rentable != null)) {
            // Get grouped price specifications
            const rentableSpecs = groupBy(items, i => i.rentable?.id);

            return Object.keys(rentableSpecs).map((key) => {
                const items = rentableSpecs[key];

                return {
                    label: items[0].rentable?.name ?? '-',
                    value: items.map((i) => {
                        let time = null;

                        if (i.rentalBlock != null) {
                            const from = parseTimeOnly(i.rentalBlock.from, new Date());
                            const to = addMinutes(from, i.rentalBlock.duration);
                            time = `tarief geldt tussen: ${formatInUtc(from, DateTimeFormat.TimeWithOutSeconds)}-${formatInUtc(to, DateTimeFormat.TimeWithOutSeconds)}`;
                        }

                        return {
                            divergent: i.rentalBlock != null,
                            time: time,
                            value: formatToCurrency(i.totalAmount)
                        };
                    })
                };
            });
        } else {
            return items.map(r => {
                return {
                    label: r.description,
                    value: formatToCurrency(r.totalAmount)
                };
            });
        }
    };

    const addItems = (label: ReactNode, items: PriceSpecificationViewModel[]) => {
        if (items.length > 0) {
            priceItems.push({
                label: label,
                value: formatToCurrency(items.reduce((sum, val) => val.totalAmount + sum, 0)),
                specs: createSpecs(items)
            });
        }
    };

    addItems('Borg', deposit);
    addItems('Huur', rent);
    addItems('Kortingsvoucher', voucher);
    addItems(
        <>
            Verificatie
            <Tooltip text={'Om de automatische incasso op uw bankrekening te verifiëren, wordt er een verificatiebedrag van 1 cent in rekening gebracht.'}>
                <div style={{ display: 'inline-block', padding: '.4rem' }}>
                    <Icon name="info" size={.8} />
                </div>
            </Tooltip>
        </>, 
        verification
    );
    addItems('Restitutie', restitution);

    priceItems.push({
        label: 'Totaal',
        value: `${formatToCurrency(specifications.reduce((sum, val) => val.totalAmount + sum, 0))} ${monthlyPayment ? 'p/m' : ''}`,
        big: true
    });

    return priceItems;
};

export const isVoucherApplied = (specifications?: PriceSpecificationViewModel[]): boolean => {
    return specifications?.some(spec => spec.type === PriceSpecificationType.Voucher) ?? false;
};
