import { useTheme } from '@emotion/react';
import React, { FC } from 'react';
import { Arrow, useHover, useLayer } from 'react-laag';
import { Wrapper } from './style';

interface TooltipProps {
    text: string;
}

const Tooltip: FC<TooltipProps> = (props) => {
    const {
        text,
        children
    } = props;

    const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 300 });

    const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
        isOpen: isOver,
        placement: 'top-center',
        triggerOffset: 8
    });

    const theme = useTheme();

    let trigger;
    if (!React.isValidElement(children)) {
        trigger = (
            <span {...triggerProps} {...hoverProps}>
                {children}
            </span>
        );
    } else {
        trigger = React.cloneElement(children, {
        ...triggerProps,
        ...hoverProps
        });
    }

    return (
        <>
            {trigger}
            {renderLayer(
                <div {...layerProps}>
                {
                    isOver &&
                    <>
                        <Wrapper>
                            {text}
                        </Wrapper>
                        <Arrow
                            {...arrowProps}
                            backgroundColor={theme.greys[900]}
                        />
                    </>
                }
                </div>
            )}
        </>
    );
};

export default Tooltip;
