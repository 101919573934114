import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import { useTheme } from '@emotion/react';

interface IconProps {
  size?: number;
  color?: string;
  name: string;
  className?: string;
}

const Icon: FC<IconProps> = ({ size = 1.25, color, name, className }) => {
  const theme = useTheme();
  color = color ? color : theme.colors?.dark;

  return (
    <ReactSVG
      wrapper="span"
      className={classnames('icon-wrapper', className)}
      beforeInjection={(svg) => {
        svg.setAttribute('style', `width: ${size}rem; height: ${size}rem;`);
        color && svg.setAttribute('fill', color);
      }}
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      src={require(`static/icons/${name}.svg`).default}
    />
  );
};

export default Icon;
