import React, { FC } from 'react';
import { Wrapper } from './styles';
import Icon from '../Icon';

export interface LoadingProps {
    visible?: boolean;
}

const Loading: FC<LoadingProps> = () => {
    return (
        <Wrapper>
            <Icon name={'loading'} className={'loading'} size={3} />
        </Wrapper>
    );
};

export default Loading;
