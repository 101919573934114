import styled from '@emotion/styled';
import { mq } from 'utils/breakpointHelper';

export const Wrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding: 1.35rem 0;
  color: ${({ theme }) => theme.colors.medium};
  font-size: .9rem;
  font-weight: 500;
`;

export const Inner = styled.div`
  display :flex;
  align-items: center;
  ${mq[2]} {
    flex-direction: column;
    align-items: flex-start;
  }
  > * {
    flex: 1;
  }  
  ul {
    list-style: none;
    list-style-image: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;

    &:first-of-type {
      justify-content: center;
    }

    &:last-of-type {
      justify-content: flex-end;
      li:last-of-type {
        margin-right: 0;
      }
    }
    li {
      margin: 0 .5rem;
      ${mq[2]} {
        margin: 0 1rem 1rem 0;
      }
    }
  }
`;