import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Role } from 'utils/constants';
import { useSession } from 'contexts/sessionContext';
import { defaultAreaRoute } from 'utils/areaHelper';

export const useAreaAuthorization = (initialized: boolean, role?: Role, redirectToLogin = true) => {
    const { session } = useSession();
    const navigate = useNavigate();
    const [initializedAuth, setInitializedAuth] = useState(false);

    useEffect(() => {
        if (session != null && initialized) {
            if (role != null && session.role === role) {
                setInitializedAuth(true);
            } else {
                const route = defaultAreaRoute(session.role);
                navigate(route, { replace: true });
            }
        }

        if (initialized && session == null && redirectToLogin) {
            navigate('/account');
            return;
        }

    }, [session, initialized, initializedAuth]);

    return { initializedAuth };
};
