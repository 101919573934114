import React, { FC, useEffect, useState } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { DateTimeFormat, TimeZone } from 'utils/constants';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Wrapper } from './styles';
import Icon from 'components/atoms/Icon';

export interface FormDateProps extends ReactDatePickerProps {
    error?: string;
    enableTyping?: boolean;
    showOnlyInjectedTimes?: boolean;
}

const FormDate: FC<FormDateProps> = ({ error, ...props }) => {

    const {
        selected,
        onChange,
        showTimeSelectOnly,
        enableTyping,
        open,
        injectTimes,
        timeIntervals,
        showOnlyInjectedTimes,
        timeFormat = DateTimeFormat.TimeWithOutSeconds,
        ...dateTimeProps
    } = props;

    const [value, setValue] = useState<Date>();
    const [convertedInjectedTimes, setConvertedInjectedTimes] = useState<Date[]>();

    // Methods.
    const handleOnChange = (date: Date, event: React.SyntheticEvent<any, Event> | undefined) => {
        date = zonedTimeToUtc(date, TimeZone.UTC);
        const val = isNaN(date.valueOf()) ? null : date;
        onChange && onChange(val, event);
    };

    const handleOnChangeRaw = (event: React.FocusEvent<HTMLInputElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (selected == null) {
            setValue(undefined);
            return;
        }

        const date = utcToZonedTime(selected, TimeZone.UTC);
        if (!isNaN(date.getTime())) {
            setValue(date);
        }
    }, [selected]);

    useEffect(() => {
        const times = injectTimes?.map((d) => utcToZonedTime(d, TimeZone.UTC));
        setConvertedInjectedTimes(times);
    }, [injectTimes]);

    const secondsInADay = 86400;

    return (
        <>
            <Wrapper showTimeSelectOnly={showTimeSelectOnly} showOnlyInjectedTimes={showOnlyInjectedTimes} open={open}>
                <DatePicker
                    selected={value}
                    onChange={handleOnChange}
                    popperPlacement="bottom-start"
                    timeIntervals={injectTimes != null ? secondsInADay : timeIntervals}
                    injectTimes={convertedInjectedTimes}
                    showTimeSelectOnly={showTimeSelectOnly}
                    onChangeRaw={enableTyping ? undefined : handleOnChangeRaw}
                    open={open}
                    timeFormat={timeFormat}
                    {...dateTimeProps}
                />
                {
                    showTimeSelectOnly && open !== false &&
                    <Icon name="chevron-down" size={1} />
                }
            </Wrapper>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormDate;
