import React, { FC } from 'react';
import Transaction, { Specs } from 'components/molecules/Transaction';
import { sortBy } from 'lodash-es';
import EmptyContent from 'components/molecules/EmptyContent';
import usePeriodicPayments from 'hooks/api/periodicPayment';
import Loading from 'components/atoms/Loading';
import { parsePeriodModel, periodToString } from 'utils/dateHelper';
import { useSession } from 'contexts/sessionContext';

export interface UpcomingTransactionProps {
}

const UpcomingTransactions: FC<UpcomingTransactionProps> = () => {
    const { session } = useSession();
    const { useAllUpcomingPayments } = usePeriodicPayments();
    const { data: upcomingPayments = [], isLoading: isLoadingUpcomingPayments } = useAllUpcomingPayments({
        userId: session?.userId
    });
    const sortedUpcomingPayments = sortBy(upcomingPayments, 'created', 'desc');

    if (isLoadingUpcomingPayments) {
        return <Loading />;
    }

    const upcomingPaymentsContent = sortedUpcomingPayments.map((payment, i) => {
        const { amount, reservations, period } = payment;

        const roomName = Array.from(new Set(reservations.map(r => `${r.room.name} - ${r.room.location.name}`))).join(', ');
        const roomType = Array.from(new Set(reservations.map(r => r.room.roomType))).join(', ');

        const formattedReservationTime = period != null ? periodToString(parsePeriodModel(period)) : '';

        const rentedOptions = Array.from(new Set(reservations.map(r => r.options.map(o => o.name)).flat()));
        const hasRentedOptions = rentedOptions.length > 0;

        return (
            <Transaction
                key={i}
                amount={amount}
                paymentDateOrPeriod={formattedReservationTime}
                specs={[
                    {
                        items: [
                            { label: 'Soort ruimte', value: roomType },
                            { label: 'Naam ruimte', value: roomName },
                            { label: 'Huurperiode', value: formattedReservationTime }
                        ]
                    },
                    hasRentedOptions && {
                        items: rentedOptions.map((name, i) => (
                            { label: i === 0 ? 'Gehuurde items' : '', value: name }
                        ))
                    }
                ].filter(Boolean) as Specs[]}
            />
        );
    });

    return upcomingPayments.length > 0 ? <>{upcomingPaymentsContent}</> : <EmptyContent title="transacties" />;
};

export default UpcomingTransactions;
