import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes as Router } from 'react-router-dom';

// Templates
import AccountLayout from 'components/templates/Account';
import AdminLayout from 'components/templates/Admin';
import ClientLayout from 'components/templates/Client';
import Profile from 'components/pages/client/Profile';
import Loading from 'components/atoms/Loading';

// Account pages
const Login = lazy(() => import('components/pages/account/Login'));
const CompleteCustomerInvitation = lazy(() => import('components/pages/account/CompleteInvitation/CompleteCustomerInvitation'));
const CompleteAdminInvitation = lazy(() => import('components/pages/account/CompleteInvitation/CompleteAdminInvitation'));
const TwoFactorAuthentication = lazy(() => import('components/pages/account/TwoFactorAuthentication'));
const ForgotPassword = lazy(() => import('components/pages/account/ForgotPassword'));
const ResetPassword = lazy(() => import('components/pages/account/ResetPassword'));
// const Wizard = lazy(() =>import('components/pages/account/Wizard'));

// Admin pages.
const AdminRentables = lazy(() => import('components/pages/admin/Rentables'));
const AdminReservations = lazy(() => import('components/pages/admin/Reservations'));
const AdminPayments = lazy(() => import('components/pages/admin/Payments'));
const AdminCustomerUsers = lazy(() => import('components/pages/admin/CustomerUsers'));

// Admin Settings pages.
const AdminAppStore = lazy(() => import('components/pages/admin/AppStore'));
const AdminSettings = lazy(() => import('components/pages/admin/Settings'));
const AdminSettingsWebsite = lazy(() => import('components/pages/admin/Settings/components/Website'));
const AdminSettingsRentableTypes = lazy(() => import('components/pages/admin/Settings/components/RentableTypes'));
const AdminSettingsAdminUsers = lazy(() => import('components/pages/admin/Settings/components/Management/AdminUsers'));
const AdminSettingsUserGroups = lazy(() => import('components/pages/admin/Settings/components/Management/UserGroups'));
const AdminSettingsDiscountVouchers = lazy(() => import('components/pages/admin/Settings/components/Management/DiscountVouchers'));
const AdminSettingsBookingSettings = lazy(() => import('components/pages/admin/Settings/components/Management/BookingSettings'));
const AdminSettingsFinanceSettings = lazy(() => import('components/pages/admin/Settings/components/Management/FinanceSettings'));

// Client pages.
const Dashboard = lazy(() => import('components/pages/client/Dashboard'));
const Search = lazy(() => import('components/pages/client/Search'));
const Contact = lazy(() => import('components/pages/client/Contact'));
const RoomDetails = lazy(() => import('components/pages/client/RoomDetails'));
const Checkout = lazy(() => import('components/pages/client/Checkout'));
const PayInvoice = lazy(() => import('components/pages/client/PayInvoice'));
const PaymentComplete = lazy(() => import('components/pages/client/PaymentComplete'));
const PaymentFailed = lazy(() => import('components/pages/client/PaymentFailed'));
const ContentPage = lazy(() => import('components/pages/client/ContentPage'));
const NotFound = lazy(() => import('components/pages/client/NotFound'));

const Routes = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Router>
                <Route path="account" element={<AccountLayout />}>
                    <Route index element={<Login />} />
                    <Route path="register/customer/:code" element={<CompleteCustomerInvitation />} />
                    <Route path="register/admin/:code" element={<CompleteAdminInvitation />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route path="authentication" element={<TwoFactorAuthentication />} />
                </Route>

                {/*<Route path="account/wizard" element={<ClientLayout />}>*/}
                {/*    <Route index element={<Wizard />} />*/}
                {/*</Route>*/}

                <Route path="/admin" element={<AdminLayout />}>
                    <Route index element={<Navigate to="/admin/reservations" replace />} />
                    <Route path="rentables" element={<AdminRentables />} />
                    <Route path="reservations" element={<AdminReservations />} />
                    <Route path="payments" element={<AdminPayments />} />
                    <Route path="customer-users" element={<AdminCustomerUsers />} />
                    <Route path="appstore" element={<AdminAppStore />} />
                    <Route path="settings" element={<AdminSettings />} />
                    <Route path="settings/website" element={<AdminSettingsWebsite />} />
                    <Route path="settings/rentable-types" element={<AdminSettingsRentableTypes />} />
                    <Route path="settings/admin-users" element={<AdminSettingsAdminUsers />} />
                    <Route path="settings/user-groups" element={<AdminSettingsUserGroups />} />
                    <Route path="settings/discount-vouchers" element={<AdminSettingsDiscountVouchers />} />
                    <Route path="settings/booking-settings" element={<AdminSettingsBookingSettings />} />
                    <Route path="settings/finance-settings" element={<AdminSettingsFinanceSettings />} />
                </Route>

                <Route path="/" element={<ClientLayout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/room/:roomId/details" element={<RoomDetails />} />
                    <Route path="/form" element={<Dashboard />} />
                    <Route path="/paymentComplete" element={<PaymentComplete />} />
                    <Route path="/paymentFailed" element={<PaymentFailed />} />
                    <Route path="/content" element={<ContentPage />} />
                </Route>

                <Route path="/" element={<ClientLayout requireAuth />}>
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/payInvoice/:invoiceId" element={<PayInvoice />} />
                    <Route path="/room/:roomId/checkout" element={<Checkout />} />
                </Route>

                <Route path="*" element={<ClientLayout />}>
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Router>
        </Suspense>
    );
};

export default Routes;
