import styled from '@emotion/styled';

export const Navigation = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem 0;
  font-size: .9rem;
  font-weight: 500;
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 2rem;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const UserWrapper = styled.div`
  padding-left: 1.5rem;
  margin-left: 1.5rem;
  border-left: 1px solid ${({ theme }) => theme.colors.white};
`;

export const Logo = styled.div<{image?: string}>`
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 11rem;
  height: 2.1rem;
  background-image: url(${({ image }) => image});
  margin-right: 2.5rem;
`;
