import React, { FC, useEffect, useState } from 'react';
import Content from 'components/atoms/Content';
import { Row, Col, Container } from 'react-grid-system';
import Tabs from 'components/molecules/Tabs';
import Breadcrumb from 'components/molecules/Breadcrumb';
import { Global, css } from '@emotion/react';
import { useTheme } from '@emotion/react';
import { Deposits, Reservations, Transactions, UserDetails } from './components';
import { useSession } from 'contexts/sessionContext';
import Loading from 'components/atoms/Loading';
import useUser from 'hooks/api/user';
import useInvoices from 'hooks/api/invoice';
import { InvoiceType } from 'hooks/api/invoice/models/invoice';
import { PriceSpecificationType } from 'hooks/api/price/models/price';
import Margin from 'components/atoms/Margin';
import { getDepositAmount } from 'utils/invoiceHelper';

const Profile: FC = () => {
    const theme = useTheme();
    const styles = css`
        body {
            background-color: ${theme.colors.light};
        }
    `;

    const { session } = useSession();

    const { useAllUsers } = useUser();
    const { data: users = [], isLoading: isLoadingUser } = useAllUsers({ id: session?.userId });
    const user = users.length > 0 ? users[0] : null;

    const { useAllInvoices } = useInvoices();
    const { data: invoices = [], isLoading: isLoadingInvoices } = useAllInvoices({ userId: user?.id });
    const [totalRemainingDeposit, setTotalRemainingDeposit] = useState(0);

    useEffect(() => {
        if (invoices.length > 0) {
            const debitInvoices = invoices.filter(i => i.type === InvoiceType.Debit && i.paidOn != null);
            const creditInvoices = invoices.filter(i => i.type === InvoiceType.Credit);
            const totalRemainingDeposit = getDepositAmount(debitInvoices, PriceSpecificationType.Deposit);
            const totalDepositRefunded = getDepositAmount(creditInvoices, PriceSpecificationType.DepositRestitution);

            setTotalRemainingDeposit(totalRemainingDeposit - totalDepositRefunded);
        }
    }, [invoices]);

    if (isLoadingUser || isLoadingInvoices || user == null) {
        return <Loading />;
    }

    return (
        <div style={{ width: '100%' }}>
            <Global styles={styles} />
            <Container>
                <Breadcrumb
                    items={[
                        { title: 'Overzicht', link: '/search' },
                        { title: 'Profiel' }
                    ]}
                />
                <Content small>
                    <Row>
                        <Col md={4}>
                            <Margin bottom={1}>
                                <UserDetails
                                    user={user}
                                    totalDeposit={totalRemainingDeposit}
                                />
                            </Margin>
                        </Col>
                        <Col md={8}>
                            <Tabs
                                responsiveStretch
                                tabs={[
                                    { header: 'Mijn reserveringen', content: <Reservations userId={user.id} /> },
                                    { header: 'Mijn transacties', content: <Transactions transactions={invoices} /> },
                                    { header: 'Borg', content: <Deposits invoices={invoices} totalDepositPaid={totalRemainingDeposit} /> }
                                ]}
                            />
                        </Col>
                    </Row>
                </Content>
            </Container>
        </div>
    );
};

export default Profile;
