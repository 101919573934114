import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from 'components/molecules/Tabs';
import Reservation from 'components/molecules/Reservation';
import { PlaceholderImage } from 'utils/constants';
import { getCurrentDate } from 'utils/dateHelper';
import { ReservationViewModel } from 'hooks/api/reservation/models/reservation';
import { cdnFileUrl } from 'utils/cdnHelper';
import { getFullAddress } from 'utils/addressHelper';
import { sortBy } from 'lodash-es';
import EmptyContent from 'components/molecules/EmptyContent';
import { parseISO } from 'date-fns';
import { formatReservationOccurrence } from 'utils/reservationHelper';
import useReservations from 'hooks/api/reservation';
import Loading from 'components/atoms/Loading';

interface ReservationsProps {
    userId: string;
}
export enum TabType { Upcoming = 0, Past = 1, Cancelled = 2 }

const Reservations: FC<ReservationsProps> = props => {
    const { userId } = props;
    const { useAllReservations } = useReservations();
    const { data: reservations = [], isLoading: isLoadingReservations } = useAllReservations({ userId });

    const navigate = useNavigate();

    if (isLoadingReservations) {
        return <Loading />;
    }

    const sortedUpcomingReservations = sortBy(reservations.filter(r => r.canceled === null && parseISO(r.nextReservationStartTime ?? '') > getCurrentDate().date), 'nextReservationStartTime', 'asc');
    const sortedPastReservations = sortBy(reservations.filter(r => parseISO(r.reservationTime.to ?? '') < getCurrentDate().date), 'reservationTime.to', 'desc');
    const sortedCancelledReservations = sortBy(reservations.filter(r => r.canceled != null), 'canceled', 'desc');

    const renderReservations = (tab: TabType, reservations: ReservationViewModel[]) => {
        const reservationContent = reservations.map(reservation => {
            const {
                id: reservationId, reservationTime, isOneTimeRental, firstPaymentPaid, canceled,
                room: { name: roomName, images: roomImages, location: { name: locationName, address } },
            } = reservation;
            const thumbnail = roomImages.length > 0 ? cdnFileUrl(roomImages[0].file) : PlaceholderImage;

            return (
                <Reservation
                    key={reservationId}
                    title={`${roomName} - ${locationName}`}
                    address={getFullAddress(address)}
                    thumbnail={thumbnail}
                    isPaid={firstPaymentPaid}
                    isCanceled={canceled != null}
                    reservationTime={formatReservationOccurrence(reservation)}
                    isRepeat={!isOneTimeRental}
                    isHistory={parseISO(reservationTime.to) < getCurrentDate().date}
                />
            );
        });

        const showAction = tab !== TabType.Cancelled
            ? !(tab === TabType.Past && sortedUpcomingReservations.length > 0)
            : false;

        return reservations.length > 0
            ? reservationContent
            : (
                <EmptyContent
                    title="reserveringen"
                    showAction={showAction}
                    action={{ title: 'Voeg reservering toe', onClick: () => navigate('/search') }}
                />
            );
    };

    return (
        <Tabs
            inline
            tabs={[
                { header: 'Aankomend', content: renderReservations(TabType.Upcoming, sortedUpcomingReservations) },
                { header: 'Historie', content: renderReservations(TabType.Past, sortedPastReservations) },
                { header: 'Geannuleerd', content: renderReservations(TabType.Cancelled, sortedCancelledReservations) }
            ]}
        />
    );
};

export default Reservations;
