import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { lighten } from 'polished';

export const Content = styled.div<{ variant: 'error' | 'warning' }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: .85rem;
    padding: 1rem;
    line-height: 1.3;
    font-weight: 500;
    border-radius: ${({ theme }) => theme.borderRadius.small}px;

    .icon-wrapper {
        margin-left: 1.5rem;
    }

    ${({ theme, variant }) =>
        variant === 'error' &&
        css`
        color: ${theme.colors.error};
        background-color: ${lighten(.475, theme.colors.error)};
        border: 1px solid ${theme.colors.error};
    `}

    ${({ theme, variant }) =>
        variant === 'warning' &&
        css`
        color: ${theme.colors.warning};
        background-color: ${lighten(.475, theme.colors.warning)};
        border: 1px solid ${theme.colors.warning};
    `}

`;
