import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';

interface CardProps {
    selected?: boolean;
    shadow?: boolean;
    light?: boolean;
}

const Card = styled.div<CardProps>`
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.dark};

    ${({ theme, shadow }) =>
    shadow &&
    css`
        box-shadow: 0px 2px 6px 0px ${rgba(theme.colors.dark, .1)};
    `}
    ${({ theme, light }) =>
    light &&
    css`
        background-color: ${theme.colors.light};
    `}
`;

export default Card;
