import styled from '@emotion/styled';
import { darken, lighten } from 'polished';
import NumberFormat from 'react-number-format';

export const FormControl = styled(NumberFormat)`
    width: 100%;
    height: 3rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: ${({ theme }) => theme.borderRadius.small}px;
    padding: 0 1rem;
    font-size: 1rem;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colors.border};
    }

    &:hover {
        border-color: ${({ theme }) => darken(.1, theme.colors.border)};
    }

    ::placeholder {
        color: ${({ theme }) => lighten(.75, theme.colors.dark)};
    }
`;
