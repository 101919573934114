export interface BorderRadius {
  small: number;
  normal: number;
  large: number;
}

const borderRadius: BorderRadius = {
  small: 5,
  normal: 10,
  large: 15,
};

export default borderRadius;