import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .65rem 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    p {
        color: ${({ theme }) => theme.colors.medium};
        font-size: .85rem;
        font-weight: 500;
        margin-bottom: 0;
    }
`;

export const Left = styled.div`
    display: flex;
    align-items: center;
    padding: 0 .25rem 0 0;
    h4 {
        margin-bottom: .15rem;
    }
`;

export const Image = styled.div<{ image: string; }>`
    position: relative;
    overflow: hidden;
    height: auto;
    width: 6.5rem;
    height: 4.5rem;
    background-image: url(${({ image }) => image});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 1rem;
`;

export const Right = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 0 .25rem;
    button {
        font-size: .85rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primary};
    }
`;

export const RightAction = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: .25rem;
    > div {
        margin-left: .75rem;
    }
    p {
        display: flex;
        align-items: center;
    }
`;

export const CheckWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.1rem;
    height: 1.1rem;
    min-width: 1.1rem;
    margin-right: .5rem;
    background-color: ${({ theme }) => theme.colors.medium};
`;
