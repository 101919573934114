import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'contexts/sessionContext';
import { defaultAreaRoute } from 'utils/areaHelper';
import { useApi } from '..';
import { CookieLoginInputModel } from './models/cookieLogin';
import { SessionModel } from './models/session';
import { ForgotPasswordInputModel } from './models/forgotPassword';
import { ResetPasswordInputModel } from './models/resetPassword';

const useAccount = () => {
    const { setSession, clearSession } = useSession();
    const { apiPost, apiPut } = useApi();
    const navigate = useNavigate();

    const login = async (values: CookieLoginInputModel, redirectUrl?: string) => {
        const response = await apiPost<SessionModel>('/account/cookielogin', values);

        if (response.ok && response.data) {
            const { claims } = response.data;
            const templateSession = setSession(claims);
            const defaultRoute = defaultAreaRoute(templateSession.role);
            navigate(redirectUrl ?? defaultRoute);
        }

        return response;
    };

    const logOut = async () => {
        const response = await apiPost('/account/cookielogout');

        if (response.ok) {
            navigate('/');
            clearSession();
        }

        return response;
    };

    const refreshSession = React.useCallback(async () => {
        const response = await apiPost<SessionModel>('/account/cookierefresh');

        if (response.ok && response.data) {
            const { claims } = response.data;
            setSession(claims);
        } else {
            clearSession();
        }

        return response;
    }, [setSession]);

    const forgotPassword = async (values: ForgotPasswordInputModel) => {
        const response = await apiPost('/account/forgotPassword', values);
        return response;
    };

    const resetPassword = async (values: ResetPasswordInputModel) => {
        const response = await apiPut('/account/resetPassword', values);
        return response;
    };


    return {
        login,
        logOut,
        refreshSession,
        forgotPassword,
        resetPassword
    };
};

export default useAccount;
