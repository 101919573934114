export enum PaymentStatus {
    NotCreated = 0,
    Pending = 1,
    Success = 2,
    Failed = 3,
    Canceled = 4,
    Refunded = 5
}

export enum PaymentMethod {
    Ideal = 0,
    MasterCard = 1,
    Visa = 2,
    DirectDebit = 3
}

export interface PaymentModel {
    id: string;
    paymentProviderReference: string;
    status: PaymentStatus;
    extraStatusInfo: string | null;
    paymentMethod: PaymentMethod;
    isRefund: boolean;
    paidOn: string | null;
    created: string;
}

export interface PerformPaymentModel {
    paymentMethod: PaymentMethod;
    // Should only be defined for iDeal payments
    issuer?: string;
}

export interface IssuerModel {
    name: string;
    value: string;
}
