import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from 'utils/breakpointHelper';

export const Menu = styled.div<{ light?: boolean }>`
    display: flex;
    align-items: center;
    ${mq[1]} {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 1rem;
    }
    ${({ theme, light }) =>
        light &&
        css`
        color: ${theme.colors.medium};
        button {
            color: ${theme.colors.medium};
            border-color: ${theme.colors.medium};
        }
    `}
`;
