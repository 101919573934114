import React, { FC } from 'react';
import { Group as Wrapper } from './styles';

export interface GroupProps {
    spaceBetween?: boolean;
    right?: boolean;
    noMargin?: boolean;
}

const Group: FC<GroupProps> = ({ children, spaceBetween, right, noMargin }) => {
    return (
        <Wrapper spaceBetween={spaceBetween} right={right} noMargin={noMargin}>
            {children}
        </Wrapper>
    );
};

export default Group;
