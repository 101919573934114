export enum DateTimeFormat {
    Day = 'eeee',
    Date = 'dd-MM-yyyy',
    DateFull = 'd MMMM yyyy',
    TimeWithSeconds = 'HH:mm:ss',
    TimeWithOutSeconds = 'HH:mm',
    DateTime = 'dd-MM-yyyy HH:mm',
    FullDateWithYear = 'EEEE d MMMM yyyy',
    DayMonth = 'd MMMM'
}

export enum Locale { NL = 'nl' }
export enum TimeZone { UTC = 'UTC' }
