import React, { FC } from 'react';
import { Image, SpecsWrapper, Inner, DropdownWrapper } from './styles';
import { Card, CardBody } from 'components/atoms/card';
import Dropdown from 'components/molecules/Dropdown';
import { useModal } from 'hooks/modal';
import { UserModel } from 'hooks/api/user/models/user';
import { cdnFileUrl } from 'utils/cdnHelper';
import { PlaceholderImage } from 'utils/constants';
import EditUserProfileModel from 'components/organisms/EditUserProfileModel';

interface Spec {
    label: string;
    value: string;
}

interface Specs {
    items: Spec[];
}

export interface ProfileCardProps {
    user: UserModel;
    specs?: Specs[];
}

const ProfileCard: FC<ProfileCardProps> = (props) => {
    const { user, specs } = props;
    const { avatar, firstName, insertion, lastName, email } = user || {};

    const [showEditUserProfileModel, hideEditUserProfileModel] = useModal(({ in: inProp, onExited }) => {
        const modalProp = { inProp, onExited, onHide: hideEditUserProfileModel };
        return (
            <EditUserProfileModel
                userId={user.id}
                modalProps={modalProp}
            />
        );
    }, [user]);

    return (
        <Card>
            <CardBody small border>
                <Card light style={{ position: 'relative' }}>
                    <DropdownWrapper>
                        <Dropdown
                            icon="dots"
                            items={[
                                {
                                    title: 'Profiel bewerken',
                                    onClick: showEditUserProfileModel,
                                    closeOnClick: true
                                }
                            ]}
                        />
                    </DropdownWrapper>
                    <Inner>
                        <Image image={avatar ? cdnFileUrl(avatar) : PlaceholderImage} />
                        <CardBody>
                            <h4>{`${firstName} ${insertion != null ? insertion : ''} ${lastName}`}</h4>
                            <p>{email}</p>
                        </CardBody>
                    </Inner>
                </Card>
            </CardBody>
            {
                specs && specs.map((s, i) => (
                    <CardBody key={i} border={i !== (specs.length - 1)}>
                        <SpecsWrapper>
                            <table>
                                {
                                    s.items.map((spec, index) => (
                                        <tr key={index}>
                                            <td>{spec.label}</td>
                                            <td>{spec.value}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </SpecsWrapper>
                    </CardBody>
                ))

            }
        </Card>
    );
};

export default ProfileCard;
