import { useQuery } from 'react-query';
import { appendToForm } from 'utils/formDataHelper';
import { CustomError, useApi } from '..';
import { InvoiceModel, PayInvoiceModel, TakeOverByAdministrationInputModel } from './models/invoice';
import { SearchInvoiceInputModel } from './models/search';

export const searchInvoicesQueryKey = 'invoice.search';

const useInvoices = () => {
    const baseUrl = '/invoice';
    const { apiGet, apiPost } = useApi();

    const useAllInvoices = (params?: SearchInvoiceInputModel) => {
        const queryKey = [searchInvoicesQueryKey, params];

        return useQuery<InvoiceModel[], CustomError[]>(queryKey, async () => {
            const response = await getAllInvoices(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const getAllInvoices = async (params?: SearchInvoiceInputModel) => {
        return await apiPost<InvoiceModel[]>(`${baseUrl}/search`, params);
    };

    const payInvoice = async (model: PayInvoiceModel) => {
        const formData = new FormData();
        appendToForm(formData, model);
        return await apiPost<string>(`${baseUrl}/pay`, formData);
    };

    const downloadInvoicePdfDocument = async (invoiceId: string) => {
        return await apiGet<string>(`${baseUrl}/download/${invoiceId}`);
    };

    const takeOverByAdministration = async (model: TakeOverByAdministrationInputModel) => {
        return await apiPost(`${baseUrl}/takeOverByAdministration`, model);
    };

    return {
        useAllInvoices,
        getAllInvoices,
        payInvoice,
        downloadInvoicePdfDocument,
        takeOverByAdministration
    };
};

export default useInvoices;
