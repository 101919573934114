import styled from '@emotion/styled';

export const Image = styled.div<{ image: string; }>`
    position: relative;
    overflow: hidden;
    height: auto;
    width: 5rem;
    min-height: 5rem;
    background-image: url(${({ image }) => image});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const Inner = styled.div`
    min-height: 5rem;
    display: flex;
    padding: .25rem;
    h4, p {
        margin-bottom: 0;
    }
    p {
        font-weight: 500;
        font-size: .85rem;
        color: ${({ theme }) => theme.colors.medium};
    }
    > div {
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const SpecsWrapper = styled.div`
    font-size: .85rem;
    table {
        width: 100%;
    }
    td {
        &:first-of-type {
            width: 45%;
            padding-left: 0;
            color: ${({ theme }) => theme.colors.medium};
        }
        &:last-of-type {
            width: 55%;
            padding-right: 0;
        }
    }
`;

export const DropdownWrapper = styled.div`
   position: absolute;
   top: 0;
   right: 0;
   padding: .25rem .5rem;
`;
