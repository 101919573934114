import styled from '@emotion/styled';

export const ThumbnailWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  padding: 1rem;
  display: flex;
  justify-content: center;
  > div {
    max-width: 10rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 -.5rem;
  button {
    flex: 1;
    margin: 0 .5rem;
    justify-content: center;
  }
`;
