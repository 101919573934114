import React, { FC } from 'react';
import Modal, { ModalProps } from 'components/organisms/Modal';
import useCustomer from 'hooks/api/customer';
import { AddCustomerInputModel } from 'hooks/api/customer/models/customer';
import RegisterUserForm from 'components/organisms/account/RegistrationForm';

interface RegisterProps {
    redirectUrl?: string;
    modalProps: ModalProps;
}

const Register: FC<RegisterProps> = (props) => {
    const { redirectUrl, modalProps: { inProp, onExited, onHide } } = props;
    const { addCustomer } = useCustomer();

    const initialValues: AddCustomerInputModel = {
        firstName: '',
        insertion: undefined,
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: '',
    };

    return (
        <Modal title="Registreren" size="small" inProp={inProp} onExited={onExited} onHide={onHide}>
            <RegisterUserForm
                initialValues={initialValues}
                onRegister={values => addCustomer(values)}
                redirectUrl={redirectUrl}
                onHide={onHide}
            />
        </Modal>
    );
};

export default Register;
