import styled from '@emotion/styled';
import { lighten } from 'polished';

export const Account = styled.span`
  margin-top: 2rem;
  display: flex;
  justify-content: center;

  &,
  a, button {
    color: ${({ theme }) => lighten(.6, theme.colors.dark)};
    font-size: 1rem;
    text-decoration: none;

    span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  span {
    color: ${({ theme }) => lighten(.6, theme.colors.dark)};
    font-size: 1rem;
    text-decoration: none;
    margin-bottom: 2rem;

    strong {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export default Account;
