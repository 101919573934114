import { useTheme } from '@emotion/react';
import { Button } from 'components/atoms/button';
import Item, { DropdownItemProps } from 'components/atoms/DropdownItem';
import Icon from 'components/atoms/Icon';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown as Wrapper, Header, Items } from './styles';

export interface DropdownProps {
    items: DropdownItemProps[];
    icon?: string;
    hideIcon?: boolean;
}

const Dropdown: FC<DropdownProps> = ({ children, items, icon = 'chevron-down', hideIcon }) => {
    const ref = useRef<HTMLUListElement>(null);
    const [open, setOpen] = useState(false);

    const theme = useTheme();

    // Dropdown's methods.
    const toggle = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        setOpen((open) => !open);
    }, []);

    const handleOnClick = useCallback((event: MouseEvent) => {
        if (ref.current !== null && !ref.current.contains(event.target as Node)) {
            setOpen(false);
        }
    }, []);

    // Dropdown's life cycle.
    useEffect(() => {
        if (open) {
            window.addEventListener('click', handleOnClick);
        }

        return () => window.removeEventListener('click', handleOnClick);
    }, [open, handleOnClick]);

    return (
        <Wrapper>
            <Header onClick={toggle}>
                {typeof children === 'string' ? <Button>{children}</Button> : children}
                {!hideIcon && <Icon color={theme.colors.medium} name={icon} size={.85} />}
            </Header>
            <Items ref={ref} open={open}>
                {items.map((item) => (
                    <Item key={item.title} setOpen={setOpen} {...item} />
                ))}
            </Items>
        </Wrapper>
    );
};

export default Dropdown;
