import React, { FC, InputHTMLAttributes } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import { FormTextArea as Wrapper } from './styles';

export interface FormTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    error?: string;
    darkBorder?: boolean;
}

const FormTextArea: FC<FormTextAreaProps> = ({ error, ...props }) => {
    return (
        <>
            <Wrapper {...props} rows={3} />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormTextArea;
