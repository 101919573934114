import { Col } from 'react-grid-system';
import styled from '@emotion/styled';
import { PlaceholderImage } from 'utils/constants';

const horizontalPadding = (screenClass: string): number => {
  switch (screenClass) {
    case 'xs':
    case 'sm':
    case 'md':
      return 1;
    case 'lg':
      return 4;
    default:
      return 6;
  }
};

export const Wrapper = styled.div<{
  screenClass: string;
}>`
  margin-top: 14rem;
  padding: 0 ${({ screenClass }) => horizontalPadding(screenClass)}rem;
  display: flex;
  flex-direction: column;
`;

export const Background = styled(Col)`
  background-color: ${({ theme }) => theme.colors.dark};
  background-image: url(${PlaceholderImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
`;
