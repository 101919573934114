import { ButtonProps } from 'components/atoms/button/Button';
import React, { FC } from 'react';
import { CardHeader as Wrapper, Title, Subtitle, Text, Check } from './styles';

export interface CardHeaderProps {
    title: string;
    subtitle?: string;
    buttons?: ButtonProps[];
    name?: string;
}

const CardHeader: FC<CardHeaderProps> = ({ title, subtitle, name }) => {
    return (
        <Wrapper>
            {name && <Check name={name} />}
            <Text>
                <Title>{title}</Title>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </Text>
        </Wrapper>
    );
};

export default CardHeader;
