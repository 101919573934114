import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { darken, lighten } from 'polished';

export const FormControl = styled.input<{ disabled?: boolean }>`
    width: 100%;
    height: 3rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: ${({ theme }) => theme.borderRadius.small}px;
    padding: 0 1rem;
    font-size: 1rem;

    ${({ theme, disabled }) =>
        disabled &&
        css`
            background-color: ${theme.greys[25]};
            border-color: ${darken(.1, theme.colors.border)};
        `};

    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colors.border};
    }

    &:hover {
        border-color: ${({ theme }) => darken(.1, theme.colors.border)};
    }

    ::placeholder {
        color: ${({ theme }) => lighten(.75, theme.colors.dark)};
    }
`;
