import Menu from 'components/molecules/Menu';
import React, { FC, useState } from 'react';
import { Navigation as Wrapper, Inner, Logo, Left, Right, NavToggle, NavBackdrop } from './styles';
import { Container } from 'react-grid-system';
import { Button } from 'components/atoms/button';
import useAccount from 'hooks/api/account';
import { useSession } from 'contexts/sessionContext';
import { useModal } from 'hooks/modal';
import { LoginModal, RegisterModal } from './components';
import { Link } from 'react-router-dom';
import Icon from 'components/atoms/Icon';
import { useAppSettings } from 'contexts/settingsContext';

interface ClientNavigationProps { }

const ClientNavigation: FC<ClientNavigationProps> = () => {
    const { logOut } = useAccount();
    const session = useSession();
    const isLoggedIn = session.session?.userId != null;
    const { website: { logo, websiteUrl, headerReturnUrlText } } = useAppSettings();

    const [menuOpen, setMenuOpen] = useState(false);

    const [showLoginModal, hideLoginModal] = useModal(({ in: inProp, onExited }) => {
        const modalProp = { inProp, onExited, onHide: hideLoginModal };
        return (
            <LoginModal
                modalProps={modalProp}
                showRegisterModal={showRegisterModal}
            />
        );
    }, [session]);

    const [showRegisterModal, hideRegisterModal] = useModal(({ in: inProp, onExited }) => {
        const modalProp = { inProp, onExited, onHide: hideRegisterModal };
        return (
            <RegisterModal
                modalProps={modalProp}
            />
        );
    }, [session]);

    // Render.
    return (
        <>
            <NavBackdrop open={menuOpen} onClick={() => setMenuOpen(false)} />
            <Wrapper>
                <Container>
                    <Inner>
                        <Left>
                            <Link to="/">
                                <Logo image={logo} />
                            </Link>
                            {
                                websiteUrl != null &&
                                <Button variant="light" startIcon="arrow-left" onClick={() => window.location.replace(websiteUrl)}>
                                    {headerReturnUrlText ?? 'Over'}
                                </Button>
                            }
                        </Left>
                        <NavToggle onClick={() => setMenuOpen(!menuOpen)}>
                            <Icon name={menuOpen ? 'cross' : 'hamburger'} size={2} />
                        </NavToggle>
                        <Right open={menuOpen}>
                            <Menu items={[]} />
                            <Menu
                                light
                                items={[
                                    {
                                        title: isLoggedIn ? 'Uitloggen' : 'Inloggen',
                                        to: '#',
                                        onClick: isLoggedIn ? logOut : showLoginModal
                                    },
                                    {
                                        title: 'Mijn profiel ',
                                        to: '/profile',
                                        isVisible: isLoggedIn
                                    }
                                ]}
                            />
                        </Right>
                    </Inner>
                </Container>
            </Wrapper>
        </>
    );
};

export default ClientNavigation;
