import React, { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import FormSelect, { FormSelectProps, IOption } from '..';
import { OnChangeValue } from 'react-select';

interface FormikFormSelectProps extends FormSelectProps {
    name: string;
    errorFields?: string[];
    isSearchable?: boolean;
}

const FormikFormSelect: FC<FormikFormSelectProps> = ({ name, onChange, options, isMulti, ...props }) => {
    const { errorFields = [] } = props;
    const { errors } = useFormikContext();
    const [field, meta, helpers] = useField(name);

    const handleOnChange = (option: OnChangeValue<IOption | IOption[], boolean>) => {
        const value = isMulti
            ? (option as IOption[]).map((item: IOption) => item.value)
            : (option as IOption).value;

        helpers.setValue(value);
        onChange && onChange(option);
    };

    // Render.
    const otherFieldErrors = errorFields.map((errorField: any) => (errors as any)[errorField]);
    const allErrors = [meta.error, ...otherFieldErrors].filter(Boolean);
    const error = allErrors.length > 0 ? allErrors[0] : undefined;

    const getValue = () => {
        if (options) {
            return isMulti
                ? options.filter((option: IOption) => field.value.indexOf(option.value) >= 0)
                : options.find((option: IOption) => option.value === field.value);
        } else {
            return isMulti ? [] : ('' as any);
        }
    };

    return (
        <FormSelect
            {...field}
            value={getValue()}
            {...props}
            onChange={handleOnChange}
            onMenuOpen={() => helpers.setTouched(false)}
            onMenuClose={() => helpers.setTouched(true)}
            error={error}
            options={options}
            isMulti={isMulti}
        />
    );
};

export default FormikFormSelect;
