import React, { FC } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormGroup, FormikFormControl } from 'components/atoms/form';

const UserSection: FC = () => {
    return (
        <>
            <h4>Persoonsgegevens</h4>
            <Row>
                <Col sm={4}>
                    <FormGroup label="Voornaam" required>
                        <FormikFormControl
                            placeholder="Voornaam"
                            name="firstName"
                            errorFields={['firstName']}
                            disabled
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Tussenvoegsel">
                        <FormikFormControl
                            placeholder="Tussenvoegsel"
                            name="insertion"
                            errorFields={['insertion']}
                            disabled
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Achternaam" required>
                        <FormikFormControl
                            placeholder="Achternaam"
                            name="lastName"
                            errorFields={['lastName']}
                            disabled
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col sm={6}>
                    <FormGroup label="E-mail" required>
                        <FormikFormControl
                            placeholder="E-mail"
                            name="email"
                            errorFields={['email']}
                        />
                    </FormGroup>
                </Col>

                <Col sm={6}>
                    <FormGroup label="Telefoonnummer" required>
                        <FormikFormControl
                            placeholder="Telefoonnummer"
                            name="phoneNumber"
                            errorFields={['phoneNumber']}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <hr />
        </>
    );
};

export default UserSection;
