import { useField, useFormikContext } from 'formik';
import React, { FC } from 'react';
import FormImage, { FormImageProps } from '..';

export interface FormikFormImageProps extends FormImageProps {
    name: string;
    errorFields?: string[];
}

const FormikFormImage: FC<FormikFormImageProps> = (props) => {
    const { name, errorFields = [] } = props;
    const [field, meta, helpers] = useField(name);
    const { errors } = useFormikContext();

    const otherFieldErrors = errorFields.map((errorField: any) => (errors as any)[errorField]);
    const allErrors = [meta.error, ...otherFieldErrors].filter(Boolean);
    const error = allErrors.length > 0 ? allErrors[0] : undefined;

    return <FormImage {...props} value={field.value} onChange={helpers.setValue} error={error} />;
};

export default FormikFormImage;
