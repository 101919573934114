import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';
import { mq } from 'utils/breakpointHelper';

export const Inner = styled.div<{ inline?: boolean; }>`
    ${({ inline }) =>
        inline &&
        css`
        padding: 1rem 0;
    `}
`;

export const ButtonWrapper = styled.div<{ light?: boolean; inline?: boolean; responsiveStretch?: boolean; }>`
    display: inline-flex;
    background-color: ${({ theme, }) => rgba(theme.colors.dark, .1)};

    ${({ theme, inline }) =>
        inline &&
        css`
        width: 100%;
        background-color: transparent;
        border-bottom: 1px solid ${theme.colors.border};
    `}

    ${({ theme, light }) =>
        light &&
        css`
        background-color: ${theme.colors.light};
    `}

    ${({ responsiveStretch }) =>
        responsiveStretch &&
        css`
        ${mq[1]} {
            display: flex;
            flex: 1;
        }
    `}
`;

export const TabItem = styled.button<{ active?: boolean; inline?: boolean; responsiveStretch?: boolean; }>`
    line-height: 1;
    padding: 1.25rem ${({ theme }) => theme.card.padding}px;
    color: ${({ theme }) => theme.colors.medium};
    overflow: hidden;
    font-weight: 500;
    
    ${({ theme, active }) =>
        active &&
        css`
        background-color: ${theme.colors.white};
        color: ${theme.colors.dark};
    `}
    
    ${({ theme, inline, active }) =>
        inline &&
        css`
        position: relative;
        z-index: 1;
        padding: 0 0 1rem 0;
        margin-bottom: -1px;
        margin-right: 1rem;
        background-color: transparent;
        border-bottom: 1px solid ${active ? theme.colors.dark : 'transparent'};
    `}

    ${({ responsiveStretch }) =>
        responsiveStretch &&
        css`
        ${mq[1]} {
            flex: 1;
        }
    `}
`;
