import React, { createContext, FC, useContext } from 'react';
import { ThemeProvider } from '@emotion/react';
import { DefaultAppLogo, DefaultTheme, PlaceholderImage } from 'utils/constants';
import useSettings from 'hooks/api/settings';
import Loading from 'components/atoms/Loading';
import { cdnFileUrl } from 'utils/cdnHelper';

interface WebsiteStylingContextValue {
    logo: string;
    letterPaper?: string;
    banner: string;
    bannerMainText?: string;
    bannerSubText?: string;
    primaryColor: string;
    headerReturnUrlText: string | null;
    websiteUrl?: string;
    facebookUrl?: string;
    instagramUrl?: string;
    privacyUrl?: string;
    termsAndConditionsUrl?: string;
}

interface BookingSettingsContextValue {
    bookingUnitMinutes: number;
}

interface AppSettingsContextValue {
    organisationName?: string;
    defaultOrganisationName: string;
    website: WebsiteStylingContextValue;
    booking: BookingSettingsContextValue;
    refetchSetting?: () => Promise<unknown>;
}

const defaultContextValues: AppSettingsContextValue = {
    defaultOrganisationName: 'Qbio',
    website: {
        logo: DefaultAppLogo.dark,
        banner: PlaceholderImage,
        primaryColor: DefaultTheme.colors.primary,
        headerReturnUrlText: null
    },
    booking: {
        bookingUnitMinutes: 15
    }
};

const AppSettingsContext = createContext(defaultContextValues);

export const AppSettingsProvider: FC = ({ children }) => {
    const { useSiteSettings } = useSettings();
    const { data: setting = null, isLoading: isLoadingSetting, refetch: refetchSetting } = useSiteSettings();

    if (setting === null || isLoadingSetting) {
        return <Loading />;
    }

    const theme = { ...DefaultTheme, colors: { ...DefaultTheme.colors, primary: setting.primaryColor } };
    const settingsContextValue: AppSettingsContextValue = {
        organisationName: setting.organisationName,
        defaultOrganisationName: defaultContextValues.defaultOrganisationName,
        website: {
            logo: setting.logo ? cdnFileUrl(setting.logo) : defaultContextValues.website.logo,
            letterPaper: setting.letterPaper ? cdnFileUrl(setting.letterPaper) : undefined,
            banner: setting.banner ? cdnFileUrl(setting.banner) : defaultContextValues.website.banner,
            bannerMainText: setting.bannerMainText,
            bannerSubText: setting.bannerSubText,
            primaryColor: setting.primaryColor,
            headerReturnUrlText: setting.headerReturnUrlText ?? null,
            websiteUrl: setting.websiteUrl ?? undefined,
            facebookUrl: setting.facebookUrl ?? undefined,
            instagramUrl: setting.instagramUrl ?? undefined,
            privacyUrl: setting.privacyUrl ?? undefined,
            termsAndConditionsUrl: setting.termsAndConditionsUrl ?? undefined
        },
        refetchSetting,
        booking: {
            bookingUnitMinutes: setting.bookingUnitMinutes
        },
    };

    return (
        <AppSettingsContext.Provider value={settingsContextValue}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </AppSettingsContext.Provider>
    );
};

export const useAppSettings = () => {
    const context = useContext(AppSettingsContext);

    if (!context) {
        throw new Error('useAppSettings must be used within a AppSettingsProvider');
    }

    return context;
};
