import React, { FC } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormCheck, FormGroup, FormikFormControl } from 'components/atoms/form';
import usePostalCode from 'hooks/api/postalCode';
import { useFormikContext } from 'formik';
import { EditUserInputModel } from 'hooks/api/user/models/user';
import { toFormikErrors } from 'utils/errorhelper';

interface AddressSectionProps {
    invoiceAddressIsEqualToAddress: boolean;
    setInvoiceAddressIsEqualToAddress: (isEqual: boolean) => void;
}

const AddressSection: FC<AddressSectionProps> = (props) => {
    const { invoiceAddressIsEqualToAddress, setInvoiceAddressIsEqualToAddress } = props;
    const { values: { addres }, setFieldValue, setErrors, handleBlur } = useFormikContext<EditUserInputModel>();
    const { getAddress } = usePostalCode();

    const address = 'addres';
    const invoiceAddress = 'invoiceAddres';

    const setFormikFieldValue = (fields: {name: string, value: any}[]) =>
        fields.forEach(field => setFieldValue(field.name, field.value));

    const addressFieldsOnBlur = async (event: React.FocusEvent<any>) => {
        handleBlur(event);
        const { postalCode, houseNumber, houseNumberAddition } = addres ?? { postalCode: '', houseNumber: '', houseNumberAddition: '' };
        const response = await getAddress(postalCode, houseNumber);

        if (response.data) {
            const { street, city, coordinates: { longitude, latitude } } = response.data;
            const addressFields = [
                { name: `${address}.street`, value: street },
                { name: `${address}.city`, value: city },
                { name: `${address}.country`, value: 'Nederland' },
                { name: `${address}.coordinates.longitude`, value: longitude },
                { name: `${address}.coordinates.latitude`, value: latitude },
            ];

            setFormikFieldValue(addressFields);

            if (invoiceAddressIsEqualToAddress) {
                const invoiceAddressFields = [
                    { name: `${invoiceAddress}.postalCode`, value: postalCode },
                    { name: `${invoiceAddress}.houseNumber`, value: houseNumber },
                    { name: `${invoiceAddress}.houseNumberAddition`, value: houseNumberAddition },

                    { name: `${invoiceAddress}.street`, value: street },
                    { name: `${invoiceAddress}.city`, value: city },
                    { name: `${invoiceAddress}.country`, value: 'Nederland' },
                    { name: `${invoiceAddress}.coordinates.longitude`, value: longitude },
                    { name: `${invoiceAddress}.coordinates.latitude`, value: latitude },
                ];

                setFormikFieldValue(invoiceAddressFields);
            }
        } else if (response.status === 404) {
            const emptyValue = '';

            setFieldValue(`${address}.street`, emptyValue);
            setFieldValue(`${address}.city`, emptyValue);
            setFieldValue(`${address}.country`, emptyValue);
        } else if (response.errors) {
            const addressErrors = response.errors.map(error => {
                return {
                    ...error,
                    key: `${address}.${error.key}`
                };
            });
            setErrors(toFormikErrors(addressErrors));
        }
    };

    return (
        <>
            <h4>Adresgegevens</h4>
            <Row>
                <Col sm={4}>
                    <FormGroup label="Postcode" required>
                        <FormikFormControl
                            placeholder="Postcode"
                            name={`${address}.postalCode`}
                            errorFields={[`${address}.postalCode`]}
                            onChange={event => {
                                const { name, value: postalCode } = event.target;
                                setFieldValue(name, postalCode.toUpperCase());
                            }}
                            onBlur={addressFieldsOnBlur}
                        />
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup label="Huisnummer" required>
                        <FormikFormControl
                            placeholder="Huisnummer"
                            name={`${address}.houseNumber`}
                            errorFields={[`${address}.houseNumber`]}
                            onBlur={addressFieldsOnBlur}
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Toevoeging">
                        <FormikFormControl
                            placeholder="Toevoeging"
                            name={`${address}.houseNumberAddition`}
                            errorFields={[`${address}.houseNumberAddition`]}
                            onChange={event => {
                                const { name, value: houseNumberAddition } = event.target;
                                setFieldValue(name, houseNumberAddition.toUpperCase());
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Straat" required>
                        <FormikFormControl
                            placeholder="Straat"
                            name={`${address}.street`}
                            errorFields={[`${address}.street`]}
                            disabled
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Woonplaats" required>
                        <FormikFormControl
                            placeholder="Woonplaats"
                            name={`${address}.city`}
                            errorFields={[`${address}.city`]}
                            disabled
                        />
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup label="Land" required>
                        <FormikFormControl
                            placeholder="Land"
                            name={`${address}.country`}
                            errorFields={[`${address}.country`]}
                            disabled
                        />
                    </FormGroup>
                </Col>
            </Row>

            <FormCheck
                label="Factuuradres is hetzelfde als adres"
                name="invoiceAddressIsEqualToAddress"
                checked={invoiceAddressIsEqualToAddress}
                onChange={() => {
                    setInvoiceAddressIsEqualToAddress(!invoiceAddressIsEqualToAddress);

                    const invoiceAddressFields = [
                        { name: `${invoiceAddress}.postalCode`, value: addres?.postalCode },
                        { name: `${invoiceAddress}.houseNumber`, value: addres?.houseNumber },
                        { name: `${invoiceAddress}.houseNumberAddition`, value: addres?.houseNumberAddition },
                        { name: `${invoiceAddress}.street`, value: addres?.street },
                        { name: `${invoiceAddress}.city`, value: addres?.city },
                        { name: `${invoiceAddress}.country`, value: addres?.country },
                        { name: `${invoiceAddress}.coordinates.longitude`, value: addres?.coordinates.longitude },
                        { name: `${invoiceAddress}.coordinates.latitude`, value: addres?.coordinates.latitude }
                    ];

                    const emptyValue = '';
                    const emptyInvoiceAddressFields = [
                        { name: `${invoiceAddress}.postalCode`, value: emptyValue },
                        { name: `${invoiceAddress}.houseNumber`, value: emptyValue },
                        { name: `${invoiceAddress}.houseNumberAddition`, value: emptyValue },
                        { name: `${invoiceAddress}.street`, value: emptyValue },
                        { name: `${invoiceAddress}.city`, value: emptyValue },
                        { name: `${invoiceAddress}.country`, value: emptyValue },
                        { name: `${invoiceAddress}.coordinates.longitude`, value: emptyValue },
                        { name: `${invoiceAddress}.coordinates.latitude`, value: emptyValue }
                    ];

                    if (invoiceAddressIsEqualToAddress) {
                        setFormikFieldValue(emptyInvoiceAddressFields);
                    } else {
                        setFormikFieldValue(invoiceAddressFields);
                    }
                }}
            />

            <hr />
        </>
    );
};

export default AddressSection;
