import React, { FC } from 'react';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import InvalidFeedback from '../InvalidFeedback';

export interface WysiwygEditorProps extends IAllProps {
    error?: string;
}

const WysiwygEditor: FC<WysiwygEditorProps> = (props) => {

    const {
        error,
        init,
        ...otherProps
    } = props;

    return (
        <>
            <Editor
                {...otherProps}
                initialValue={undefined}
                scriptLoading={{ async: true }}
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                init={{
                    ...init,
                    plugins: 'print lists fullscreen preview autoresize table',
                    promotion: false
                }}
            />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );

};

export default WysiwygEditor;
