import { FC, useCallback, useState } from 'react';
import { useModal as useReactModal } from 'react-modal-hook';

const useModal = (component: FC<any>, inputs?: any[]): [() => void, () => void, boolean] => {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [show, hide] = useReactModal(component, inputs);

    // Methods.
    const showModal = useCallback(() => {
        show();
        setIsShowing(true);
    }, [show]);

    const hideModal = useCallback(() => {
        hide();
        setIsShowing(false);
    }, [hide]);

    return [showModal, hideModal, isShowing];
};

export default useModal;
