import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

Sentry.init({
    dsn: 'https://e2d89530d2a2449386b4798d32b2db66@utility2.bluenotion.nl/9',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
    enabled: process.env.NODE_ENV !== 'development'
});

ReactDOM.render(
    <StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </StrictMode>,
    document.getElementById('root')
);
