import React, { FC } from 'react';
import Transaction, { Specs } from 'components/molecules/Transaction';
import { getPaymentMethodLabel } from 'utils/paymentHelper';
import { rentalPeriodToString, parseToDate, parsePeriodModel, periodToString, formatInUtc } from 'utils/dateHelper';
import { DateTimeFormat } from 'utils/constants';
import { PeriodModel } from 'hooks/api/global/models/dateTimePeriod';
import { InvoiceModel, InvoiceStatus, InvoiceType } from 'hooks/api/invoice/models/invoice';
import { getInvoiceStatusType, getInvoiceStatusLabel } from 'utils/invoiceHelper';
import { sortBy } from 'lodash-es';
import { PriceSpecificationType } from 'hooks/api/price/models/price';
import EmptyContent from 'components/molecules/EmptyContent';
import { parseISO } from 'date-fns';

export interface TransactionHistoryProps {
    transactions: InvoiceModel[];
}

const TransactionHistory: FC<TransactionHistoryProps> = (props) => {
    const { transactions } = props;
    const sortedTransactions = sortBy(transactions, 'created', 'desc');

    const transactionsContent = sortedTransactions.map(invoice => {
        const {
            id: invoiceId,
            pdfDocumentName,
            totalAmount,
            status,
            paidOn,
            reservations,
            paymentMethod,
            period,
            specifications,
            type,
            created
        } = invoice;

        const isDebitInvoice = type === InvoiceType.Debit;

        const { reservationTime, isOneTimeRental } = reservations[0];
        const roomNames = Array.from(new Set(reservations.map(r => `${r.room.name} - ${r.room.location.name}`))).join(', ');
        const roomTypes = Array.from(new Set(reservations.map(r => r.room.roomType))).join(', ');

        let formattedReservationTime = '';
        if (isOneTimeRental) {
            const parsedReservationTime = parseToDate(reservationTime.from, reservationTime.to);
            const mappedReservationTime: PeriodModel = { from: parsedReservationTime.from, to: parsedReservationTime.to ?? parsedReservationTime.from };
            formattedReservationTime = rentalPeriodToString(mappedReservationTime);
        } else {
            formattedReservationTime = period != null ? periodToString(parsePeriodModel(period)) : '-';
        }

        const rents = specifications.filter(s => s.type === PriceSpecificationType.Rent);
        const rentedOptions = Array.from(new Set(rents.filter(s => !reservations.map(r => r.room.name).includes(s.description))));
        const hasRentedOptions = rentedOptions.length > 0;

        const paymentDateOrPeriod = paidOn != null
            ? formatInUtc(parseISO(paidOn), DateTimeFormat.Date)
            : formatInUtc(parseISO(created), DateTimeFormat.Date);

        const emptySpecItem = { label: '', value: '' };

        return (
            <Transaction
                key={invoiceId}
                invoiceId={invoiceId}
                pdfDocumentName={pdfDocumentName}
                amount={status === InvoiceStatus.Paid ? -totalAmount : totalAmount}
                paymentDateOrPeriod={paymentDateOrPeriod}
                status={{ title: getInvoiceStatusLabel(status), type: getInvoiceStatusType(status) }}
                invoiceStatus={status}
                invoiceType={type}
                specs={[
                    {
                        items: [
                            { label: 'Soort ruimte', value: roomTypes },
                            { label: 'Naam ruimte', value: roomNames },
                            { label: 'Huurperiode', value: formattedReservationTime },
                            isDebitInvoice ? { label: 'Betaalmethode: ', value: getPaymentMethodLabel(paymentMethod) } : emptySpecItem,
                        ]
                    },
                    hasRentedOptions && {
                        items: rentedOptions.map(o => (
                            { label: 'Gehuurde items', value: o.description }
                        ))
                    }
                ].filter(Boolean) as Specs[]}
            />
        );
    });

    return transactions.length > 0 ? <>{transactionsContent}</> : <EmptyContent title="transacties" />;
};

export default TransactionHistory;
