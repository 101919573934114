import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TransitionStatus, ENTERING, ENTERED, EXITING, EXITED } from 'react-transition-group/Transition';
import { Paragraph } from 'components/atoms/text';

export const Overlay = styled.div<{ isSideModal: boolean; state: TransitionStatus; duration: number }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    transition: opacity ${({ duration }) => duration}ms ease;
    opacity: 0;

    ${({ isSideModal }) =>
        isSideModal &&
        css`
        align-items: flex-start;
        justify-content: flex-end;
    `}

    ${({ state }) =>
        (state === ENTERING || state === ENTERED) &&
        css`
            opacity: 1;
        `}

    ${({ state }) =>
        (state === EXITING || state === EXITED) &&
        css`
            opacity: 0;
        `}
`;

export const Modal = styled.div<{ isSideModal: boolean; state: TransitionStatus; duration: number; size: 'small' | 'medium' | 'large' | 'full' }>`
    max-width: 1200px;
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    margin: 2.5rem 0;
    max-height: calc(100% - 5rem);
    transition: transform ${({ duration }) => duration}ms ease;
    transform: translateY(-24px);
	display: flex;
    flex-direction: column;
    overflow: hidden;

    ${({ isSideModal }) =>
        isSideModal &&
        css`
        border-radius: 0;
        height: 100%;
        max-height: unset;
        background: white;
        margin: 0;
    `}

    ${({ state }) =>
        (state === ENTERING || state === ENTERED) &&
        css`
            transform: inherit;
        `}

        ${({ state }) =>
        (state === EXITING || state === EXITED) &&
        css`
                transform: translateY(-24px);
            `}

        ${({ size }) =>
        (size === 'small') &&
        css`
            width: 500px;
        `}
        ${({ size }) =>
        (size === 'large') &&
        css`
            width: 1100px;
        `}
       
        ${({ size }) =>
        (size === 'full') &&
        css`
            width: 97%;
            height: 100%;
        `}
`;

export const Content = styled.div<{ tabbedContent: boolean; size: 'small' | 'medium' | 'large' | 'full' }>`

    ${({ tabbedContent }) =>
        !tabbedContent &&
        css`
        padding: 2rem;
    `}

    ${({ tabbedContent }) =>
        tabbedContent &&
        css`
        padding: 0 2rem 2rem 2rem;
    `}

    background-color: ${({ theme, tabbedContent }) => tabbedContent ? theme.colors.light : theme.colors.white};
    overflow-y: auto;

    ${({ size }) =>
        (size === 'full') &&
        css`
            width: 100%;
            height: 100%;
        `}
`;

export const Close = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    padding: 1rem;
    background: none;
    border: 0;
    outline: none;
`;

export const Header = styled.div<{ isSideModal: boolean; }>`
    background-color: ${({ theme }) => theme.colors.light};
    border-top-left-radius: ${({ theme }) => theme.borderRadius.normal}px;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.normal}px;
    padding: 2rem;
    text-align: center;

    ${({ isSideModal }) =>
        isSideModal &&
        css`
            border-radius: 0;
        `}
`;

export const Title = styled.h3`
    font-size: 2rem;
    margin-bottom: 0;
`;

export const Subtitle = styled(Paragraph)`
    margin-bottom: 0;
`;

export const Footer = styled.div`
    background-color: ${({ theme }) => theme.colors.light};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.normal}px;
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.normal}px;
    padding: 1.5rem 32px;
`;
