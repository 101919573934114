import { useApi } from 'hooks/api';
import { AddressFromPostalCodeResultModel } from './models/addressFromPostalCode';

const usePostalCode = () => {
    const { apiPost } = useApi();

    const getAddress = async (postalCode: string, houseNumber: string) => {
        return await apiPost<AddressFromPostalCodeResultModel>(`/postalCode/${postalCode}/houseNumber/${houseNumber}`);
    };

    return {
        getAddress
    };
};

export default usePostalCode;
