export const formatToCurrency = (value?: number): string => {

    if (value == null) {
        return 'Prijs niet bekend';
    }

    return new Intl.NumberFormat(
        'nl-NL',
        {
            style: 'currency', currency: 'EUR'
        }).format(value);
};
