import React, { FC } from 'react';
import { NumberFormatProps, NumberFormatValues, SourceInfo } from 'react-number-format';
import InvalidFeedback from 'components/atoms/form/InvalidFeedback';
import { FormControl as Wrapper } from './styles';

export interface FormControlNumberProps extends NumberFormatProps {
    prefix?: string;
    suffix?: string;
    format?: string;
    value: number;
    placeholder?: string;
    thousandSeparator?: boolean;
    onValueChange?: (values: NumberFormatValues, sourceInfo: SourceInfo) => void;
    error?: string;
}

const FormControlNumber: FC<FormControlNumberProps> = props => {
    const { thousandSeparator, placeholder, prefix, suffix, format, value, onValueChange, error, disabled } = props;
    return (
        <>
            <Wrapper
                value={value}
                placeholder={placeholder}
                decimalScale={2}
                thousandSeparator={!thousandSeparator ? false : '.'}
                decimalSeparator={','}
                allowNegative={false}
                prefix={prefix}
                suffix={suffix}
                format={format}
                onValueChange={onValueChange}
                disabled={disabled}
            />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormControlNumber;
