import { AddressModel } from 'hooks/api/location/models/address';

export const getFullAddress = (address?: AddressModel): string => {
    if (address === undefined) {
        return 'Address undefined';
    }

    const houseNumberAddition = address.houseNumberAddition ? address.houseNumberAddition : '';
    const streetWithHouseNumberAndAddition = `${address.street} ${address.houseNumber}${houseNumberAddition}`;
    const postalCode = address.postalCode.replace(' ', '');
    const hasCountry = (address as AddressModel).country != null && (address as AddressModel).country !== '';
    const country = hasCountry ? `, ${(address as AddressModel).country}` : '';
    const postalCodeWithCityAndCountry = `${postalCode} ${address.city}${country}`;
    return `${streetWithHouseNumberAndAddition}, ${postalCodeWithCityAndCountry}`;
};
