import Icon from 'components/atoms/Icon';
import Tooltip from 'components/molecules/Tooltip';
import React, { FC, ReactNode } from 'react';
import { FormGroup as Wrapper, Label, Header, Text } from './styles';

interface FormGroupProps {
    label?: string;
    rightLabel?: string;
    required?: boolean;
    text?: string | ReactNode;
    helpText?: string;
    noMargin?: boolean;
    smallMargin?: boolean;
    alignRight?: boolean;
    stretch?: boolean;
}

const FormGroup: FC<FormGroupProps> = (props) => {
    const { label, rightLabel, required, text, helpText, noMargin, alignRight, children, stretch, smallMargin } = props;
    return (
        <Wrapper noMargin={noMargin} alignRight={alignRight} stretch={stretch} smallMargin={smallMargin}>
            {(label || text) && (
                <Header>
                    {label && (
                        <Label required={required}>
                            {label}
                            {
                                helpText &&
                                <Tooltip text={helpText}>
                                    <div style={{ display: 'inline-block', padding: '.1rem .25rem 0', verticalAlign: 'middle' }}>
                                        <Icon name="info" size={.9} />
                                    </div>
                                </Tooltip>
                            }
                        </Label>
                    )}

                    {rightLabel && (
                        <Label required={required}>
                            {rightLabel}
                        </Label>
                    )}

                    {text && (
                        <Text>
                            {text}
                        </Text>
                    )}
                </Header>
            )}
            {children}
        </Wrapper>
    );
};

export default FormGroup;
