import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div<{ showTimeSelectOnly?: boolean, open?: boolean, showOnlyInjectedTimes?: boolean }>`

  position: relative;

  .react-datepicker__close-icon {
    ${({ open }) =>
        open !== false &&
        css`
        padding: 0;
        right: 40px;
    `}

    padding: 0 12px;
  }

  .react-datepicker__close-icon::after {
    background-color: inherit;
    color: ${({ theme }) => theme.colors.dark};
    font-size: 15px;
  }

    ${({ showTimeSelectOnly, theme }) =>
        showTimeSelectOnly &&
        css`
            input {
                padding-right: 2.25rem;
            }
            .icon-wrapper {
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
            }
            .react-datepicker-popper, .react-datepicker {
                width: 100%;
            }
            .react-datepicker:before {
                content: none;
            }
            .react-datepicker__time-container, .react-datepicker__time-box, .react-datepicker__time-box {
                width: 100% !important;
            }
            .react-datepicker__header {
                display: none;
            }
            .react-datepicker__time-list {
                text-align: left;
                font-size: 1rem;
                font-weight: 500;
            }
            .react-datepicker__time-list-item  {
                height: auto !important;
                padding: 2px 10px !important;
            }
            .react-datepicker__time-list-item--selected {
                background-color: ${theme.colors.primary} !important;
            }
    `}

    ${({ showOnlyInjectedTimes }) =>
        showOnlyInjectedTimes &&
        css`
        .react-datepicker__time-list-item {
            display: none;
        }

        .react-datepicker__time-list-item--injected {
            display: block;
        }
    `}
`;
