import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const Menu = styled.div`
    display: flex;
    align-items: center;
    line-height: 1;
`;

export const MenuItem = styled(NavLink)`
    margin-left: .5rem;
    display: inline-block;
`;
