import React, { FC } from 'react';
import { CardContent as Wrapper } from './styles';

export interface CardBodyProps {
    small?: boolean;
    border?: boolean;
    className?: string;
    noPadding?: boolean;
}

const CardBody: FC<CardBodyProps> = ({ children, ...props }) => {
    return <Wrapper {...props}>{children}</Wrapper>;
};

export default CardBody;
