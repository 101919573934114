import { useQuery } from 'react-query';
import { CustomError, useApi } from '..';
import { SearchUpcomingPaymentInputModel } from './models/search';
import { UpcomingPeriodicPaymentModel } from './models/upcomingPayment';
export const upcomingPeriodicPaymentsQueryKey = 'periodicPayment.upcoming';

const usePeriodicPayments = () => {
    const baseUrl = '/periodicPayment';
    const { apiPost } = useApi();

    const useAllUpcomingPayments = (params?: SearchUpcomingPaymentInputModel) => {
        const queryKey = [upcomingPeriodicPaymentsQueryKey, params];

        return useQuery<UpcomingPeriodicPaymentModel[], CustomError[]>(queryKey, async () => {
            const response = await getAllUpcomingPayments(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const getAllUpcomingPayments = async (params?: SearchUpcomingPaymentInputModel) => {
        return await apiPost<UpcomingPeriodicPaymentModel[]>(`${baseUrl}/upcoming`, params);
    };

    return {
        useAllUpcomingPayments,
        getAllUpcomingPayments
    };
};

export default usePeriodicPayments;
