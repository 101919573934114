import styled from '@emotion/styled';
import { FormikFormCheck } from 'components/atoms/form';

export const CardHeader = styled.div`
    padding: ${({ theme }) => theme.card.padding}px;
    padding-bottom: 0;
    display: flex;
`;

export const Title = styled.h3`
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    font-weight: 600;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.dark};
`;

export const Subtitle = styled.span`
    font-size: 1rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.greys[300]};
    display: inline-flex;
`;

export const Check = styled(FormikFormCheck)`
    margin-right: 16px;
`;

export const Text = styled.div``;
