import MenuItem, { MenuItemProps } from 'components/atoms/MenuItem';
import React, { FC } from 'react';
import { Menu as Wrapper } from './styles';

interface MenuProps {
    items: MenuItemProps[];
    light?: boolean;
}

const Menu: FC<MenuProps> = ({ items, light }) => {

    const visibleItems = items.filter(item => item.isVisible !== false);

    // Render.
    return (
        <Wrapper light={light}>
            {visibleItems.map(item => (
                <MenuItem key={item.title} {...item}>{item.title}</MenuItem>
            ))}
        </Wrapper>
    );
};

export default Menu;
