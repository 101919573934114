import { useField, useFormikContext } from 'formik';
import React, { FC } from 'react';
import FormControl, { FormControlProps } from '..';

interface FormikFormControlProps extends FormControlProps {
    name: string;
    errorFields?: string[];
}

const FormikFormControl: FC<FormikFormControlProps> = (props) => {
    const { errorFields = [] } = props;
    const { errors } = useFormikContext();
    const [field, meta] = useField(props);

    const otherFieldErrors = errorFields.map((errorField: any) => (errors as any)[errorField]);
    const allErrors = [meta.error, ...otherFieldErrors].filter(Boolean);
    const error = allErrors.length > 0 ? allErrors[0] : undefined;

    return <FormControl {...field} {...props} error={error} />;
};

export default FormikFormControl;
