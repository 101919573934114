import { useField, useFormikContext } from 'formik';
import React, { ChangeEvent, ChangeEventHandler, FC } from 'react';
import FormCheck, { FormCheckProps } from '..';

interface FormikFormCheckProps extends FormCheckProps {
    name: string;
    checked?: boolean;
    onValueChange?: ChangeEventHandler<HTMLInputElement>
    errorFields?: string[];
}

const FormikFormCheck: FC<FormikFormCheckProps> = (props) => {
    const { 
        name,
        checked,
        onValueChange,
        errorFields = []
    } = props;
    
    const { errors } = useFormikContext();
    const [field, meta] = useField(name);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        field.onChange(event);
        onValueChange && onValueChange(event);
    };

    const otherFieldErrors = errorFields.map((errorField: any) => (errors as any)[errorField]);
    const allErrors = [meta.error, ...otherFieldErrors].filter(Boolean);
    const error = allErrors.length > 0 ? allErrors[0] : undefined;

    // Render.
    const checkedValue = field.value === undefined ? checked : field.value;
    return <FormCheck checked={checkedValue} onChange={handleOnChange} error={error} {...props} />;
};

export default FormikFormCheck;
