import React, { FC } from 'react';
import { Border, DropdownIcon, DropdownItem as Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

export interface DropdownItemProps {
    title: string;
    onClick?: () => void;
    setOpen?: (state: boolean) => void;
    to?: string;
    icon?: string;
    closeOnClick?: boolean;
    topBorder?: boolean;
}

const DropdownItem: FC<DropdownItemProps> = ({ title, onClick, setOpen, to, icon, topBorder, closeOnClick }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    // DropdownItem's methods.
    const handleOnClick = () => {
        if (closeOnClick) {
            setOpen && setOpen(false);
        }

        if (to) {
            navigate(to);
        } else if (onClick) {
            onClick();
        }
    };

    // Render.
    return (
        <>
            {topBorder && <Border />}
            <Wrapper topBorder={topBorder} onClick={handleOnClick}>
                {icon && <DropdownIcon name={icon} color={theme.colors.dark} />}
                {title}
            </Wrapper>
        </>
    );
};

export default DropdownItem;
