import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from 'utils/breakpointHelper';

export const Navigation = styled.div`
  padding: 1.35rem 0;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  button {
    ${mq[1]} {
      span {
        display: none;
      }
    }
  }
`;

export const Right = styled.div<{ open?: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  ${mq[1]} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem;
    padding-top: 5rem;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 350px;
    box-shadow: 0px 0px 2rem 0px rgba(0,0,0,.15);
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 100;
    transform: translateX(${({ open }) => open ? '0' : '101'}%);
    transition: transform .25s ease 0s;
  }
`;

export const NavToggle = styled.button`
  position: relative;
  z-index: 99;
  display: none;
  outline: none;
  border: 0;
  padding: 1rem;
  margin: -1rem;
  background: none;
  ${mq[1]} {
    display: inline-block;
  }
`;

export const NavBackdrop = styled.div<{ open?: boolean; }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: none;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.dark};
  transition: all .25s ease 0s;

  ${mq[1]} {
    display: block;
  }

  ${({ open }) =>
        open &&
        css`
      opacity: .35;
      visibility: visible;
      pointer-events: auto;
  `}
`;


export const Logo = styled.div<{ image?: string }>`
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 12rem;
  height: 3rem;
  background-image: url(${({ image }) => image});
  margin-right: 2.5rem;
`;
