import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface MarginProps {
  all?: number;
  horizontal?: number;
  vertical?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

const Margin = styled.div<MarginProps>`
  ${({ theme, ...props }) => {
    return css`
      ${props.all &&
      css`
        margin: ${props.all}rem;
      `};
      ${props.horizontal &&
      css`
        margin-left: ${props.horizontal}rem;
        margin-right: ${props.horizontal}rem;
      `}
      ${props.vertical &&
      css`
        margin-top: ${props.vertical}rem;
        margin-bottom: ${props.vertical}rem;
      `}
      ${props.top &&
      css`
        margin-top: ${props.top}rem;
      `}
      ${props.right &&
      css`
        margin-right: ${props.right}rem;
      `}
      ${props.bottom &&
      css`
        margin-bottom: ${props.bottom}rem;
      `}
      ${props.left &&
      css`
        margin-left: ${props.left}rem;
      `}
    `;
  }}
`;

export default Margin;
