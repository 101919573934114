import { InvoiceModel, InvoiceStatus, InvoiceType } from 'hooks/api/invoice/models/invoice';
import { TransactionStatusType } from 'components/atoms/TransactionStatus';
import { PriceSpecificationType } from 'hooks/api/price/models/price';

export const getInvoiceTypeLabel = (type: InvoiceType) : string => {
    switch (type) {
        case InvoiceType.Debit: return 'Debit';
        case InvoiceType.Credit: return 'Credit';
    }
};

export const getInvoiceStatusLabel = (status: InvoiceStatus) : string => {
    switch (status) {
        case InvoiceStatus.Open: return 'Open';
        case InvoiceStatus.Paid: return 'Betaald';
        case InvoiceStatus.Expired: return 'Te laat met betalen';
        case InvoiceStatus.Cancelled: return 'Geannuleerd';
        case InvoiceStatus.TakenOverByAdministration: return 'Overgenomen door administratie';
    }
};

export const getInvoiceStatusType = (status: InvoiceStatus) : TransactionStatusType => {
    switch (status) {
        case InvoiceStatus.Open: return TransactionStatusType.Pending;
        case InvoiceStatus.Paid: return TransactionStatusType.Completed;
        case InvoiceStatus.Expired: return TransactionStatusType.Warning;
        case InvoiceStatus.Cancelled: return TransactionStatusType.Cancelled;
        case InvoiceStatus.TakenOverByAdministration: return TransactionStatusType.Info;
    }
};

export const getDepositAmount = (invoices: InvoiceModel[], type: PriceSpecificationType) : number => {
    let amount = 0;

    invoices.forEach(invoice => {
        const deposits = invoice.specifications.filter(s => s.type === type);
        amount += deposits.reduce((acc, cur) => acc + cur.amount.amount, 0);
    });

    return amount;
};
