import React, { FC, Suspense, useEffect, useState } from 'react';
import ClientNavigation from 'components/organisms/ClientNavigation';
import { Outlet, useLocation, useNavigate } from 'react-router';
import useAccount from 'hooks/api/account';
import { useSession } from 'contexts/sessionContext';
import ClientFooter from 'components/molecules/ClientFooter';
import Loading from 'components/atoms/Loading';
import { ContentWrapper } from './styles';
import { useAppSettings } from 'contexts/settingsContext';

interface ClientLayoutProps {
    requireAuth?: boolean;
}

const ClientLayout: FC<ClientLayoutProps> = ({ requireAuth }) => {
    const [initialized, setInitialized] = useState(false);
    const [initializedAuth, setInitializedAuth] = useState(false);
    const { refreshSession } = useAccount();
    const { session } = useSession();
    const navigate = useNavigate();
    const location = useLocation();
    const { organisationName } = useAppSettings();

    document.title = `Reserveren - ${organisationName}`;

    useEffect(() => {
        (async () => {
            if (session == null) {
                await refreshSession();
            }
            setInitialized(true);
        })();
    }, []);

    useEffect(() => {
        if (initialized) {
            if (session?.userId == null && requireAuth) {
                navigate('/account', { state: { redirectUrl: location.pathname } });
            }

            setInitializedAuth(true);
        }
    }, [initialized, session, requireAuth, initializedAuth]);

    return initialized && initializedAuth ? (
        <ContentWrapper>
            <ClientNavigation />
            <Suspense fallback={<Loading />}>
                <Outlet />
            </Suspense>
            <ClientFooter />
        </ContentWrapper>
    ) : (<Suspense fallback={<Loading />} />);
};

export default ClientLayout;
