import React, { FC } from 'react';
import { Header } from './styles';
import Deposit from 'components/molecules/Deposit';
import { InvoiceModel, InvoiceType } from 'hooks/api/invoice/models/invoice';
import { formatToCurrency } from 'utils/currencyHelper';
import { PriceSpecificationType } from 'hooks/api/price/models/price';
import { utcToZonedTime } from 'date-fns-tz';
import { TimeZone } from 'utils/constants';
import { sortBy } from 'lodash-es';
import { parseToDate, rentalPeriodToString } from 'utils/dateHelper';
import { PeriodModel } from 'hooks/api/global/models/dateTimePeriod';
import { getInvoiceStatusLabel, getInvoiceStatusType } from 'utils/invoiceHelper';
import { getPaymentMethodLabel } from 'utils/paymentHelper';
import EmptyContent from 'components/molecules/EmptyContent';
import { PriceSpecificationUnit } from 'utils/constants/rentablePriceUnitConstants';

interface DepositsProps {
    invoices: InvoiceModel[];
    totalDepositPaid: number;
}

const Deposits: FC<DepositsProps> = (props) => {
    const { invoices, totalDepositPaid } = props;
    const deposits = invoices.filter(i => i.specifications.find(s => s.type === PriceSpecificationType.Deposit || s.type === PriceSpecificationType.DepositRestitution));
    const sortedDeposit = sortBy(deposits, 'paidOn', 'desc');

    const renderDeposits = (deposits: InvoiceModel[]) => {
        const depositsContent = deposits.map(invoice => {
            const { type, reservations, specifications, paidOn, paymentMethod, status } = invoice;
            const isDebitInvoice = type === InvoiceType.Debit;

            // TODO repeated reservations
            const { reservationTime, options } = reservations[0];
            const roomName = reservations.map(r => r.room.name).join(',');
            const roomType = reservations.map(r => r.room.roomType).join(',');
            const locationName = reservations.map(r => r.room.location.name).join(',');

            const parsedReservationTime = parseToDate(reservationTime.from, reservationTime.to);
            const mappedReservationTime: PeriodModel = { from: parsedReservationTime.from, to: parsedReservationTime.to ?? parsedReservationTime.from };
            const formattedReservationTime = rentalPeriodToString(mappedReservationTime);

            const depositSpecifications = specifications.filter(s => s.type === PriceSpecificationType.Deposit || s.type === PriceSpecificationType.DepositRestitution);
            const totalDeposit = depositSpecifications.reduce((acc, cur) => acc + cur.totalAmount, 0);

            const optionsDeposits = isDebitInvoice 
                ? depositSpecifications.filter(s => s.description !== roomName) 
                : options.map(s => ({ description: s.name, amount: { amount: 0, unit: PriceSpecificationUnit.Unit } }));
            const hasOptionsDeposit = optionsDeposits.length > 0;

            const room = depositSpecifications.find(s => s.description === roomName) ?? depositSpecifications[0];
            const roomDeposit = isDebitInvoice && hasOptionsDeposit ? `(${formatToCurrency(room.amount.amount ?? 0)})` : '';
            const emptySpecItem = { label: '', value: '' };

            const depositRoomName = `${isDebitInvoice ? room.description : roomName} - ${locationName}`;
            const depositAmountOption = isDebitInvoice && optionsDeposits[0]?.amount != null ? `(${formatToCurrency(optionsDeposits[0].amount?.amount)})` : '';

            return (
                <Deposit
                    key={invoice.id}
                    name={depositRoomName}
                    amount={totalDeposit}
                    date={utcToZonedTime(paidOn ? paidOn : new Date(), TimeZone.UTC)}
                    status={{ title: getInvoiceStatusLabel(status), type: getInvoiceStatusType(status) }}
                    specs={[
                        {
                            items: [
                                { label: 'Soort ruimte: ', value: roomType },
                                { label: 'Naam ruimte: ', value: `${depositRoomName} ${roomDeposit}` },
                                { label: 'Huurperiode: ', value: formattedReservationTime },
                                isDebitInvoice ? { label: 'Betaalmethode: ', value: getPaymentMethodLabel(paymentMethod) } : emptySpecItem,
                            ]
                        },
                        {
                            items: [
                                hasOptionsDeposit
                                    ? { label: 'Gehuurde items: ', value: `${optionsDeposits[0].description} ${depositAmountOption}` }
                                    : emptySpecItem
                            ].concat(optionsDeposits.slice(1).map(opt => {
                                const amount = isDebitInvoice && opt.amount?.amount !== 0 ? `(${formatToCurrency(opt.amount?.amount)})` : '';
                                return ({ label: '', value: `${opt.description} ${amount}` });
                            }))
                        }
                    ]}
                />
            );
        });

        return deposits.length > 0 ? depositsContent : <EmptyContent title="borg" />;
    };

    return (
        <>
            <Header>
                <p>Totaal borg:</p>
                <h2>{formatToCurrency(totalDepositPaid)}</h2>
            </Header>
            {renderDeposits(sortedDeposit)}
        </>
    );
};

export default Deposits;
