import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { lighten, darken, rgba } from 'polished';

const lightenAmount = {
    hover: 0.1,
    active: 0.075
};

export const Wrapper = styled.div`
    max-width: 14rem;
    width: 100%;
`;

export const Inner = styled.div<{ heightPercentage?: number, hasError?: boolean; rounded?: boolean; }>`
    position: relative;
    height: 0;
    box-shadow: 0px 2px 6px 0px ${({ theme }) => rgba(theme.colors.black, 0.125)};
    width: 100%;
    border-radius: ${({ theme, rounded }) => rounded ? '50%' : `${theme.borderRadius.small}px`};
    background-color: ${({ hasError, theme }) => hasError ? lighten(.35, theme.colors.error) : theme.colors.white};
    ${({ heightPercentage }) =>
        heightPercentage &&
        css`
        padding-bottom: ${heightPercentage}%;
    `}
`;

export const NoImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    user-select: none;

    img {
        width: 100%;
        height: auto;
        max-width: 4rem;
    }
`;

export const UploadIcon = styled.a`
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 2;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary};

    &:hover {
        background-color: ${({ theme }) => lighten(lightenAmount.hover, theme.colors.primary)};
    }

    &:active {
        background-color: ${({ theme }) => darken(lightenAmount.active, theme.colors.primary)};
    }
    img {
        width: 100%;
        height: auto;
        max-width: 4rem;
    }
`;

export const Image = styled.div<{ image?: string; }>`
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;

    ${({ image }) =>
        image != null &&
        css`
        background-image: url(${image});
    `}
`;

export const Input = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

export const Label = styled.label`
    display: block;
    cursor: pointer;
`;

export const Remove = styled.a`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => rgba(theme.colors.dark, .5)};
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity .1s ease 0s;

    &:hover {
        opacity: 1;
    }
`;

export const TextLabel = styled.span`
    display: block;
    margin-top: .5rem;
    text-align: center;
    width: 100%;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
    font-size: .8rem;
`;