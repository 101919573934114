import React, { FC } from 'react';
import { Paragraph as Text } from './styles';

export interface ParagraphProps {
    center?: boolean;
    className?: string;
    size?: 'lg';
}

const Paragraph: FC<ParagraphProps> = ({ children, center, size, className }) => {
    return (
        <Text center={center} className={className} size={size}>
            {children}
        </Text>
    );
};

export default Paragraph;
