import { Claim } from './claimConstants';
import { Right } from './rightConstants';
import { Role } from './roleConstants';
import { DateTimeFormat, Locale, TimeZone } from './dateTimeConstants';
import { SortCriteria } from './searchConstants';

import theme from 'theme';
import logoDark from 'static/logo.svg';
import logoWhite from 'static/logo-white.svg';
import { PriceDefinitionUnit } from './rentablePriceUnitConstants';
import { VatRate, VatRateOptions } from './vatRateConstants';

const DefaultTheme = theme;
const DefaultAppLogo = { dark: logoDark, white: logoWhite };
const PlaceholderImage = 'https://cdn.bluenotion.nl/534603dad0d2fa162628c59c27be37d26e89953a08967f08c540b94377e48140.jpg';

export {
    Claim, Role, Right, PriceDefinitionUnit, VatRate, VatRateOptions, DateTimeFormat, Locale,
    TimeZone, SortCriteria, DefaultTheme, DefaultAppLogo, PlaceholderImage
};
