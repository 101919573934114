import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Paragraph = styled.p<{ size?: string; center?: boolean }>`
    font-size: 1rem;
    margin-bottom: 1.5rem;
    ${({ center }) =>
    center &&
      css`
      text-align: center;
    `}
    ${({ size }) =>
    size === 'lg' &&
      css`
      font-size: 1.375rem;
    `}
`;
