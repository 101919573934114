import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FormGroup = styled.div < { noMargin?: boolean; alignRight?: boolean; stretch?: boolean; smallMargin?: boolean }>`
  margin-bottom: 1.5rem;

  ${({ stretch }) =>
        stretch &&
        css`
    flex: 1;
  `};

  ${({ smallMargin }) =>
        smallMargin &&
        css`
    margin-bottom: .75rem;
  `};

  ${({ noMargin }) =>
        noMargin &&
        css`
    margin-bottom: 0;
  `};

  ${({ alignRight }) =>
        alignRight &&
        css`
    text-align: right;
    button {
      margin-left: auto;
    }
  `};
`;

export const Label = styled.label<{
    required?: boolean;
}>`
  display: block;
  font-size: .8rem;
  line-height: 1;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.medium};
  white-space: nowrap;

  ${({ required, theme }) =>
        required &&
        css`
      &:after {
        content: ' *';
        color: ${theme.colors.secondary};
        font-weight: bold;
      }
    `};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .65rem;
`;

export const Text = styled.span`
  &,
  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
    color: ${({ theme }) => theme.greys[300]};
    font-size: 0.875rem;
    line-height: 1rem;
  }
`;
