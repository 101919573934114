import React, { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import FormControlNumber from 'components/atoms/form/FormControlNumber';
import { NumberFormatProps } from 'react-number-format';

interface FormControlNumberFormikProps extends NumberFormatProps {
    name: string;
    prefix?: string;
    suffix?: string;
    format?: string;
    placeholder: string;
    thousandSeparator?: boolean;
    errorFields?: string[];
}

const FormControlNumberFormik: FC<FormControlNumberFormikProps> = props => {
    const { prefix, suffix, format, errorFields = [] } = props;
    const [field, meta, helpers] = useField(props.name);
    const { errors } = useFormikContext();

    const otherFieldErrors = errorFields.map((errorField: any) => (errors as any)[errorField]);
    const allErrors = [meta.error, ...otherFieldErrors].filter(Boolean);
    const error = allErrors.length > 0 ? allErrors[0] : undefined;

    return (
        <FormControlNumber
            {...field}
            {...props}
            value={field.value}
            error={error}
            prefix={prefix}
            suffix={suffix}
            format={format}
            onValueChange={val => helpers.setValue(val.floatValue)}
        />
    );
};

export default FormControlNumberFormik;
