import React, { FC } from 'react';
import Icon from 'components/atoms/Icon';
import { Content as Wrapper } from './styles';
import { useTheme } from '@emotion/react';

export interface AlertProps {
    title: string;
    variant?: 'error' | 'warning';
}

const Alert: FC<AlertProps> = ({ title, variant = 'error' }) => {
    const theme = useTheme();

    return (
        <Wrapper variant={variant}>
            {title}
            <Icon name="alert" color={theme.colors.error} size={1.25} />
        </Wrapper>
    );
};

export default Alert;
