import { PriceSpecificationRentableModel } from 'hooks/api/rentables/models/rentable';
import { PriceSpecificationRentalBlockModel } from 'hooks/api/rentalBlock/models/rentalBlock';
import { ReservationInputModel } from 'hooks/api/reservation/models/reservation';
import { PriceSpecificationUnit } from 'utils/constants/rentablePriceUnitConstants';

export enum PriceSpecificationType {
    Deposit = 0,
    DepositRestitution = 1,
    Rent = 2,
    Restitution = 3,
    Verification = 4,
    Voucher = 5
}

export interface AbstractPriceSpecificationModel {
    type: PriceSpecificationType;
    quantity: number;
    amount: PriceSpecificationPriceModel;
    totalAmount: number;
}

export interface PriceSpecificationPriceModel
{
    amount: number;
    unit: PriceSpecificationUnit;
}

export interface PriceSpecificationViewModel extends AbstractPriceSpecificationModel {
    description: string;
    rentable: PriceSpecificationRentableModel | null;
    rentalBlock: PriceSpecificationRentalBlockModel | null;
}

export interface PriceEstimateModel {
    initialPayment: PriceSpecificationViewModel[];
    monthlyPayment: PriceSpecificationViewModel[];
}

export interface CalculateReservationPriceInputModel extends ReservationInputModel { }
