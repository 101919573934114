import Icon from 'components/atoms/Icon';
import React, { FC } from 'react';
import { Wrapper, Left, Right, Image, RightAction, CheckWrapper } from './styles';
import { useTheme } from '@emotion/react';
import Warning from 'components/molecules/Warning';
import Tooltip from 'components/molecules/Tooltip';

export interface ReservationProps {
    title: string;
    address: string;
    thumbnail?: string;
    isPaid: boolean;
    isCanceled: boolean;
    isRepeat?: boolean;
    isHistory?: boolean;
    reservationTime: string;
}

const Reservation: FC<ReservationProps> = (props) => {
    const { title, address, thumbnail, isPaid, isCanceled, isRepeat, isHistory, reservationTime } = props;
    const theme = useTheme();
    const showWarning = !isPaid && !isCanceled;

    return (
        <Wrapper>
            <Left>
                {thumbnail && <Image image={thumbnail} />}
                <div>
                    {
                        showWarning &&
                        <>
                            <Warning message="Let op! Factuur nog niet betaald" />
                            <Tooltip text="Om de factuur te betalen, klik op het tabblad Mijn transacties -> Historie.">
                                <div style={{ display: 'inline-block', padding: '.5rem .5rem 0 .5rem' }}>
                                    <Icon name="info" size={.9} />
                                </div>
                            </Tooltip>
                        </>
                    }
                    <h4>{title}</h4>
                    <p>{address}</p>
                </div>

            </Left>
            <Right>
                <RightAction>
                    {
                        isRepeat ? (
                            <p>{reservationTime}</p>
                        ) : (
                            <p>
                                {
                                    isHistory &&
                                    <CheckWrapper>
                                        <Icon name="check" size={.75} color={theme.colors.white} />
                                    </CheckWrapper>
                                }
                                {reservationTime}
                            </p>
                        )
                    }

                </RightAction>
                {/*{*/}
                {/*    isHistory ? (*/}
                {/*        <button>Opnieuw reserveren</button>*/}
                {/*    ) : (*/}
                {/*        <button>Reservering aanpassen</button>*/}
                {/*    )*/}
                {/*}*/}
            </Right>
        </Wrapper>
    );
};

export default Reservation;
