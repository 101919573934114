import React, { FC } from 'react';
import { Wrapper, Inner } from './styles';
import { Container } from 'react-grid-system';
import Icon from 'components/atoms/Icon';
import { useTheme } from '@emotion/react';
import { useAppSettings } from 'contexts/settingsContext';

const ClientFooter: FC = () => {
    const { organisationName, defaultOrganisationName, website } = useAppSettings();
    const { facebookUrl, instagramUrl, privacyUrl, termsAndConditionsUrl } = website;

    const theme = useTheme();
    const copyrightText = `Copyright © ${new Date().getUTCFullYear()} ${organisationName}, powered by ${defaultOrganisationName}`;

    return (
        <Wrapper>
            <Container>
                <Inner>
                    <span>{copyrightText}</span>
                    <ul>
                        {
                            privacyUrl &&
                            <li>
                                <a href={privacyUrl}>Privacy</a>
                            </li>
                        }

                        {
                            termsAndConditionsUrl &&
                            <li>
                                <a href={termsAndConditionsUrl}>Voorwaarden</a>
                            </li>
                        }
                    </ul>
                    <ul>
                        {
                            facebookUrl &&
                            <li>
                                <a href={facebookUrl} rel="noreferrer noopener" target="_blank">
                                    <div>
                                        <Icon name="facebook" color={theme.colors.medium} />
                                    </div>
                                </a>
                            </li>
                        }
                        {
                            instagramUrl &&
                            <li>
                                <a href={instagramUrl} rel="noreferrer noopener" target="_blank">
                                    <div>
                                        <Icon name="instagram" color={theme.colors.medium} />
                                    </div>
                                </a>
                            </li>
                        }
                    </ul>
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default ClientFooter;
