import React, { FC, useState } from 'react';
import { Wrapper, Left, SpecsWrapper } from './styles';
import { Card, CardBody } from 'components/atoms/card';
import { Col, Row } from 'react-grid-system';
import Icon from 'components/atoms/Icon';
import { useTheme } from '@emotion/react';
import { formatToCurrency } from 'utils/currencyHelper';
import TransactionStatus, { TransactionStatusProps } from 'components/atoms/TransactionStatus';
import Alert from 'components/atoms/Alert';
import { formatInUtc } from 'utils/dateHelper';
import { DateTimeFormat } from 'utils/constants';
import Margin from 'components/atoms/Margin';

interface Spec {
    label: string;
    value: string;
}

interface Specs {
    items: Spec[];
}

export interface DepositProps {
    name: string;
    amount: number;
    date: Date;
    status: TransactionStatusProps;
    specs?: Specs[];
    alert?: string;
}

const Deposit: FC<DepositProps> = ({ name, amount, date, status, specs, alert }) => {
    const [specsVisible, setSpecsVisible] = useState<boolean>(false);
    const theme = useTheme();

    return (
        <>
            <Wrapper onClick={() => setSpecsVisible(!specsVisible)} specsVisible={specsVisible}>
                <Left>
                    <h4>{formatToCurrency(amount)}</h4>
                    <ul>
                        <li>{name}</li>
                        <li>
                            {formatInUtc(date, DateTimeFormat.DateFull)}
                            {
                                specs &&
                                <Icon name="chevron-down" size={1} color={theme.colors.medium} />
                            }
                        </li>
                    </ul>
                </Left>
                <TransactionStatus title={status.title} type={status.type} />
            </Wrapper>
            {
                (specs && specsVisible) &&
                <Margin bottom={0.5}>
                    <Card light>
                        <Row>
                            {
                                specs.map((spec, specIndex) => {
                                    return (
                                        <Col xs={6} key={specIndex}>
                                            <CardBody>
                                                <SpecsWrapper>
                                                    {
                                                        spec.items.map((item, itemIndex) => {
                                                            return (
                                                                <Row key={itemIndex}>
                                                                    <Col xs={specIndex === 0 ? 3 : 4}>
                                                                        <span>{item.label}</span>
                                                                    </Col>

                                                                    <Col xs={specIndex === 0 ? 9 : 8}>
                                                                        <span>{item.value}</span>
                                                                    </Col>
                                                                </Row>
                                                            );
                                                        })
                                                    }
                                                </SpecsWrapper>
                                            </CardBody>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                        {
                            alert &&
                            <CardBody>
                                <Alert title={alert} />
                            </CardBody>
                        }
                    </Card>
                </Margin>
            }
        </>
    );
};

export default Deposit;
