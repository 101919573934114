export interface RentalBlockModel {
    id: string;
    type: RentalBlockType;
    day: number;
    from: string;
    duration: number;
    price: number;
    minimumRentalTimeMinutes: number;
}

export interface DatedRentalBlockModel {
    id: string;
    from: string;
    to: string;
    price: number;
    type: RentalBlockType;
    minimumRentalTimeMinutes: number | null;
}

export interface PriceSpecificationRentalBlockModel extends RentalBlockModel { }

export interface EditRentalBlockInputModel {
    type: RentalBlockType;
    day: number;
    from?: string;
    duration?: number;
    price?: number;
    minimumRentalTimeMinutes: number | null;
}

export enum RentalBlockType {
    Block = 0,
    Flexible = 1
}
