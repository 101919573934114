import React, { FC, FocusEventHandler } from 'react';
import Select, { OnChangeValue, Options } from 'react-select';
import { FormSelect as Control, Option, Menu, MenuList, ValueContainer, Placeholder, SingleValueContainer } from './styles';
import InvalidFeedback from '../InvalidFeedback';

export interface IOption {
    value?: string | number | Date;
    label: string;
}

export interface FormSelectProps {
    options?: Options<IOption>;
    value?: IOption | Options<IOption>;
    onChange?: (option: OnChangeValue<IOption | IOption[], boolean>) => void;
    error?: string;
    loading?: boolean;
    open?: boolean;
    placeholder?: string;
    onBlur?: FocusEventHandler;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    isMulti?: boolean;
    small?: boolean;
    light?: boolean;
    isSearchable?: boolean;
    isOptionDisabled?: (option: IOption, selectValue: ReadonlyArray<IOption>) => boolean;
}

const FormSelect: FC<FormSelectProps> = (props) => {
    const {
        options,
        error,
        onChange,
        loading,
        open,
        placeholder = 'Maak een keuze...',
        value,
        onBlur,
        onMenuOpen,
        onMenuClose,
        isMulti,
        small,
        light,
        isSearchable = false,
        isOptionDisabled
    } = props;

    return (
        <>
            <Select
                onBlur={onBlur}
                placeholder={placeholder}
                onChange={onChange}
                menuIsOpen={open}
                isLoading={loading}
                value={value ?? null} // Map undefined to null, because it would otherwise not reset the form properly. https://github.com/JedWatson/react-select/issues/3066 
                onMenuOpen={!isSearchable ? onMenuOpen : undefined}
                onMenuClose={!isSearchable ? onMenuClose : undefined}
                components={{
                    Control: ({ children, innerProps, innerRef, isFocused }) => (
                        <Control ref={innerRef} {...innerProps} isFocused={isFocused} small={small} light={light}>
                            {children}
                        </Control>
                    ),
                    IndicatorSeparator: null,
                    ValueContainer: ({ children }) => <ValueContainer small={small}>{children}</ValueContainer>,
                    Option: ({ children, innerProps, innerRef, isSelected, isDisabled }) => (
                        <Option ref={innerRef} {...innerProps} isSelected={isSelected} isDisabled={isDisabled}>
                            {children}
                        </Option>
                    ),
                    Menu: ({ children, innerRef, innerProps }) => (
                        <Menu ref={innerRef} {...innerProps}>
                            {children}
                        </Menu>
                    ),
                    Placeholder: ({ children, innerProps }) => (
                        <Placeholder {...innerProps}>
                            {children}
                        </Placeholder>
                    ),
                    MenuList: ({ children, innerRef }) => <MenuList ref={innerRef}>{children}</MenuList>,
                    SingleValue: ({ children }) => <SingleValueContainer>{children}</SingleValueContainer>
                }}
                options={options}
                isMulti={isMulti}
                isSearchable={isSearchable}
                hideSelectedOptions={false}
                isOptionDisabled={isOptionDisabled}
            />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormSelect;
