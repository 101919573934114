import colors from './colors';
import borderRadius from './borderRadius';
import navigation from './navigation';
import header from './header';
import greys from './greys';
import card from './card';

const theme = {
    colors,
    greys,
    borderRadius,
    navigation,
    header,
    card
};

export default theme;
