import styled from '@emotion/styled';
import Icon from '../Icon';

export const DropdownItem = styled.li<{ topBorder?: boolean; }>`
  display: inline-flex;
  padding: .5rem .75rem;
  width: 100%;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.light};
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const Border = styled.hr`
  padding: 0;
  margin: 1px 0 0;
`;

export const DropdownIcon = styled(Icon)`
  margin-right: 8px;
`;
