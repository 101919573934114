import { formatInUtc } from 'utils/dateHelper';

export const appendToForm = (formData: FormData, data: any, parentKey = '') => {
    switch (typeof data) {
        case 'number':
            formData.append(parentKey, data.toString());
            break;
        case 'boolean':
            formData.append(parentKey, data.toString());
            break;
        case 'string':
            formData.append(parentKey, data);
            break;
        case 'object':
            if (data == null) {
                // Do not add a null value
            } else if (data instanceof Date) {
                // eslint-disable-next-line
                formData.append(parentKey, formatInUtc(data, "yyyy-MM-dd'T'HH:mm:ss'Z'"));
            } else if (data instanceof File) {
                formData.append(parentKey, data, data.name);
            } else if (Array.isArray(data)) {
                data.forEach((item, index) => {
                    appendToForm(formData, item, `${parentKey}[${index}]`);
                });
            } else {
                Object.keys(data).forEach((key) => {
                    const newKey = parentKey === ''
                        ? key
                        : `${parentKey}.${key}`;
                    appendToForm(formData, data[key], newKey);
                });
            }
            break;
    }

    return formData;
};
