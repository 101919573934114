import React, { FC } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormGroup, FormikFormControl } from 'components/atoms/form';

const BankSection: FC = () => {
    return (
        <>
            <h4>Bankgegevens</h4>
            <Row>
                <Col sm={4}>
                    <FormGroup label="IBAN (rekeningnummer)" required>
                        <FormikFormControl
                            placeholder="IBAN"
                            name="iban"
                            errorFields={['iban']}
                            disabled
                        />
                    </FormGroup>
                </Col>
            </Row>

            <hr />
        </>
    );
};

export default BankSection;
