import React, { FC, Suspense } from 'react';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { Background, Wrapper } from './styles';
import { Outlet } from 'react-router-dom';
import Loading from 'components/atoms/Loading';
import { useAppSettings } from 'contexts/settingsContext';

interface AccountLayoutProps {
}

const AccountLayout: FC<AccountLayoutProps> = () => {
    const screenClass = useScreenClass();
    const { organisationName } = useAppSettings();

    document.title = `Reserveren - ${organisationName}`;

    return (
        <Container fluid>
            <Row>
                <Col md={6} xl={5} xxl={4}>
                    <Wrapper screenClass={screenClass}>
                        <Suspense fallback={<Loading />}>
                            <Outlet />
                        </Suspense>
                    </Wrapper>
                </Col>
                <Background md={6} xl={7} xxl={8} />
            </Row>
        </Container>
    );
};

export default AccountLayout;
