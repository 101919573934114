import React from 'react';
import { RadioItem, Label, Switch } from './styles';

interface Item {
    title: string;
    name: string;
    defaultValue?: boolean;
    onChange?: () => void;
}

export interface FormRadioListProps {
    items: Item[];
}

const FormRadioList = ({ items }: FormRadioListProps) => {
    return (
        <div>
            {
                items.map((item, index) => (
                    <RadioItem key={index}>
                        <Label>
                            <p>{item.title}</p>
                            <input type="radio" name={item.name} checked={item.defaultValue} onChange={item.onChange} />
                            <Switch />
                        </Label>
                    </RadioItem>
                ))
            }
        </div>
    );
};

export default FormRadioList;
