import React, { useEffect, useState } from 'react';
import { useModal } from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import Margin from '../../components/atoms/Margin';
import InvalidFeedback from 'components/atoms/form/InvalidFeedback';
import { CustomError } from 'hooks/api';

type BaseItem = { id?: string; name: string; };

const useDeleteModal = <TItem extends BaseItem, >(title: string, onDelete: (id: string) => Promise<any>): [(item: TItem) => void, () => void, boolean] => {
    const [itemToDelete, setItemToDelete] = useState<TItem>();
    const [isDeleting, setIsDeleting] = useState(false);
    const [errors, setErrors] = useState<CustomError[]>([]);

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal
                onHide={hide}
                inProp={inProp}
                onExited={onExited}
                title={`${title} verwijderen`}
                size="small"
                buttons={[
                    { label: 'Verwijder', onClick: handleDelete, loading: isDeleting },
                    { label: 'Annuleer', onClick: () => hide(), variant: 'white' }
                ]}
            >
                <Margin horizontal={0.5} bottom={1}>
                    Weet je zeker dat je <b><i>"{itemToDelete?.name}"</i></b> wilt verwijderen?
                </Margin>

                {
                    errors.length > 0 && errors.map((error: CustomError, index: number) => (
                        <InvalidFeedback key={index}>{error.message}</InvalidFeedback>
                    ))
                }
            </Modal>
        ),
        [itemToDelete, errors, isDeleting]
    );

    useEffect(() => setErrors([]), [isShowing]);

    const showModal = (item: TItem) => {
        setItemToDelete(item);
        show();
    };

    const handleDelete = async () => {
        if (itemToDelete?.id == null) {
            return;
        }

        setIsDeleting(true);

        const response = await onDelete(itemToDelete.id);
        if(!response.ok && response.errors) {
            setErrors(response.errors);
        } else {
            hide();
        }

        setIsDeleting(false);
    };

    return [showModal, hide, isShowing];
};

export default useDeleteModal;
