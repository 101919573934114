import React, { FC } from 'react';
import Icon from 'components/atoms/Icon';
import { Content as Wrapper } from './styles';
import { useTheme } from '@emotion/react';

export enum TransactionStatusType {
    Pending = 0,
    Completed = 1,
    Warning = 2,
    Cancelled = 3,
    Info = 4
}

export interface TransactionStatusProps {
    title: string;
    type: TransactionStatusType;
}

const TransactionStatus: FC<TransactionStatusProps> = props => {
    const { title, type } = props;
    const theme = useTheme();
    let icon = 'pending';
    let color = theme.colors.dark;

    switch (type) {
        case TransactionStatusType.Pending:
            icon = 'pending';
            color = theme.colors.secondary;
            break;

        case TransactionStatusType.Completed:
            icon = 'completed';
            color = theme.colors.success;
            break;

        case TransactionStatusType.Warning:
            icon = 'alert';
            color = theme.colors.warning;
            break;

        case TransactionStatusType.Cancelled:
            icon = 'cancelled';
            color = theme.colors.error;
            break;

        case TransactionStatusType.Info:
            icon = 'info';
            color = theme.colors.medium;
            break;
    }

    return (
        <Wrapper color={color}>
            <Icon name={icon} color={color} size={1} />
            {title}
        </Wrapper>
    );
};

export default TransactionStatus;
