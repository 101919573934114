import React, { FC } from 'react';
import { Wrapper, Item } from './styles';

export interface ItemProps {
    title: string;
    link?: string;
    active?: boolean;
}

export interface BreadcrumbProps {
    items: ItemProps[];
}

const Breadcrumb: FC<BreadcrumbProps> = ({ items }) => {
    return (
        <Wrapper>
            {
                items.map((i: ItemProps, index: number) => (
                    <Item href={i.link} key={index} active={i.active}>
                        {i.title}
                    </Item>
                ))
            }
        </Wrapper>
    );
};

export default Breadcrumb;
