import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { darken, lighten, rgba } from 'polished';

export const FormSelect = styled.div<{ isFocused: boolean; small?: boolean; light?: boolean; }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: ${({ small }) => small ? '2.35rem' : '3rem'};
    border: 1px solid ${({ theme }) => theme.colors.border};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.small}px;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colors.border};
    }

    &:hover {
        border-color: ${({ theme }) => darken(.1, theme.colors.border)};
    }

    ::placeholder {
        color: ${({ theme }) => lighten(.75, theme.colors.dark)};
    }
    
    ${({ isFocused, theme }) =>
        isFocused &&
        css`
            outline: none;
            box-shadow: 0 0 0 0.2rem ${theme.colors.border};
        `}
    
    ${({ light, theme }) =>
        light &&
        css`
            background-color: ${theme.colors.light};
            border-color: ${theme.colors.light};
        `}
`;

export const Placeholder = styled.span`
    white-space: nowrap;
    color: ${({ theme }) => lighten(.75, theme.colors.dark)};
`;

export const ValueContainer = styled.div<{ small?: boolean }>`
    display: flex;
    flex: 1 1 0%;
    flex-wrap: nowrap;
    position: relative;
    overflow: hidden;
    padding: 0 16px;
    font-size: 1rem;
    align-items: center;
    
    ${({ small }) =>
        small &&
        css`
            padding: 0 10px;
            font-size: .9rem;
        `}
`;

export const SingleValueContainer = styled.div`
    overflow: unset;
`;

export const Option = styled.div<{ isSelected: boolean; isDisabled: boolean }>`
    display: inline-flex;
    height: 2.5rem;
    width: 100%;
    align-items: center;
    padding: 0 1rem;
    line-height: 1.1;
    border-top: 1px solid ${({ theme }) => theme.colors.border};
    font-size: 0.875rem;
    font-weight: 500;
    transition: background 0.3s ease;

    &:hover {
        background-color: ${({ theme }) => theme.colors.border};
        cursor: pointer;
    }

    &:first-of-type {
        border-top: none;
    }

    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            background-color: ${theme.colors.border};
            border-top-color: transparent;

            + div {
                border-top-color: transparent;
            }

            &:hover {
                background-color: ${theme.colors.border};
            }
        `}

    ${({ isDisabled, theme }) =>
        isDisabled &&
        css`
            color: ${theme.greys[300]};
        `}
`;

export const Menu = styled.div`
    box-shadow: 0 2px 6px ${({ theme }) => rgba(theme.colors.dark, 0.15)};
    border-radius: ${({ theme }) => theme.borderRadius.small}px;
    overflow: hidden;
    margin-top: 0.5rem;
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 99;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const MenuList = styled.div`
    padding: 0.25rem 0;
    max-height: 300px;
    overflow-x: scroll;
`;
