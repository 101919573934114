import { useApi } from '..';
import {
    AddCustomerInputModel,
    CompleteCustomerInvitationInputModel,
    EditCustomerInputModel,
    InviteCustomerInputModel
} from './models/customer';
import { appendToForm } from 'utils/formDataHelper';

const useCustomer = () => {
    const baseUrl = '/customer';
    const { apiPost, apiPut } = useApi();

    const addCustomer = async (values: AddCustomerInputModel) => {
        return await apiPost<AddCustomerInputModel>(baseUrl, values);
    };

    const editCustomer = async (values: EditCustomerInputModel) => {
        const formData = new FormData();
        appendToForm(formData, values);
        return await apiPut<EditCustomerInputModel>(baseUrl, formData);
    };

    const inviteCustomer = async (values: InviteCustomerInputModel) => {
        return await apiPost(`${baseUrl}/invite`, values);
    };

    const completeRegistration = async (values: CompleteCustomerInvitationInputModel) => {
        return await apiPost(`${baseUrl}/invite/complete`, values);
    };

    return {
        addCustomer,
        editCustomer,
        inviteCustomer,
        completeRegistration
    };
};

export default useCustomer;
