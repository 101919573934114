import { useQuery } from 'react-query';
import { CustomError, useApi } from 'hooks/api';
import { appendToForm } from 'utils/formDataHelper';
import { AdminAddReservationInputModel, AdminEditReservationInputModel, RefundDepositInputModel, ReservationViewModel } from './models/reservation';
import { SearchReservationInputModel } from './models/search';

export const searchReservationsQueryKey = 'reservation.search';

const useReservations = () => {
    const baseUrl = '/reservation';
    const { apiDelete, apiPut, apiPost } = useApi();

    const useAllReservations = (params?: SearchReservationInputModel) => {
        const queryKey = [searchReservationsQueryKey, params];

        return useQuery<ReservationViewModel[], CustomError[]>(queryKey, async () => {
            const response = await getAllReservations(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const getAllReservations = async (params?: SearchReservationInputModel) => {
        return await apiPost<ReservationViewModel[]>(`${baseUrl}/search`, params);
    };

    const addReservation = async (data: AdminAddReservationInputModel) => {
        const formData = new FormData();
        appendToForm(formData, data);
        return apiPost<AdminAddReservationInputModel>(baseUrl, formData);
    };

    const editReservation = async (data: AdminEditReservationInputModel) => {
        const formData = new FormData();
        appendToForm(formData, data);
        return apiPut<AdminEditReservationInputModel>(baseUrl, formData);
    };

    const cancelReservation = async (id: string) => {
        return await apiDelete(`${baseUrl}/${id}`);
    };

    const refundDeposit = async (data: RefundDepositInputModel) => {
        const formData = new FormData();
        appendToForm(formData, data);
        return apiPost(`${baseUrl}/refund-deposit`, formData);
    };

    return {
        useAllReservations,
        getAllReservations,
        addReservation,
        editReservation,
        cancelReservation,
        refundDeposit
    };
};

export default useReservations;
