import { parseISO } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import React, { FC } from 'react';
import FormDate, { FormDateProps } from '..';

interface FormikFormDateProps extends Omit<FormDateProps, 'onChange'> {
    name: string;
    errorFields?: string[];
}

const FormikFormDate: FC<FormikFormDateProps> = ({ name, errorFields = [], ...props }) => {
    const { errors } = useFormikContext();
    const [field, meta, helpers] = useField(name);

    // Methods.
    const handleOnChange = (date: Date | null) => {
        helpers.setValue(date);
    };

    const parsedValue = () => {
        if (typeof field.value !== 'string') {
            return field.value;
        }

        const parsed = parseISO(field.value);
        return isNaN(parsed.valueOf()) ? null : parsed;
    };

    // Render.
    const otherFieldErrors = errorFields.map((errorField: any) => (errors as any)[errorField]);
    const allErrors = [meta.error, ...otherFieldErrors].filter(Boolean);
    const error = allErrors.length > 0 ? allErrors[0] : undefined;

    return <FormDate selected={parsedValue()} onChange={handleOnChange} {...props} error={error} />;
};

export default FormikFormDate;
