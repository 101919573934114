export interface Colors {
    primary: string;
    secondary: string;
    success: string;
    error: string;
    warning: string;
    yellow: string;
    medium: string;
    dark: string;
    white: string;
    light: string;
    black: string;
    border: string;
}

const colors: Colors = {
    primary: '#CE5800',
    secondary: '#FB8F3F',
    success: '#1AC620',
    error: '#BE2727',
    warning: '#CE5800',
    yellow: '#DBBB0A',
    medium: '#848486',
    dark: '#000000',
    white: '#ffffff',
    light: '#EFEFEF',
    black: '#000000',
    border: '#E5E5E5'
};

export default colors;
