import React from 'react';
import { Col, Row } from 'react-grid-system';
import { useModal } from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormControl } from 'components/atoms/form';
import { toFormikErrors } from 'utils/errorhelper';

const useInviteUserModal = <TInviteModel, >(title: string, initialValues: TInviteModel, onInvite: (args: TInviteModel) => Promise<any>): [() => void, () => void, boolean] => {
    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => (
            <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
                {({ isSubmitting, dirty, handleSubmit }) => (
                    <Modal
                        onHide={hide}
                        inProp={inProp}
                        onExited={onExited}
                        title={`${title} uitnodigen`}
                        buttons={[
                            { label: 'Uitnodigen', loading: isSubmitting, disabled: isSubmitting || !dirty, onClick: () => handleSubmit() },
                            { label: 'Annuleren', variant: 'white', onClick: () => hide() }
                        ]}
                    >
                        <Form>
                            <Row>
                                <Col xs={4}>
                                    <FormGroup label="Voornaam" required>
                                        <FormikFormControl name="firstName" placeholder="Voornaam" />
                                    </FormGroup>
                                </Col>

                                <Col xs={4}>
                                    <FormGroup label="Tussenvoegsel">
                                        <FormikFormControl name="insertion" placeholder="Tussenvoegsel" />
                                    </FormGroup>
                                </Col>

                                <Col xs={4}>
                                    <FormGroup label="Achternaam" required>
                                        <FormikFormControl name="lastName" placeholder="Achternaam" />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <FormGroup label="E-mailadres" required>
                                        <FormikFormControl name="email" placeholder="info@voorbeeld.nl" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                )}
            </Formik>
        ), []
    );

    // Methods.
    const handleOnSubmit = async (values: TInviteModel, actions: FormikHelpers<TInviteModel>) => {
        actions.setSubmitting(true);

        const response = await onInvite(values);

        if (!response.ok && response.errors) {
            const errors = toFormikErrors<TInviteModel>(response.errors);
            actions.setErrors(errors);
        } else {
            hide();
        }

        actions.setSubmitting(false);
    };

    return [show, hide, isShowing];
};

export default useInviteUserModal;
