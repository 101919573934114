import React, { FC } from 'react';
import { Wrapper } from './styles';

export interface WarningProps {
    message: string;
}

const Warning: FC<WarningProps> = props => {
    const { message } = props;

    return (
        <Wrapper>
            {message}
        </Wrapper>
    );
};

export default Warning;
