import React, { FC } from 'react';
import Modal, { ModalProps } from 'components/organisms/Modal';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormCheck, FormikFormControl } from 'components/atoms/form';
import { Button } from 'components/atoms/button';
import { Account as AccountFooter } from 'components/organisms/footer';
import { Link, useLocation } from 'react-router-dom';
import { toFormikErrors } from 'utils/errorhelper';
import useAccount from 'hooks/api/account';
import { CookieLoginInputModel } from 'hooks/api/account/models/cookieLogin';

interface LoginProps {
    redirectUrl?: string;
    modalProps: ModalProps;
    showRegisterModal: () => void;
}

interface LoginValues {
    email: string;
    password: string;
    rememberMe: boolean;
}

const Login: FC<LoginProps> = ({ showRegisterModal, ...props }) => {
    const { redirectUrl, modalProps: { inProp, onExited, onHide } } = props;
    const location = useLocation();
    const state = location.state;
    const { login } = useAccount();

    const onSubmit = async (values: LoginValues, { setSubmitting, setErrors }: FormikHelpers<LoginValues>) => {
        const loginValues: CookieLoginInputModel = {
            userName: values.email,
            password: values.password,
            rememberMe: values.rememberMe
        };

        const response = await login(loginValues, redirectUrl);
        if (!response.ok && response.errors) {
            const errors = toFormikErrors<LoginValues>(response.errors);
            setErrors(errors);
        } else {
            onHide();
        }

        setSubmitting(false);
    };

    // Formik.
    const initialValues: LoginValues = {
        email: (state && state.mail) || '',
        password: '',
        rememberMe: false
    };

    const handleOnRegister = () => {
        onHide();
        showRegisterModal();
    };

    return (
        <Modal title="Inloggen" size="small" inProp={inProp} onExited={onExited} onHide={onHide}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ values, isSubmitting, dirty }) => (
                    <Form>
                        <FormGroup label="E-mailadres" required>
                            <FormikFormControl name="email" placeholder="E-mailadres" errorFields={['userName']} />
                        </FormGroup>
                        <FormGroup
                            label="Wachtwoord"
                            text={
                                <Link to="/account/forgot-password" state={{ email: values.email }}>
                                    Wachtwoord vergeten?
                                </Link>
                            }
                            required
                        >
                            <FormikFormControl name="password" type="password" placeholder="Wachtwoord" />
                        </FormGroup>
                        <FormGroup>
                            <Field type="checkbox" name="rememberMe" label="Onthoud deze browser" as={FormCheck} />
                        </FormGroup>
                        <FormGroup alignRight>
                            <Button size="lg" disabled={isSubmitting || !dirty} loading={isSubmitting}>
                                Inloggen
                            </Button>
                        </FormGroup>
                        <AccountFooter>
                            <button onClick={handleOnRegister}>
                                Nog geen account? <span>Registreren</span>
                            </button>
                        </AccountFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default Login;
