import React, { StrictMode } from 'react';
import theme from './theme';
import { Global, css } from '@emotion/react';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';
import grid from 'theme/grid';
import colors from 'theme/colors';
import { SessionProvider } from 'contexts/sessionContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import borderRadius from 'theme/borderRadius';
import 'react-datepicker/dist/react-datepicker.css';
import Routes from './Routes';
import { darken, lighten } from 'polished';
import { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import { Locale } from 'utils/constants';
import { SearchContext, useSearch } from 'contexts/searchContext';
import { mq } from 'utils/breakpointHelper';
import { AppSettingsProvider } from 'contexts/settingsContext';
import { closeSnackbar, SnackbarProvider, SnackbarProviderProps } from 'notistack';
import Icon from 'components/atoms/Icon';

registerLocale(Locale.NL, nl);

setConfiguration(grid);

const styles = css`
    *, *:before, *:after {
        box-sizing: border-box;
    }
    
    html {
        font-size: 15px;
        overflow-x: hidden;
        ${mq[0]} {
            font-size: 14px;
        }
        ${mq[2]} {
            font-size: 13px;
        }
    }

    body {
        margin: 0;
        font-family: 'neue-haas-unica', Arial, sans-serif;
        line-height: 1.75;
        overflow-x: hidden;
    }

    ::-webkit-scrollbar {
        width: 3px;               /* width of the entire scrollbar */
        height: 3px;               /* width of the entire scrollbar */
    }
      
    ::-webkit-scrollbar-track {
        background: #EFEFEF;        /* color of the tracking area */
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: #848486;    /* color of the scroll thumb */
        border-radius: 10px;       /* roundness of the scroll thumb */
    }

    .chrome-picker {
        font-family: inherit !important;
    }

    p {
        margin-top: 0;
    }

    ul {
        margin-top: 0;
    }

    input, button, textarea, select {
        font-family: inherit;
    }

    input:disabled {
        background-color: ${theme.greys[25]}
    }

    button {
        background: none;
        padding: 0;
        border: 0;
        cursor: pointer;
        outline: none;
        font-size: inherit;
    }

    button, a {
        cursor: pointer;
    }

    #layers {
        z-index: 100;
    }

    img {
        vertical-align: middle;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.1;
        font-weight: 700;
    }

    h1 {
        font-size: 4.5rem;
        margin-bottom: 2rem;
        ${mq[0]} {
            font-size: 3.5rem;
        }
        ${mq[2]} {
            font-size: 2.5rem;
        }
    }

    h2 {
        font-size: 2.625rem;
        ${mq[0]} {
            font-size: 2rem;
        }
        ${mq[2]} {
            font-size: 1.85rem;
        }
    }

    h3 {
        font-size: 1.875rem;
        ${mq[0]} {
            font-size: 1.75rem;
        }
        ${mq[2]} {
            font-size: 1.5rem;
        }
    }

    h4, h5, h6 {
        font-weight: 500;
        font-size: 1.15rem;
        ${mq[2]} {
            font-size: 1.1rem;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .rotate {
        transform: rotate(180deg);
        transform-origin: center center;
        display: inline-block;
    }

    @keyframes rotate {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    hr {
        border: 0;
        height: 0;
        margin: 1rem 0;
        border-bottom: 1px solid ${colors.border};
    }

    .icon-wrapper {
        pointer-events: none;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .react-code-input {
        width: 100%;
        overflow: hidden;
    }

    .react-datepicker {
        font-family: inherit;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
        border-radius: ${borderRadius.small}px;
        border-color: ${colors.border};
        &:before {
            content: '';
            position: absolute;
            left: 1rem;
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
        }
    }

    .react-datepicker-popper {
        z-index: 5;
        &[data-placement=top-start] {
            .react-datepicker {
                &:before {
                top: 100%;
                border-width: 7px 8px 0 7px;
                    border-color: ${theme.colors.white} transparent transparent transparent;
                }
            }
        }
        &[data-placement=bottom-start] {
            .react-datepicker {
                &:before {
                    bottom: 100%;
                    border-width: 0 7px 8px 7px;
                    border-color: transparent transparent ${theme.colors.white} transparent;
                }
            }
        }
    }

    .react-datepicker-popper[data-placement^=bottom] {
        padding-top: 1px;
    }

    .react-datepicker-wrapper {
        input {
            width: 100%;
            height: 3rem;
            border: 1px solid ${colors.border};
            border-radius: ${borderRadius.small}px;
            padding: 0 1rem;
            font-size: 1rem;

            &:focus {
                outline: none;
                box-shadow: 0 0 0 0.2rem ${theme.colors.border};
            }

            &:hover {
                border-color: ${darken(.1, theme.colors.border)};
            }

            ::placeholder {
                color: ${lighten(.75, theme.colors.dark)};
            }
        }
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__header {
        background: transparent;
        border-color: ${colors.border};
        padding-top: 4px;
    }

    .react-datepicker__current-month {
        font-size: .75rem;
    }
    
    .react-datepicker__navigation-icon:before {
        border-width: 2px 2px 0 0;
        top: 9px;
    }

    .react-datepicker__day, .react-datepicker__day-name {
        margin: 0;
        width: 2.25rem;
        line-height: 2.25rem;
        border-radius: 0 !important;
    }

    .react-datepicker__day {
        outline: none !important;
        &:hover {
            background-color: ${colors.primary} !important;
            color: ${colors.white};
            border-radius: 3px !important;
        }
    }

    .react-datepicker__day--excluded {
        background: none !important;
        color: inherit !important;
        opacity: .35;
        &:hover {
            background: none !important;
            color: inherit !important;
        }
    }

    .react-datepicker__day-names {
        text-transform: lowercase;
        font-size: .7rem;
    }

    .react-datepicker__day--today {
        color: inherit;
        font-weight: 700;
        background: transparent;
    }

    .react-datepicker__month {
        font-size: .85rem;
        font-weight: 400;
    }

    .react-datepicker__day--in-range {
        background-color: ${colors.light} !important;
        color: inherit;
    }

    .react-datepicker__day--in-selecting-range {
        background-color: ${colors.light} !important;
        color: inherit;
        &:hover {
            background-color: ${colors.primary} !important;
            color: ${colors.white};
            border-radius: 3px !important;
        }
    }
    
    .react-datepicker__day--range-start, .react-datepicker__day--range-end, .react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end, .react-datepicker__day--selected {
        background-color: ${colors.primary} !important;
        border-radius: 3px !important;
        color: ${colors.white};
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
        border: 0;
        th#Action {
            width: 100px;
        }
    }
`;

const queryClient = new QueryClient();
const snackbarRef = React.createRef<SnackbarProvider>();
const snackbarDefaultActions = (snackbarId: string) => {
    return (
        <button onClick={() => closeSnackbar(snackbarId)}>
            <Icon name="cross" size={1} color={'#fff'} />
        </button>
    );
};

const snackbarProviderProps: SnackbarProviderProps = {
    ref: snackbarRef,
    anchorOrigin: { vertical: 'top', horizontal: 'center' },
    action: snackbarDefaultActions,
    autoHideDuration: 2500,
};

const App = () => {
    return (
        <StrictMode>
            <ScreenClassProvider>
                <Global styles={styles} />
                <QueryClientProvider client={queryClient}>
                    <AppSettingsProvider>
                        <SessionProvider>
                            <ModalProvider rootComponent={TransitionGroup}>
                                <SearchContext value={useSearch()}>
                                    <SnackbarProvider {...snackbarProviderProps}>
                                        <Routes />
                                    </SnackbarProvider>
                                </SearchContext>
                            </ModalProvider>
                        </SessionProvider>
                    </AppSettingsProvider>
                </QueryClientProvider>
            </ScreenClassProvider>
        </StrictMode>
    );
};

export default App;
