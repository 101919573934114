import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { mq } from 'utils/breakpointHelper';

export const Content = styled.div < { noPaddingTop?: boolean; noPaddingBottom?: boolean; small?: boolean; responsive?: boolean; } > `
    padding: ${({ small }) => small ? '2rem' : '5rem'} 0;

    ${({ noPaddingTop }) =>
        noPaddingTop &&
        css`
        padding-top: 0;
    `}

    ${({ noPaddingBottom }) =>
        noPaddingBottom &&
        css`
        padding-bottom: 0;
    `}

    ${({ responsive }) =>
        responsive &&
        css`
        ${mq[1]} {
            padding: 2rem 0 0;
        }
    `}
`;
