import styled from '@emotion/styled';

export const Sticky = styled.div`
  position: sticky;
  top: 1rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  h2 {
    margin: 0;
    font-size: 2rem;
  }
  
  p {
    margin: 0;
    font-size: .9rem;
    margin-right: 3rem;
    color: ${({ theme }) => theme.colors.medium};
  }
`;
