import produce from 'immer';
import { LocalStorageKeys } from 'localStorage';
import { SearchStateModel } from './state';
import { SearchAction, SearchActionType } from './action';
import { SortCriteria } from 'utils/constants';
import { PartlyDefinedPeriodModel } from 'hooks/api/global/models/dateTimePeriod';
import { parseToDate } from 'utils/dateHelper';

export const initialState: SearchStateModel = {
    isOneTimeRental: true,
    selectedRepeatInterval: undefined,
    selectedRoomType: undefined,
    selectedLocation: undefined,
    selectedDates: [],
    isStartTimeSelected: false,
    isEndTimeSelected: false,
    sortBy: SortCriteria.BestMatch,
    priceRange: [0, 0]
};

export const searchReducer = (previousState: SearchStateModel | undefined = initialState, action: SearchAction): SearchStateModel | undefined => {
    switch (action.type) {
        case SearchActionType.SET_FILTERS: {
            const localStorageData = localStorage.getItem(LocalStorageKeys.SearchFilters);
            return produce(previousState, nextState => {
                if (localStorageData) {
                    const parsedData = JSON.parse(localStorageData);

                    nextState.isOneTimeRental = parsedData.isOneTimeRental;
                    nextState.selectedRepeatInterval = parsedData.selectedRepeatInterval;
                    nextState.selectedRoomType = parsedData.selectedRoomType;
                    nextState.selectedLocation = parsedData.selectedLocation;

                    if (parsedData.selectedDates) {
                        nextState.selectedDates = parsedData.selectedDates.map((selectedDate: any) => {
                            if (selectedDate) {
                                return parseToDate(selectedDate.from, selectedDate.to);
                            }
                            return null;
                        });
                    }

                    nextState.isStartTimeSelected = parsedData.isStartTimeSelected;
                    nextState.isEndTimeSelected = parsedData.isEndTimeSelected;

                    nextState.sortBy = parsedData.sortBy;
                    nextState.priceRange = parsedData.priceRange;
                }
            }) as SearchStateModel;
        }

        case SearchActionType.SET_IS_ONE_TIME_RENTAL: {
            if (previousState == null) {
                throw new Error('Action not possible before Search-Sidebar is set');
            }
            const { isOneTimeRental } = action.payload;
            return produce(previousState, nextState => {
                nextState.isOneTimeRental = isOneTimeRental;
            });
        }

        case SearchActionType.SET_SELECTED_REPEAT_INTERVAL: {
            if (previousState == null) {
                throw new Error('Action not possible before Search-Sidebar is set');
            }

            const { selectedRepeatInterval } = action.payload;
            return produce(previousState, nextState => {
                nextState.selectedRepeatInterval = selectedRepeatInterval;
            });
        }

        case SearchActionType.SET_SELECTED_ROOM_TYPE: {
            if (previousState == null) {
                throw new Error('Action not possible before Search-Sidebar is set');
            }

            const { selectedRoomType } = action.payload;
            return produce(previousState, nextState => {
                nextState.selectedRoomType = selectedRoomType;
            });
        }

        case SearchActionType.SET_SELECTED_LOCATION: {
            if (previousState == null) {
                throw new Error('Action not possible before Search-Sidebar is set');
            }

            const { selectedLocation } = action.payload;
            return produce(previousState, nextState => {
                nextState.selectedLocation = selectedLocation;
            });
        }

        case SearchActionType.SET_SELECTED_DATE: {
            if (previousState == null) {
                throw new Error('Action not possible before Search-Sidebar is set');
            }

            const { selectedDates } = previousState;
            const { selectedDate } = action.payload;

            return produce(previousState, nextState => {
                if (selectedDate) {
                    selectedDates.length === 0
                        ? nextState.selectedDates.push(selectedDate)
                        : nextState.selectedDates = [selectedDate];

                    nextState.isStartTimeSelected = false;
                    nextState.isEndTimeSelected = false;
                }
            });
        }

        case SearchActionType.SET_SELECTED_TIME: {
            if (previousState == null) {
                throw new Error('Action not possible before Search-Sidebar is set');
            }

            const { selectedDates } = previousState;
            const { startTime, endTime } = action.payload;

            return produce(previousState, nextState => {
                nextState.selectedDates = selectedDates.map((date: PartlyDefinedPeriodModel) => {
                    const newDate = { ...date };

                    if (startTime) {
                        nextState.isStartTimeSelected = true;
                        newDate.from = startTime;
                    }

                    if (endTime) {
                        nextState.isEndTimeSelected = true;
                        newDate.to = endTime;
                    }

                    return newDate;
                });
            });
        }

        case SearchActionType.SET_SORT_BY: {
            if (previousState == null) {
                throw new Error('Action not possible before Search-Sidebar is set');
            }

            const { sortBy } = action.payload;
            return produce(previousState, nextState => {
                nextState.sortBy = sortBy;
            });
        }

        case SearchActionType.SET_PRICE_RANGE: {
            if (previousState == null) {
                throw new Error('Action not possible before Search-Sidebar is set');
            }

            const { priceRange } = action.payload;
            return produce(previousState, nextState => {
                nextState.priceRange = priceRange;
            });
        }

        case SearchActionType.CLEAR_FILTERS: {
            localStorage.removeItem(LocalStorageKeys.SearchFilters);
            previousState = initialState;
            return initialState;
        }

        default:
            return previousState;
    }
};
