import { useQuery } from 'react-query';
import { appendToForm } from 'utils/formDataHelper';
import { CustomError, useApi } from '..';
import { AddUserCommentsInputModel, EditUserInputModel, UserModel } from './models/user';
import { SearchUserInputModel } from './models/search';

export const searchUsersQueryKey = 'users.search';

const useUser = () => {
    const { apiPost, apiPut, apiDelete } = useApi();
    const baseUrl = '/user';

    const allUsers = async (params?: SearchUserInputModel) => {
        return await apiPost<UserModel[]>(`${baseUrl}/search`, params);
    };

    const useAllUsers = (params?: SearchUserInputModel) => {

        const queryKey = [searchUsersQueryKey, params];

        return useQuery<UserModel[], CustomError[]>(queryKey, async () => {
            const response = await allUsers(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const deleteUser = async (id: string) => {
        return await apiDelete<string>(`${baseUrl}/${id}`);
    };

    const editUser = async (values: EditUserInputModel) => {
        const formData = new FormData();
        appendToForm(formData, values);
        return await apiPut<EditUserInputModel>(baseUrl, formData);
    };
    
    const addUserComments = async (values: AddUserCommentsInputModel) => {
        const formData = new FormData();
        appendToForm(formData, values);
        return await apiPut<AddUserCommentsInputModel>(`${baseUrl}/comments`, formData);
    };

    return {
        allUsers,
        useAllUsers,
        deleteUser,
        editUser,
        addUserComments
    };
};

export default useUser;

