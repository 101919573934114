import { CdnFileResult } from 'utils/cdnHelper/types';

const cdnUrl = 'https://cdn.bluenotion.nl';

export const cdnFileUrl = (file: CdnFileResult | string, params?: any, type: 'jimp' | 'gm' = 'gm') => {
    switch (type) {
        case 'gm':
            return cdnFileUrlGm(file, params);
        case 'jimp':
            return cdnFileUrlJimp(file, params);
    }
};

const cdnFileUrlGm = (file: CdnFileResult | string, params?: any) => {
    const args = params
        ? Object.keys(params).map((k: any) => {
            return `${k}=${params[k]}`;
        }).reduce((a, b) => {
            return !!a
                ? `${a}&${b}`
                : b;
        }, '')
        : '';

    const hash = typeof file === 'string'
        ? file
        : file.hash;

    let result = `${cdnUrl}/${hash}`;
    if (args.length > 0) {
        result += `?${args}`;
    }
    return result;
};

const cdnFileUrlJimp = (file: CdnFileResult | string, params?: any) => {
    if (typeof file === 'string') {
        throw new Error('Resolving Jimp cdn url can only be done with a CdnFileResult');
    }

    const args = params
        ? Object.keys(params).map((k: any) => {
            return `${k}(${params[k]})`;
        }).reduce((a, b) => {
            return !!a
                ? `${a}-${b}`
                : b;
        }, '')
        : '';

    const hash = file.hash.split('.')[0];
    const result = !!args
        ? `${cdnUrl}/${hash}/${args}/${file.fileName}`
        : `${cdnUrl}/${hash}/${file.fileName}`;
    return result;
};

export const resolvedCdn = (file: CdnFileResult, width = 64, height = 64) => {
    return cdnFileUrl(file, {
        cover: `${width},${height}`
    }, 'jimp');
};
