import React, { FC } from 'react';
import { Content as Wrapper } from './styles';

export interface ContentProps {
    noPaddingTop?: boolean;
    noPaddingBottom?: boolean;
    small?: boolean;
    responsive?: boolean;
}

const Content: FC<ContentProps> = ({ children, noPaddingTop, noPaddingBottom, small, responsive }) => {
    return <Wrapper small={small} noPaddingTop={noPaddingTop} noPaddingBottom={noPaddingBottom} responsive={responsive}>{children}</Wrapper>;
};

export default Content;
