import { useField } from 'formik';
import React, { FC } from 'react';
import FormTextarea, { FormTextAreaProps } from '..';

interface FormikFormTextAreaProps extends FormTextAreaProps {
    name: string;
}

const FormikFormTextArea: FC<FormikFormTextAreaProps> = (props) => {
    const [field, meta] = useField(props);
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormTextarea {...field} {...props} error={error} />;
};

export default FormikFormTextArea;
