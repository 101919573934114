import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { darken, lighten } from 'polished';

export const FormTextArea = styled.textarea<{ darkBorder?: boolean }>`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: ${({ theme }) => theme.borderRadius.small}px;
    padding: 1rem;
    font-size: 1rem;
    min-height: 5rem;
    line-height: 1.5;
    resize: vertical;
    
    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colors.border};
    }

    &:hover {
        border-color: ${({ theme }) => darken(.1, theme.colors.border)};
    }

    ::placeholder {
        color: ${({ theme }) => lighten(.75, theme.colors.dark)};
    }

    ${({ darkBorder, theme }) =>
        darkBorder &&
        css`
            border: 1px solid ${theme.greys[100]};

            &:hover {
                border-color: ${theme.greys[300]};
            }
        `}
`;
