import styled from '@emotion/styled';
import { Colors } from 'theme/colors';

const InvalidFeedback = styled.span<{ type?: (keyof Colors) }>`
  display: block;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${({ theme, type = 'error' }) => theme.colors[type]};
  margin-top: 0.5rem;
`;

export default InvalidFeedback;
