import React, { FC } from 'react';
import Tabs from 'components/molecules/Tabs';
import TransactionHistory from './TransactionHistory';
import UpcomingTransactions from './UpcomingTransactions';
import { InvoiceModel } from 'hooks/api/invoice/models/invoice';

export interface TransactionsProps {
    transactions: InvoiceModel[];
}

const Transactions: FC<TransactionsProps> = (props) => {
    const { transactions } = props;

    return (
        <Tabs
            inline
            tabs={[
                { header: 'Aankomend', content: <UpcomingTransactions /> },
                { header: 'Historie', content: <TransactionHistory transactions={transactions} /> }
            ]}
        />
    );
};

export default Transactions;
