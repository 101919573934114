import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-size: .85rem;
    margin: 1rem 0;
    color: ${({ theme }) => theme.colors.medium};
`;

export const Item = styled.a<{ active?: boolean; }>`
    display: inline-block;
    color: ${({ theme, active }) => active ? theme.colors.dark : 'inherit'};

    &:not(:last-of-type) {
        &:after {
            content: '/';
            display: inline-block;
            margin: 0 .25rem;
            color: ${({ theme }) => theme.colors.medium};
        }
    }
    
    &:hover {
        color: ${({ theme }) => theme.colors.dark};
    }
`;
