export enum Claim {
    UserId = 'Api.PropertyManager.NL.UserId',
    UserName = 'Api.PropertyManager.NL.UserName',
    Roles = 'Api.PropertyManager.NL.Role',
    Rights = 'Api.PropertyManager.NL.Right',
    FirstName = 'Api.PropertyManager.NL.FirstName',
    Insertion = 'Api.PropertyManager.NL.Insertion',
    LastName = 'Api.PropertyManager.NL.LastName',
    Avatar = 'Api.PropertyManager.NL.Avatar',
}
