import { UnparsedPeriodModel } from 'hooks/api/global/models/dateTimePeriod';
import { PaymentMethod, PerformPaymentModel } from 'hooks/api/payment/models/payment';
import { AbstractPriceSpecificationModel } from 'hooks/api/price/models/price';
import { ReservationInvoiceModel } from 'hooks/api/reservation/models/reservation';
import { UserInvoiceModel } from 'hooks/api/user/models/user';

export enum InvoiceStatus {
    Open = 0,
    Paid = 1,
    Expired = 2,
    Cancelled = 3,
    TakenOverByAdministration = 4
}

export enum InvoiceType { Debit = 0, Credit = 1 }

export interface InvoiceModel {
    id: string;
    invoiceNumber: string;
    type: InvoiceType,
    expirationDate: string | null;
    status: InvoiceStatus;
    paymentMethod: PaymentMethod;
    paidOn: string | null;
    period: UnparsedPeriodModel | null;
    created: string;
    cancelled?: string;
    pdfDocumentName: string;
    totalAmount: number;
    reservations: ReservationInvoiceModel[];
    user: UserInvoiceModel;
    specifications: InvoiceSpecificationViewModel[];
    takenOverByAdministrationOn: string;
    takenOverByAdministrationByUser: UserInvoiceModel;
    takenOverByAdministrationReason: string;
}

export interface InvoiceSpecificationViewModel extends AbstractPriceSpecificationModel {
    description: string;
}

export interface PayInvoiceModel extends PerformPaymentModel {
    invoiceId: string;
}

export interface TakeOverByAdministrationInputModel{
    invoiceId: string;
    reason: string;
}
