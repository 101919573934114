import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { mq } from 'utils/breakpointHelper';

export const Wrapper = styled(NavLink) <{ active?: boolean; hideActiveLine?: boolean; }>`
  margin-left: 2.5rem;
  user-select: none;
  text-decoration: none;
  color: inherit;
  position: relative;
  display: block;
  transition: color .15s ease 0s;
  ${mq[1]} {
      margin-left: 0;
      margin: .25rem 0;
  }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary};
    display: block;
    bottom: -.25rem;
    transition: width .15s ease 0s;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    &:after {
      width: 100%;
    }
  }
  &:active {
    &:after {
      width: calc(100% + .5rem);
    }
  }
  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.colors.primary};
      &:after {
        width: 100%;
      }
    `}

    ${({ hideActiveLine }) =>
    hideActiveLine &&
    css`
      &:after {
        content: none;
      }
    `}
`;

export const Title = styled.span`
  color: inherit;
`;