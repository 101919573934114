import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div<{ specsVisible?: boolean; }>`
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: space-between;
    padding: .65rem 1rem;
    border-bottom: ${({ theme, specsVisible }) => specsVisible ? `2px solid ${theme.colors.white}` : `1px solid ${theme.colors.border}`};
    background-color: ${({ theme, specsVisible }) => specsVisible ? theme.colors.light : 'inherit'};
    p {
        color: ${({ theme }) => theme.colors.medium};
        font-size: .85rem;
        font-weight: 500;
        margin-bottom: 0;
    }
    ${({ specsVisible }) =>
    specsVisible &&
      css`
        li .icon-wrapper {
            transform: rotate(180deg);
        }
    `}
`;

export const Left = styled.div`
    display: flex;
    flex-direction: column;
    h4 {
        margin-bottom: .15rem;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        list-style-image: none;
        padding-left: 0;
        margin-bottom: 0;
        font-size: .85rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.medium};
        li {
            display: inline-flex;
            align-items: center;
            &:not(:last-of-type) {
                position: relative;
                margin-right: 1rem;
                &:after {
                    content: '';
                    position: absolute;
                    right: -.5rem;
                    top: 50%;
                    transform: translate(50%, -50%);
                    display: block;
                    width: 3px;
                    height: 3px;
                    background-color: ${({ theme }) => theme.colors.medium};
                    border-radius: 50%;
                }
            }
        }
    }
`;

export const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 15rem;
`;

export const SpecsWrapper = styled.div`
    font-size: .85rem;
    table {
        width: 100%;
    }
    td {
        &:first-of-type {
            width: 45%;
            padding-left: 0;
            color: ${({ theme }) => theme.colors.medium};
        }
        &:last-of-type {
            width: 55%;
            padding-right: 0;
        }
    }
`;