import { addMonths, formatDuration, getTime, intervalToDuration, parse, parseISO, startOfMonth } from 'date-fns';
import { nl } from 'date-fns/locale';
import { format, OptionsWithTZ, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { DateTimeFormat, TimeZone } from 'utils/constants';
import { NullablePeriodModel, PartlyDefinedPeriodModel, PeriodModel, UnparsedNullablePeriodModel, UnparsedPeriodModel } from 'hooks/api/global/models/dateTimePeriod';
import { capitalizeFirstLetter } from 'utils/stringHelper';

export const getUTCTime = (date: Date): { hours: number, minutes: number, seconds: number, milliSeconds: number } => {
    return {
        hours: date.getUTCHours(),
        minutes: date.getUTCMinutes(),
        seconds: date.getUTCSeconds(),
        milliSeconds: date.getUTCMilliseconds()
    };
};
export const setUTCTime = (date: Date, hours: number, minutes: number, seconds = 0, milliseconds = 0): Date => {
    date = new Date(date);
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    date.setUTCSeconds(seconds);
    date.setUTCMilliseconds(milliseconds);
    return date;
};

export const getFirstSelectableTimeOfDay = (date: Date) => setUTCTime(date, 9, 0, 0, 0);
export const getStartOfDay = (date: Date) => setUTCTime(date, 0, 0, 0, 0);
export const getEndOfDay = (date: Date) => setUTCTime(date, 23, 59, 59, 999);
export const getCurrentDate = (): { date: Date, start: Date, end: Date } => {
    const today = zonedTimeToUtc(new Date(), TimeZone.UTC);
    return { date: today, start: getStartOfDay(today), end: getEndOfDay(today) };
};

export const formatInUtc = (date: Date, fmt: string, options?: OptionsWithTZ): string => {
    return date != null ? format(utcToZonedTime(date, TimeZone.UTC), fmt, { ...options, locale: nl }) : '';
};

export const dateToString = (date?: Date, fmt: DateTimeFormat = DateTimeFormat.FullDateWithYear, locale: Locale = nl): string => {
    if (date == null) return '';
    return formatInUtc(date, fmt, { locale });
};

export const getDateCapitalized = (date?: Date, fmt?: DateTimeFormat) => capitalizeFirstLetter(dateToString(date, fmt));

export const rentalPeriodToString = (date: PeriodModel | null, showDuration = true, fmt?: DateTimeFormat): string => {
    if (date == null) return '';

    const { from, to } = date;
    const fullDate = getDateCapitalized(from, fmt);
    const startTime = formatInUtc(from, DateTimeFormat.TimeWithOutSeconds);
    const endTime = formatInUtc(to, DateTimeFormat.TimeWithOutSeconds);
    const duration = ` (${formatDuration(intervalToDuration({ start: from, end: to }), { locale: nl })})`;

    return `${fullDate} ${startTime} - ${endTime}${showDuration ? duration : ''}`;
};

export const periodToString = (period: PeriodModel) => {
    const { from, to } = period;
    const start = formatInUtc(from, DateTimeFormat.Date);
    const end = formatInUtc(to, DateTimeFormat.Date);
    return `${start} t/m ${end}`;
};

// Truncate milliseconds to 0
export const truncateTime = (date: Date): number => {
    const time = getTime(date);
    return time - time % (60 * 1000);
};

export const parseToDate = (start: string, end: string): PartlyDefinedPeriodModel => {
    const startDate = start != null ? parseISO(start) : null;
    const endDate = end != null ? parseISO(end) : null;
    return { from: startDate, to: endDate } as PartlyDefinedPeriodModel;
};

export const parsePeriodModel = (model: UnparsedPeriodModel): PeriodModel => {
    return {
        from: parseISO(model.from),
        to: parseISO(model.to)
    };
};

export const parseNullablePeriodModel = (model: UnparsedNullablePeriodModel): NullablePeriodModel => {
    return {
        from: model.from != null ? parseISO(model.from) : null,
        to: model.to != null ? parseISO(model.to) : null
    };
};

export const parseTimeOnly = (time: string, referenceDate: Date): Date => {
    let date = parse(time, DateTimeFormat.TimeWithSeconds, referenceDate);

    if (isNaN(date as any)) {
        date = parse(time, DateTimeFormat.TimeWithOutSeconds, referenceDate);
    }

    return zonedTimeToUtc(date, TimeZone.UTC);
};

export const periodsWithinDate = (periods: PeriodModel[], from: Date, to: Date) => {
    return periods?.some((closedTime) => {
        if (closedTime.from !== null && closedTime.to !== null) {
            return new Date(closedTime.from) < to
                && new Date(closedTime.to) > from;
        }
        return false;
    });
};

export const firstIncassoDate = () => {
    const nextMonth = addMonths(new Date(), 1);
    return startOfMonth(nextMonth);
};
