import { css } from '@emotion/react';
import { rgba } from 'polished';
import styled from '@emotion/styled';

export const Dropdown = styled.div`
    display: flex;
    position: relative;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer;
`;

export const Items = styled.ul<{ open?: boolean }>`
    z-index: 1;
    position: absolute;
    top: 100%;
    right: 0;
    width: 15rem;
    opacity: 0;
    visibility: hidden;
    list-style: none;
    list-style-image: none;
    margin-bottom: 0;
    transform: translateY(-16px);
    transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
    border-radius: ${({ theme }) => theme.borderRadius.small}px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    padding: 0.35rem;
    box-shadow: 0 10px 20px ${({ theme }) => rgba(theme.colors.dark, 0.1)};
    margin-top: 0.5rem;

    ${({ open }) =>
        open &&
        css`
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        `};
`;
