import React, { FC } from 'react';
import { Wrapper } from './styles';
import { Card, CardBody } from 'components/atoms/card';
import { Button } from 'components/atoms/button';

interface Action {
    title: string;
    onClick: () => void;
}

export interface EmptyContentProps {
    title: string;
    showAction?: boolean;
    action?: Action;
}

const EmptyContent: FC<EmptyContentProps> = ({ title, showAction, action }) => {
    return (
        <Card>
            <CardBody>
                <Wrapper>
                    <h4>{`Geen ${title} gevonden`}</h4>
                    {
                        showAction && action &&
                        <Button variant="light" onClick={action.onClick} endIcon="plus">{action.title}</Button>
                    }
                </Wrapper>
            </CardBody>
        </Card>
    );
};

export default EmptyContent;
