import { Card, CardBody } from 'components/atoms/card';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { ButtonWrapper, TabItem, Inner } from './styles';

export interface ITab {
    header: string;
    items?: number;
    warningItems?: number;
    content: ReactNode;
}

interface ITabsProps {
    tabs: ITab[];
    activeTab?: number;
    onTabClick?: (tab: number) => void;
    light?: boolean;
    className?: string;
    wrappedTabs?: boolean;
    inline?: boolean;
    noPadding?: boolean;
    responsiveStretch?: boolean;
}

const Tabs: FC<ITabsProps> = ({ tabs, activeTab, onTabClick, light, className, wrappedTabs = true, inline, noPadding, responsiveStretch }) => {
    const [active, setActive] = useState<number>(activeTab || 0);

    // Tab's methods.
    const handleOnTabClick = (index: number) => {
        if (onTabClick) {
            onTabClick(index);
        }

        setActive(index);
    };

    // Tab's lifecycle.
    useEffect(() => {
        if (activeTab !== undefined) {
            setActive(activeTab);
        }
    }, [activeTab]);

    return (
        <div>
            <ButtonWrapper inline={inline} light={light} responsiveStretch={responsiveStretch}>
                {tabs.map((tab, index) => (
                    <TabItem responsiveStretch={responsiveStretch} active={index === active} key={tab.header} inline={inline} onClick={() => handleOnTabClick(index)}>{tab.header}</TabItem>
                ))}
            </ButtonWrapper>
            {
                (wrappedTabs && !inline) ? (
                    <Card>
                        <CardBody noPadding={noPadding}>
                            <div className={className}>{tabs[active].content}</div>
                        </CardBody>
                    </Card>
                ) : (
                    <div className={className}>
                        <Inner inline={inline}>
                            {tabs[active].content}
                        </Inner>
                    </div>
                )
            }
        </div>
    );
};

export default Tabs;
