import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { Navigation as Wrapper, Inner, Logo, Left, Right, UserWrapper } from './styles';
import Menu from 'components/molecules/Menu';
import AdminActionMenu from 'components/molecules/AdminActionMenu';
import { Container } from 'react-grid-system';
import User from 'components/molecules/User';
import useAccount from 'hooks/api/account';
import { Link } from 'react-router-dom';
import { Button } from 'components/atoms/button';
import { DefaultAppLogo } from 'utils/constants';

interface AdminNavigationProps { }

const AdminNavigation: FC<AdminNavigationProps> = () => {
    const { logOut } = useAccount();
    const navigate = useNavigate();
    const goBackToHome = () => navigate('/');

    // Render.
    return (
        <Wrapper>
            <Container fluid>
                <Inner>
                    <Left>
                        <Link to="/admin">
                            <Logo image={DefaultAppLogo.white} />
                        </Link>
                        <Button variant="light" startIcon="arrow-left" onClick={goBackToHome}>Naar klantomgeving</Button>
                        <Menu
                            items={[
                                { title: 'Verhuur', to: '/admin/rentables' },
                                { title: 'Reserveringen', to: '/admin/reservations' },
                                { title: 'Betalingen', to: '/admin/payments' },
                                { title: 'Contacten', to: '/admin/customer-users' },
                            ]}
                        />
                    </Left>
                    <Right>
                        <AdminActionMenu
                            items={[
                                { title: 'Instellingen', icon: 'settings', to: '/admin/settings' },
                                { title: 'App store', icon: 'appstore', to: '/admin/appstore' },
                                { title: 'Uitloggen', icon: 'sign-out', to: '#', onClick: logOut }
                            ]}
                        />
                        <UserWrapper>
                            <User large />
                        </UserWrapper>
                    </Right>
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default AdminNavigation;
