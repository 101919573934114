import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ButtonProps } from './';
import Icon from 'components/atoms/Icon';
import { lighten } from 'polished';

export const Button = styled.button<ButtonProps>`
  border: none;
  height: 2.8rem;
  line-height: 1;
  padding: 0 1.35rem;
  border-radius: ${({ theme, rounded }) => (rounded ? '1.6rem' : `${theme.borderRadius.small}px`)};
  width: ${({ full }) => (full ? '100%' : 'auto')};
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  display: inline-flex;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
  align-items: center;
  white-space: ${({ breakText }) => breakText ? 'break-spaces' : 'nowrap'};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme, variant }) =>
        variant === 'primary' &&
        css`
    background-color: ${theme.colors.primary};
  `}

  ${({ theme, variant }) =>
        variant === 'primary-bordered' &&
        css`
        background-color: transparent;
        color: ${theme.colors.primary};
        border: 1.5px solid ${theme.colors.primary};

        &:hover {
            background-color: ${theme.colors.primary};
            color: ${theme.colors.white};
        }
  `}

  ${({ theme, variant }) =>
        variant === 'secondary' &&
        css`
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.dark};
  `}

  ${({ theme, variant }) =>
        variant === 'white' &&
        css`
    color: ${theme.colors.medium};
    background-color: ${theme.colors.white};
  `}

  ${({ theme, variant }) =>
        variant === 'light' &&
        css`
    color: ${theme.colors.medium};
    background-color: ${theme.colors.light};
  `}

  ${({ theme, variant }) =>
        variant === 'success' &&
        css`
    color: ${theme.colors.success};
    background-color: ${lighten(.45, theme.colors.success)};
  `}
  
  ${({ theme, variant }) =>
        variant === 'border-white' &&
        css`
    background-color: transparent;
    color: ${theme.colors.white};
    border: 1.5px solid ${theme.colors.white};
  `}
  
  ${({ theme, variant }) =>
        variant === 'border' &&
        css`
    background-color: transparent;
    color: ${theme.colors.dark};
    border: 1.5px solid ${theme.colors.dark};
  `}
  
  ${({ size, rounded, theme }) =>
        size === 'sm' &&
        css`
      height: 2rem;
    padding: 0 1rem;
    font-size: .85rem;
      border-radius: ${(rounded ? '1rem' : `${theme.borderRadius.small}px`)};
  `}

  ${({ size, rounded, theme }) =>
        size === 'lg' &&
        css`
      height: 3rem;
      border-radius: ${(rounded ? '2rem' : `${theme.borderRadius.small}px`)};
  `}
  
  ${({ loading }) =>
        loading &&
        css`
    opacity: .6;
    cursor: not-allowed;
  `}

  ${({ disabled }) =>
        disabled &&
        css`
    background-color: #bfbfbf;
    cursor: default;
  `}
`;

export const Loading = styled.div<{
    color?: string;
    icon?: boolean;
}>`
  width: 1rem;
  height: 1rem;
  margin-right: ${({ icon }) => (icon ? 'calc(16px + 0.25rem)' : '12px')};
  margin-left: ${({ icon }) => (icon ? 0.25 : 0)}rem;
  border: 2px solid ${({ color }) => color};
  animation: rotate 1s linear infinite;
  border-left-color: transparent;
  border-radius: 50%;
`;

export const StartIcon = styled(Icon)`
  margin-right: 1rem;
  margin-left: -.25rem;
`;

export const EndIcon = styled(Icon)`
  margin-left: 1rem;
  margin-right: -.25rem;
`;
